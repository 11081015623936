import { useCallback } from 'react'
import { Option, OptionClick } from '../../types'

import s from './SelectedItem.scss'
import Label from 'components/Label'

type SelectedItemProps = {
  children?: React.ReactNode
  option: Option
  onRemoveClick: OptionClick
}

const SelectedItem: React.FunctionComponent<SelectedItemProps> = (props) => {
  const { option, onRemoveClick } = props

  const handleRemoveClick = useCallback((event: React.MouseEvent) => {
    onRemoveClick(option)

    event.preventDefault()
    event.stopPropagation()
  }, [onRemoveClick, option])

  const handleMouseDown = useCallback((event: React.MouseEvent) => {
    event.preventDefault()
  }, [])

  // const content = useMemo(() => {
  //   if (typeof children === 'function') {
  //     return children(option)
  //   }

  //   return option.label
  // }, [ option, children ])

  return (
    <Label
      className={s.item}
      role="listitem"
      tabIndex={-1}
      onRemoveClick={handleRemoveClick}
      onRemoveMouseDown={handleMouseDown}
    >
      {option.label}
    </Label>
  )
}

export default SelectedItem
