
import { useDropdownProvider, DropdownContextProvider, DropdownMenu } from 'components/Dropdown'
import Button from 'components/Button'

type DropdownExampleProps = {
  children?: React.ReactNode
  className?: string
}

const DropdownExample: React.FunctionComponent<DropdownExampleProps> = () => {
  const context = useDropdownProvider()
  const { isOpened, open } = context

  return (
    <DropdownContextProvider value={context}>
      <div>
        <Button onClick={open} aria-haspopup="listbox" aria-expanded={isOpened}>Открыть меню</Button>
        <DropdownMenu focusOnOpen>
          <div>
            Some menu items?
          </div>
        </DropdownMenu>
      </div>
    </DropdownContextProvider>
  )
}

export default DropdownExample
