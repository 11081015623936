import 'sanitize.css'
import './styles/globals.scss'
import './locale'

import { render } from 'react-dom'
import App from './App'

render(<App />, document.getElementById('app'))

window.__VERSION__ = process.env.VERSION || 'latest'
