import { useCallback } from 'react'
import { FieldProps as BaseFieldProps } from 'formik'
import BaseFormField, { BaseFormFieldProps } from 'components/BaseFormField'

export type FormFieldProps = BaseFormFieldProps & BaseFieldProps

const FormField: React.FunctionComponent<FormFieldProps> = (props) => {
  const { control, field, form, onBlur, onFocus, ...rest } = props
  const { name, onBlur: fieldOnBlur } = field

  const hasError = form && form.touched[name] && !!form.errors[name]
  const error = hasError ? form.errors[name] : null

  const handleFocus = useCallback((event) => {
    if (typeof onFocus === 'function') {
      onFocus(event)
    }
  }, [onFocus])

  const handleBlur = useCallback((event) => {
    if (typeof fieldOnBlur === 'function') {
      fieldOnBlur(event)
    }

    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }, [onBlur, fieldOnBlur])

  const fieldProps = {
    ...rest,
    ...field,
    control,
    name,
    error,
    hasError,
    onFocus: handleFocus,
    onBlur: handleBlur,
  }

  return (
    <BaseFormField {...fieldProps} />
  )
}

export default FormField
