import { DateTime } from 'luxon'
import { decode } from 'html-entities'
import { OperationData, Parser } from '../../types'

interface SberbankData {
  response: {
    operations: {
      operation: SberbankOperation[]
    }
  }
}

interface SberbankOperation {
  date: string
  autopayable: boolean
  isArchive: boolean
  stateStyle: 'draft' | 'executed'
  description: string
  type: 'payment' | 'servicePayment' | 'ufsPayment'
  url: string
  copyable: boolean
  classificationCode?: number
  form: string
  operationAmount?: Commission
  nationalAmount?: Commission
  id: number
  state: string
  to: string
  from?: string
  commission?: Commission
}

interface Commission {
  amount: number
  currency: {
    code: string
    name: string
  }
}

const sberbankJSONParser: Parser = async (data) => {
  let parsed: SberbankData

  try {
    parsed = JSON.parse(data)
  }
  catch (error) {
    return null
  }

  let startDate: DateTime = null
  let endDate: DateTime = null

  const operations = parsed.response.operations.operation
    .filter((item) => Boolean(item.nationalAmount) && item.nationalAmount.amount !== 0)
    .map<OperationData>((item) => {
    const datetime = DateTime.fromFormat(item.date, 'dd.MM.yyyy\'T\'TT', {
      locale: 'ru',
      zone: 'Europe/Moscow',
    }).setZone('local').set({ second: 0 })

    const fromAmount = item.nationalAmount.amount
    const fromCurrency = item.nationalAmount.currency.code

    const toAmount = item.nationalAmount.amount
    const toCurrency = item.nationalAmount.currency.code

    // const prefix = item.description !== 'Оплата услуг' ? `${item.description}: ` : ''
    const title = `${decode(item.to)}`.trim().replace('Автоплатёж', '[Авто]').replace(/["]/g, '')
    const type = fromAmount > 0 ? 'deposition' : 'payment'

    if (!startDate || startDate > datetime) {
      startDate = datetime
    }

    if (!endDate || endDate < datetime) {
      endDate = datetime
    }

    return {
      type,
      datetime,
      title,
      amount: Math.abs(toAmount),
      currency: toCurrency,
      fromAmount: Math.abs(fromAmount),
      fromCurrency,
    }
  })

  return {
    startDate,
    endDate,
    operations: operations.reverse(),
  }
}

export default sberbankJSONParser
