
import { gql } from 'apollo'
import { getLink, getOriginRoot, useMedia, getLinkWithSearch } from 'helpers'

import { Operation, Operations } from './types'

import ExceptMobile from './ExceptMobile'
import Mobile from './Mobile'

type OperationsListProps = {
  className?: string
  operations: Operations
}

const getOperationLink = ({ type, origin }: Operation) => {
  return getLinkWithSearch(getLink(getOriginRoot(type).edit, { id: origin.id }))
}

const OperationsList: React.FunctionComponent<OperationsListProps> = (props) => {
  const { operations } = props
  const { isMobile } = useMedia()

  return (
    isMobile ? (
      <Mobile operations={operations} getOperationLink={getOperationLink} />
    ) : (
      <ExceptMobile operations={operations} getOperationLink={getOperationLink} />
    )
  )
}

export const OperationsListFragment = gql`
  fragment OperationsList on Operation {
    id
    datetime
    title
    direction
    amount
    balanceAfter
    type
    account {
      id
      name
      currency {
        id
        isoCode
      }
    }
    origin {
      id
      ...on Transfer {
        toAccount {
          id
          name
          currency {
            id
            isoCode
          }
        }
        fromAccount {
          id
          name
          currency {
            id
            isoCode
          }
        }
      }
      ...on Payment {
        market {
          id
          name
        }
      }
      ...on Deposition {
        market {
          id
          name
        }
      }
    }
  }
`

export default OperationsList
