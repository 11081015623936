
import CloseButton from 'components/CloseButton'
import Text from 'components/Text'
import Box from 'components/Box'

import s from './ModalHeader.scss'

type ModalHeaderProps = {
  children?: React.ReactNode
  title?: React.ReactNode,
  onClose?: () => void
}

const ModalHeader: React.FunctionComponent<ModalHeaderProps> = (props) => {
  const { children, title, onClose } = props

  return (
    <Box p={16} className={s.root}>
      <div className={s.container}>
        <Text className={s.title} weight="medium">{title}</Text>
        {children}
      </div>
      {
        onClose && (
          <CloseButton className={s.close} onClick={onClose} />
        )
      }
    </Box>
  )
}

export default ModalHeader
