import { useCallback, useEffect } from 'react'
import { pathOr } from 'ramda'
import { useQuery, gql, useMutation } from 'apollo'

import Select, { SelectProps } from 'components/Select'

const QUERY = gql`
  query ProductSelectQuery{
    products {
      items {
        id
        value: id
        label: name
        categoriesIds
      }
    }
  }
`

const CREATE_MUTATION = gql`
  mutation ProductSelect_CreateMutation($input: CreateProductInput!) {
    createProduct(input: $input) {
      node {
        id
        value: id
        label: name
        categoriesIds
      }
    }
  }
`

let isPolling = false

const ProductSelect: React.FunctionComponent<SelectProps> = (props) => {
  const { data, startPolling, stopPolling } = useQuery(QUERY, {
    partialRefetch: true,
  })

  useEffect(() => {
    if (isPolling === false) {
      // reload once in 5 minutes
      startPolling(5 * 60 * 1000)
      isPolling = true

      return () => {
        stopPolling()
        isPolling = false
      }
    }
  }, [ startPolling, stopPolling ])

  const options = pathOr([], ['products', 'items'], data)

  const [ createMutation, { loading } ] = useMutation(CREATE_MUTATION, {
    refetchQueries: ['ProductSelectQuery']
  })

  const handleCreate = useCallback((name: string) => {
    return createMutation({ variables: { input: { name } } })
      .then((result) => {
        return pathOr(null, ['data', 'createProduct', 'node'], result)
      })
  }, [createMutation])

  return (
    <Select
      options={options}
      searchable={true}
      clearable={true}
      creatable={true}
      isLoading={loading}
      onCreateOption={handleCreate}
      {...props}
    />
  )
}

export default ProductSelect
