import originTypes from 'helpers/constants/originTypes'
import links from 'helpers/links'

const origins = Object.values(originTypes).reduce((index, origin) => {
  index[origin] = true
  return index
}, {})

type OriginRoot = {
  root: string
  edit?: string
}

const getOriginRoot = (origin: string): OriginRoot => {
  if (!origin || typeof origins[origin] === 'undefined') {
    throw new Error(`Invalid origin ${origin}`)
  }

  if (typeof links[origin] === 'undefined') {
    throw new Error(`Origin ${origin} not found`)
  }

  return links[origin]
}

export default getOriginRoot
