import { useMemo } from 'react'
import cx from 'classnames'

import ListItem from 'components/ListItem'
import FormattedDate from 'components/FormattedDate'
import Text from 'components/Text'
import Flex from 'components/Flex'
import Box from 'components/Box'

import OperationInfo from 'pages/operations/OperationsList/components/OperationInfo'
import OperationAmount from 'pages/operations/OperationsList/components/OperationAmount'
import OperationAccount from 'pages/operations/OperationsList/components/OperationAccount'

import { Operation, OperationLinkGetter } from 'pages/operations/OperationsList/types'

import s from './Item.scss'
import { constants } from 'helpers'

type ItemProps = {
  data: Operation
  getOperationLink: OperationLinkGetter
}

const Item: React.FunctionComponent<ItemProps> = ({ data, getOperationLink }) => {
  const { account, type } = data

  const rootClassName = cx({
    [s.transfer]: type === constants.originTypes.transfer,
    [s.deposition]: type === constants.originTypes.deposition,
  })

  const to = useMemo(() => {
    return getOperationLink(data)
  }, [ data, getOperationLink ])

  return (
    <ListItem className={rootClassName} to={to}>
      <Text muted size="sm">
        <FormattedDate value={data.datetime} format="date" />, <OperationAccount account={account} asText />
      </Text>
      <Box mt={8}>
        <Flex>
          <div className={s.infoContainer}>
            <Box mb={8}>{data.title}</Box>
            <Text muted>
              <OperationInfo data={data} />
            </Text>
          </div>
          <div className={s.amountContainer}>
            <Text>
              <OperationAmount data={data} />
            </Text>
          </div>
        </Flex>
      </Box>
    </ListItem>
  )
}

export default Item
