import { mapObjIndexed } from 'ramda'

const linksSchema = {
  root: '',
  signIn: 'signin',
  accounts: {
    root: 'accounts',
    create: 'new',
    edit: ':id',
  },
  operations: {
    root: '',
  },
  payment: {
    root: 'payment',
    create: 'new',
    edit: ':id',
  },
  deposition: {
    root: 'deposition',
    create: 'new',
    edit: ':id',
  },
  transfer: {
    root: 'transfer',
    create: 'new',
    edit: ':id',
  },
  markets: {
    root: 'markets',
    create: 'new',
    edit: ':id',
  },
  products: {
    root: 'products',
    create: 'new',
    edit: ':id',
  },
  categories: {
    root: 'categories',
    create: 'new',
    edit: ':id',
  },
  import: {
    root: 'import',
    file: 'file',
    receipts: 'receipts',
  },
  guidelines: 'guidelines',
  test: 'test',
}

type Links = {
  root?: string
  [path: string]: string | Links
}

type AbsoluteLinks<T> = {
  [K in keyof T]: T[K] extends string ? string : AbsoluteLinks<T[K]>
}

export const createAbsoluteLinks = <M>(schema: M, root = ''): AbsoluteLinks<M> =>
  mapObjIndexed<Links, any>((value, key) => {
    if (typeof value === 'string') {
      if (key === 'root') {
        return root === '' ? '/' : root
      }

      return `${root}/${value}`
    }
    else if (value.root === undefined) {
      throw new Error('Missed `root` property in links object')
    }

    return createAbsoluteLinks(value, `${root}/${value.root}`)
  }, schema) as AbsoluteLinks<M>

const absoluteLinks = createAbsoluteLinks(linksSchema)

export default absoluteLinks
