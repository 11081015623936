import { useMemo, useCallback } from 'react'
import { Field, Form, Formik, FormikHelpers } from 'components/formik'
import * as Yup from 'yup'
import { getCurrentTimestamp, getQueryParams } from 'helpers'

import { Modal } from 'components/Modal'
import ModalLoader from 'components/ModalLoader'
import ModalError from 'components/ModalError'

import AccountSelect from 'components/data/AccountSelect'
import useTransferEditor from './useTransferEditor'

export type TransferFormValues = {
  id?: string
  fromAccountId?: string
  toAccountId?: string
  datetime?: number
  title?: string
  notes?: string
  fromAmount?: number
  toAmount?: number
}

export type TransferFormSubmit = (values: TransferFormValues, actions: FormikHelpers<TransferFormValues>) => void

type TransferFormProps = {
  id?: string
  initialData?: TransferFormValues
  onSubmit: () => void
  onClose: () => void
  onDelete: () => void
}

const TransferFormSchema = Yup.object().shape({
  fromAccountId: Yup.string().required(),
  toAccountId: Yup.string().required(),
  datetime: Yup.number().required(),
  title: Yup.string().min(2).required(),
  notes: Yup.string().nullable(),
  fromAmount: Yup.number().required(),
  toAmount: Yup.number().required(),
})

const TransferForm: React.FunctionComponent<TransferFormProps> = (props) => {
  const { id, initialData, onSubmit, onClose, onDelete } = props

  const {
    node,
    loading,
    error,
    saveNode,
    deleteNode,
  } = useTransferEditor(id)

  const initialValues = useMemo<TransferFormValues>(() =>{
    const { accountIds = [] } = getQueryParams()

    return {
      fromAccountId: accountIds && accountIds.length === 1 ? accountIds[0] : '',
      toAccountId: '',
      datetime: getCurrentTimestamp(),
      title: '',
      notes: '',
      fromAmount: null,
      toAmount: null,
      ...initialData,
    }
  }, [initialData])

  const handleSubmit = useCallback((values: TransferFormValues, actions: FormikHelpers<TransferFormValues>) => {
    saveNode(values)
      .finally(() => {
        actions.setSubmitting(false)
      })
      .then(
        () => {
          if (typeof onSubmit === 'function') {
            onSubmit()
          }
        },
        (mutationError) => {
          const { validationErrors } = mutationError

          if (validationErrors) {
            actions.setErrors(validationErrors)
          }
        }
      )
  }, [onSubmit, saveNode])

  const handleDelete = useCallback(() => {
    if (!id) {
      return
    }

    if (confirm('Вы уверены?')) {
      deleteNode(id)
        .then(
          () => {
            if (typeof onDelete === 'function') {
              onDelete()
            }
          },
          (mutationError) => {
            alert(mutationError)
          }
        )
    }
  }, [id, deleteNode, onDelete])

  if (loading) {
    return <ModalLoader size="md" onClose={onClose} />
  }

  if (error) {
    return <ModalError size="md" onClose={onClose} error={error} />
  }

  return (
    <Formik
      validationSchema={TransferFormSchema}
      enableReinitialize
      initialValues={node || initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Modal
          size="md"
          title={`${!node ? 'Создание' : 'Редактирование'} перевода`}
          onClose={onClose}
          wrapper={Form}
          footerButtons={[
            { children: 'Удалить', onClick: handleDelete, color: 'danger' },
            { children: 'Назад', onClick: onClose },
            { children: 'Сохранить', color: 'primary', loading: isSubmitting, type: 'submit' },
          ]}
        >
          <Field label="Счет списания" name="fromAccountId" control={AccountSelect} />
          <Field label="Счет пополнения" name="toAccountId" control={AccountSelect} />
          <Field label="Дата и время" name="datetime" control="datetime" />
          <Field label="Название" name="title" />
          <Field label="Описание" name="notes" multiline={true} />
          <Field label="Сумма списания" type="number" name="fromAmount" />
          <Field label="Сумма пополнения" type="number" name="toAmount" />
        </Modal>
      )}
    </Formik>
  )
}

export default TransferForm
