import { useMemo, Fragment, useCallback } from 'react'
import TextInput, { TextInputProps } from 'components/TextInput'

import { useUniqueId } from 'helpers'

type AutocompleteSuggestion = {
  value?: string | number
  label: string
}

export type AutocompleteProps = TextInputProps & {
  suggestions?: AutocompleteSuggestion[]
  onSuggestionSelect?: (suggestion: AutocompleteSuggestion) => void
}

const Autocomplete: React.FunctionComponent<AutocompleteProps> = (props) => {
  const { suggestions, onChange, onSuggestionSelect, ...rest } = props

  // datalist
  const dataListId = useUniqueId('datalist_')
  const dataListNode = useMemo(() => {
    if (!suggestions || !suggestions.length) {
      return null
    }

    return (
      <datalist id={dataListId}>
        {
          suggestions.map((suggestion) => (
            <option key={suggestion.value || suggestion.label} value={suggestion.value}>{suggestion.label}</option>
          ))
        }
      </datalist>
    )
  }, [suggestions, dataListId])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      onChange(event)
    }

    // find selected suggestion after change
    if (typeof onSuggestionSelect === 'function') {
      const { value } = event.target
      const selectedSuggestion = suggestions.find((suggestion) => (value === suggestion.label))

      if (selectedSuggestion) {
        onSuggestionSelect(selectedSuggestion)
      }
    }
  }, [onChange, onSuggestionSelect, suggestions])

  const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
    type: 'text',
    autoComplete: 'off',
    list: dataListId,
    onChange: handleChange,
    ...rest,
  }

  // Finally, render it!
  return (
    <Fragment>
      <TextInput {...inputProps} />
      {dataListNode}
    </Fragment>
  )
}

export default Autocomplete
