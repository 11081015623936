
import { Field, Form, Formik, FormikHelpers } from 'components/formik'
import * as Yup from 'yup'
import { Modal } from 'components/Modal'

export type MarketFormValues = {
  name: string
  notes: string
}

export type MarketFormSubmit = (values: MarketFormValues, actions: FormikHelpers<MarketFormValues>) => void

type MarketFormProps = {
  node?: MarketFormValues
  onSubmit: MarketFormSubmit
  onClose: () => void
  onDelete: () => void
}

const newInitialValues: MarketFormValues = {
  name: '',
  notes: '',
}

const MarketFormSchema = Yup.object().shape({
  name: Yup.string().min(2).required(),
  notes: Yup.string().nullable(),
})

const MarketForm: React.FunctionComponent<MarketFormProps> = (props) => {
  const { node, onSubmit, onClose, onDelete } = props

  return (
    <Formik
      validationSchema={MarketFormSchema}
      enableReinitialize
      initialValues={node || newInitialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Modal
          size="md"
          title={`${!node ? 'Создание' : 'Редактирование'} магазина/источника`}
          onClose={onClose}
          wrapper={Form}
          footerButtons={[
            { children: 'Удалить', onClick: onDelete, color: 'danger' },
            { children: 'Назад', onClick: onClose },
            { children: 'Сохранить', color: 'primary', loading: isSubmitting, type: 'submit' },
          ]}
        >
          <Field label="Название" type="text" name="name" />
          <Field label="Описание" type="text" name="notes" multiline={true} />
        </Modal>
      )}
    </Formik>
  )
}

export default MarketForm
