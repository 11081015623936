
import cx from 'classnames'

import s from './Loader.scss'

type LoaderProps = {
  className?: string
  show?: boolean
  strokeWidth?: number
  heightBased?: boolean
}

const Loader: React.FunctionComponent<LoaderProps> = (props) => {
  const { className, show, strokeWidth, heightBased } = props

  const rootClassName = cx(className, s.root, {
    [s.isVisible]: show,
  })

  const svgClassName = cx(s.svg, heightBased ? s.heightBased : s.widthBased)

  return (
    <div className={rootClassName} role="status">
      <svg width="50" height="50" viewBox="0 0 50 50" className={svgClassName}>
        <circle
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          className={s.circle}
        />
      </svg>
    </div>
  )
}

Loader.defaultProps = {
  strokeWidth: 2,
  show: true,
  heightBased: false,
}

export default Loader
