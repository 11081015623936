import { memo, useMemo, Fragment } from 'react'

export type FormattedCurrencyProps = React.HTMLAttributes<HTMLSpanElement> & {
  value: number | string
  locale?: string
  currency?: string
  smallFraction?: boolean
}

const defaultLocale = 'ru-RU'
const decimalSeparator = (3 / 2).toLocaleString(defaultLocale).replace(/\d/g, '')

const FormattedCurrency: React.FunctionComponent<FormattedCurrencyProps> = (props) => {
  const { value, currency, smallFraction, locale, ...rest } = props

  const parts = useMemo(() => {
    const floatValue = typeof value === 'number' ? value : parseFloat(value)
    const isRound = (floatValue % 1.0 === 0.0)

    const options: Intl.NumberFormatOptions = {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }

    const str = floatValue.toLocaleString(locale, options)
    const parts = str.split(decimalSeparator)
    const fractionParts = parts[1].split(/\s/)

    return [
      parts[0],
      isRound ? '' : fractionParts[0],
      fractionParts[1]
    ]
  }, [currency, locale, value])

  if (smallFraction) {
    return (
      <span {...rest}>
        {parts[0]}
        <small className="muted">
          {
            parts[1] ? (
              <Fragment>
                {decimalSeparator}
                {parts[1]}
                &nbsp;
              </Fragment>
            ) : (
              <Fragment>&nbsp;</Fragment>
            )
          }
          {parts[2]}
        </small>
      </span>
    )
  }

  return (
    <span {...rest}>
      {parts.join('')}
    </span>
  )
}

FormattedCurrency.defaultProps = {
  locale: defaultLocale,
  currency: 'RUB',
  smallFraction: true,
}

export default memo(FormattedCurrency)
