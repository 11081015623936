import { Fragment, memo, useCallback, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import uniqid from 'uniqid'
import { parseFloat, toFixed } from 'helpers'

import FormLabel from 'components/FormLabel'
import Box from 'components/Box'
import FormattedCurrency from 'components/FormattedCurrency'
import Text from 'components/Text'

import Item, { ItemState } from './Item'

type ItemsProps = any

type ContextType = {
  amount: number
  discount?: number
  discountPercent?: number
  items: ItemState[]
}

const Items: React.FunctionComponent<ItemsProps> = () => {
  const { values: formValues, setFieldValue, setFieldTouched } = useFormikContext<ContextType>()

  const name = 'items'
  const values = formValues[name]
  const { amount: prevAmount, discount = 0, discountPercent = 0 } = formValues

  const update = useCallback((value, touched = true) => {
    setFieldValue(name, value, false)

    if (touched) {
      setFieldTouched(name, touched)
    }
  }, [setFieldValue, setFieldTouched, name])

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      const element = document.getElementById('payment-modal')

      if (element) {
        element.scrollTop = element.scrollHeight
      }
    }, 100)
  }, [])

  const isFirstRenderRef = useRef(true)

  // add empty item
  useEffect(() => {
    if (values.length === 0) {
      update([ { count: 1, clientId: uniqid() } ], false)

    } else {
      const lastField = values[values.length - 1]

      if (lastField.productId) {
        update([].concat(values, { count: 1, clientId: uniqid() }), false)
        if (!isFirstRenderRef.current) {
          scrollToBottom()
        }
      }
    }

    isFirstRenderRef.current = false
  }, [scrollToBottom, update, values])

  useEffect(() => {
    let amount = values.reduce((amount, item) => {
      const itemAmount = parseFloat(item.amount)

      if (isNaN(itemAmount)) {
        return amount
      }

      return amount + itemAmount
    }, 0)

    if (discountPercent > 0) {
      amount = (1 - discountPercent / 100) * amount
    } else if (discount > 0) {
      amount -= discount
    }

    amount = toFixed(amount)

    if (prevAmount !== amount) {
      setFieldValue('amount', amount, false)
    }
  }, [discount, discountPercent, prevAmount, setFieldValue, values])

  //
  const handleChange = useCallback((index: number, value: ItemState) => {
    update(values.map((item, itemIndex) => itemIndex === index ? value : item))
  }, [update, values])

  const handleRemove = useCallback((index: number) => {
    update(values.filter((item, itemIndex) => itemIndex !== index))
  }, [update, values])

  return (
    <div>
      <FormLabel>Товары</FormLabel>
      <Fragment>
        {
          values.map((item, index) => (
            <Item
              key={item.clientId || item.id}
              name={`${name}[${index}]`}
              index={index}
              value={item}
              onChange={handleChange}
              onRemove={handleRemove}
            />
          ))
        }
      </Fragment>
      <Box mt={16}>
        <FormLabel>Сумма:</FormLabel>
        <Text weight="bold" size="md">
          <FormattedCurrency value={prevAmount} />
        </Text>
      </Box>
    </div>
  )
}

export default memo(Items)
