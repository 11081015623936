import { Fragment } from 'react'

import { OptionClick, Option } from '../types'
import SelectedItem from './SelectedItem'

type SelectedMultipleProps = {
  value: Option[]
  onRemoveClick: OptionClick
  // renderOption?: OptionRenderer
}

const SelectedMultiple: React.FunctionComponent<SelectedMultipleProps> = (props) => {
  const { value, onRemoveClick } = props

  return (
    <Fragment>
      {
        value.map((option) => (
          <SelectedItem
            key={option.value}
            option={option}
            onRemoveClick={onRemoveClick}
          />
        ))
      }
    </Fragment>
  )
}

export default SelectedMultiple
