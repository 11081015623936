import { BrowserRouter, Route, Switch } from 'react-router-dom'

import MediaProvider from 'providers/MediaProvider/MediaProvider'
import { ApolloProvider, client } from 'apollo'
import { UserProvider } from 'providers/UserProvider'

import links from 'helpers/links'

import Layout from 'layout/Layout'
import SignInRoot from 'pages/auth/SignIn/SignInRoot'

import OperationsRoot from 'pages/operations/OperationsRoot'
import AccountsListPage from 'pages/accounts/AccountsListPage'
import MarketsRoot from 'pages/markets/MarketsRoot'
import CategoriesRoot from 'pages/categories/CategoriesRoot'
import ProductsRoot from 'pages/products/ProductsRoot'

import Guidelines from 'pages/guidelines/Guidelines'

import ImportFile from 'pages/import/file/ImportFile'
import ReceiptsRoot from 'pages/import/receipts/ReceiptsRoot'

const App: React.FunctionComponent = () => (
  <ApolloProvider client={client}>
    <MediaProvider>
      <UserProvider>
        <BrowserRouter>
          <Switch>
            <Route path={links.signIn} exact={true} component={SignInRoot} />
            <Layout>
              <Switch>
                <Route path={links.accounts.root} component={AccountsListPage} />
                <Route path={links.markets.root} component={MarketsRoot} />
                <Route path={links.categories.root} component={CategoriesRoot} />
                <Route path={links.products.root} component={ProductsRoot} />
                <Route path={links.guidelines} component={Guidelines} exact />
                <Route path={links.import.file} component={ImportFile} exact />
                <Route path={links.import.receipts} component={ReceiptsRoot} exact />
                <Route path={links.operations.root} component={OperationsRoot} />
              </Switch>
            </Layout>
          </Switch>
        </BrowserRouter>
      </UserProvider>
    </MediaProvider>
  </ApolloProvider>
)

export default App
