import React from 'react'
import { Field, Form, Formik, FormikHelpers } from 'components/formik'
import * as Yup from 'yup'

import Button from 'components/Button'

import FormError from 'components/FormError'

import s from './SignInForm.scss'

type AuthFormValues = {
  username: string
  password: string
}

export type AuthFormSubmit = (values: AuthFormValues, actions: FormikHelpers<AuthFormValues>) => void

type AuthFormProps = {
  onSubmit: AuthFormSubmit
}

const SignInFormSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
})

class SignInForm extends React.Component<AuthFormProps> {

  public render() {
    const { onSubmit } = this.props

    return (
      <Formik
        validationSchema={SignInFormSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ username: '', password: '' }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, status }) => (
          <Form className={s.form}>
            <Field type="text" name="username" placeholder="Имя пользователя" />
            <Field type="password" name="password" placeholder="Пароль" />
            {status ? <FormError summary>{status}</FormError> : null}
            <Button type="submit" loading={isSubmitting} block>Отправить</Button>
          </Form>
        )}
      </Formik>
    )
  }
}

export default SignInForm
