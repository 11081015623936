
import Box, { BoxProps } from 'components/Box'

type FormGroupProps = BoxProps & {
  className?: string
  children?: React.ReactNode
}

const FormGroup: React.FunctionComponent<FormGroupProps> = ({ className, children, ...rest }) => (
  <Box className={className} {...rest}>
    {children}
  </Box>
)

FormGroup.defaultProps = {
  mb: 16,
}

export default FormGroup
