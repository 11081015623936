import { useCallback } from 'react'
import { gql, useMutation, useQuery } from 'apollo'
import { setAuthToken } from 'helpers/token'
import { UserContext, UserContextValue } from './UserContext'

const QUERY = gql`
  query UserProviderQuery {
    user {
      id
      username
    }
  }
`

const SIGN_IN = gql`
  mutation UserProvider_SignInMutation($input: CreateTokenInput!) {
    createToken(input: $input) {
      token
    }
  }
`

const SING_OUT = gql`
  mutation UserProvider_RevokeTokenMutation($input: RevokeTokenInput!) {
    revokeToken(input: $input) {
      ok
    }
  }
`

type UserProviderProps = {
  children?: React.ReactNode
}

const UserProvider: React.FunctionComponent<UserProviderProps> = (props) => {

  const { data, loading, refetch } = useQuery(QUERY, { errorPolicy: 'ignore' })
  const [signInMutation] = useMutation(SIGN_IN)
  const [signOutMutation] = useMutation(SING_OUT)

  const user = data ? data.user || null : null

  // ------

  const signIn = useCallback(async (username: string, password: string) => {
    setAuthToken(null)

    const { data: { createToken: { token } } } = await signInMutation({
      variables: {
        input: {
          username,
          password,
        },
      },
    })

    setAuthToken(token)

    return refetch()
  }, [signInMutation, refetch])

  // ------

  const refetchUser = useCallback(() => {
    return refetch()
  }, [refetch])

  // ------
  const signOut = useCallback(() => {
    signOutMutation({
      variables: {
        input: {},
      },
    }).then(() => {
      setAuthToken(null)
      window.location.reload()
    })
  }, [signOutMutation])

  const value: UserContextValue = {
    user,
    refetchUser,
    signIn,
    signOut,
  }

  if (loading) {
    return null
  }

  return (
    <UserContext.Provider value={value}>
      {props.children}
    </UserContext.Provider>
  )
}

export default UserProvider
