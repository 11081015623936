
// import cx from 'classnames'

import Icon from 'components/Icon'

import s from './ListDragHandle.scss'

const ListDragHandle = () => (
  <span className={s.root}>
    <Icon icon="hamburger" width="50%" height="50%" />
  </span>
)

export default ListDragHandle
