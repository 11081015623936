import { useRef, useEffect, useCallback, useMemo, forwardRef } from 'react'
import { createTextMaskInputElement } from 'text-mask-core'

type ArrayMask = Array<string | RegExp>

export type MaskedInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  mask: string | ArrayMask | ((string) => ArrayMask)
  guide?: boolean
  pipe?: () => void,
  placeholderChar?: string
  keepCharPositions?: boolean
  showMask?: boolean
}

const convertStringMask = (mask: string): ArrayMask => (
  mask.split('').map((char) => {
    if (char === '9') {
      return /\d/
    }
    if (char === 'a') {
      return /[A-Za-z]/
    }

    return char
  })
)

const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>((props, forwardedRef) => {
  const {
    mask: rawMask, guide, pipe, placeholderChar, keepCharPositions, showMask,
    value, onChange,...rest
  } = props

  const inputRef = useRef(null)
  const textMaskRef = useRef(null)

  const setInputRef = useCallback((node) => {
    inputRef.current = node

    if (forwardedRef) {
      if (typeof forwardedRef === 'function') {
        forwardedRef(node)
      }
      else {
        (forwardedRef as React.MutableRefObject<HTMLInputElement>).current = node
      }
    }
  }, [forwardedRef])

  const handleChange = useCallback((event) => {
    textMaskRef.current.update()

    if (typeof onChange === 'function') {
      onChange(event)
    }
  }, [onChange])

  const maskOptions = useMemo(() => {
    const mask = typeof rawMask === 'string'
      ? convertStringMask(rawMask)
      : rawMask

    return {
      mask,
      guide,
      pipe,
      placeholderChar,
      keepCharPositions,
      showMask,
    }
  }, [ rawMask, guide, pipe, placeholderChar, keepCharPositions, showMask ])

  // Update text mask object
  useEffect(() => {
    if (!inputRef.current) {
      return
    }

    const options = {
      ...maskOptions,
      inputElement: inputRef.current,
    }

    if (textMaskRef.current === null) {
      textMaskRef.current = createTextMaskInputElement(options)
    }

    textMaskRef.current.update(value, options)
  }, [ maskOptions, value ])

  useEffect(() => {
    return () => {
      textMaskRef.current = null
    }
  }, [])

  return (
    <input
      ref={setInputRef}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  )
})

export default MaskedInput
