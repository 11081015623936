
import { pathOr } from 'ramda'
import { useQuery, gql } from 'apollo'

import Select, { SelectProps } from 'components/Select'

type CurrencySelectProps = SelectProps & {
  children?: React.ReactNode
  className?: string
}

const query = gql`
  query CurrencySelectQuery{
    currencies {
      id
      value: id
      label: name
    }
  }
`

const CurrencySelect: React.FunctionComponent<CurrencySelectProps> = (props) => {
  const { ...rest } = props
  const result = useQuery(query)

  const currencies = pathOr([], ['data', 'currencies'], result)

  return (
    <Select
      options={currencies}
      {...rest}
    />
  )
}

export default CurrencySelect
