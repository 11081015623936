import { useCallback, useMemo } from 'react'
import { useFiltersState, getLinkWithSearch, links } from 'helpers'

import BaseFormField from 'components/BaseFormField'
import AccountSelect from 'components/data/AccountSelect'
import FiltersForm from 'components/FiltersForm'
import FlexItem from 'components/FlexItem'
import ButtonGroup from 'components/ButtonGroup'
import Button from 'components/Button'
import FormLabel from 'components/FormLabel'
import FormGroup from 'components/FormGroup'

type OperationsFiltersProps = {
  query: string
  accountIds: Array<string>
  onChange: (values: { query: string, accountIds: Array<string> }) => void
}

const OperationsFilters: React.FunctionComponent<OperationsFiltersProps> = (props) => {
  const { onChange } = props

  const filtersProps = useMemo(() => ({
    query: props.query || '',
    accountIds: props.accountIds || [],
  }), [props.query, props.accountIds])

  const [state, setState, onSubmit] = useFiltersState(filtersProps, onChange)

  const handleChange = useCallback((event: any) => {
    const { target: { name, value } } = event

    setState({ [name]: value })
  }, [setState])

  return (
    <FiltersForm onSubmit={onSubmit}>
      <FlexItem basis="60%" cloneChildren>
        <BaseFormField
          label="Искать"
          type="text"
          name="query"
          value={state.query}
          onChange={handleChange}
          autoFocus
          rounded
        />
      </FlexItem>
      <FlexItem basis="40%" cloneChildren>
        <BaseFormField
          label="Счета"
          type="select"
          name="accountIds"
          value={state.accountIds}
          onChange={handleChange}
          control={AccountSelect}
          multiple
          rounded
        />
      </FlexItem>
      <FlexItem none cloneChildren>
        <FormGroup>
          <FormLabel>Добавить</FormLabel>
          <ButtonGroup>
            <Button to={getLinkWithSearch(links.deposition.create)} rounded>Пополнение</Button>
            <Button to={getLinkWithSearch(links.transfer.create)} rounded>Перевод</Button>
            <Button to={getLinkWithSearch(links.payment.create)} color="primary" rounded>Платеж</Button>
          </ButtonGroup>
        </FormGroup>
      </FlexItem>
    </FiltersForm>
  )
}

export default OperationsFilters
