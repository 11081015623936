import { useContext, useEffect, useRef } from 'react'
import cx from 'classnames'
import DropdownContext from '../DropdownContext'

import s from './DropdownMenu.scss'

export type DropdownMenuProps = React.HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode
  className?: string
  absolute?: boolean
  fullWidth?: boolean
  focusOnOpen?: boolean
}

const DropdownMenu: React.FunctionComponent<DropdownMenuProps> = (props) => {
  const { children, className, fullWidth, absolute, focusOnOpen, ...rest } = props
  const { isOpened, close, containerRef } = useContext(DropdownContext)

  const menuRef = useRef(null)

  useEffect(() => {
    if (!isOpened) {
      return
    }

    if (focusOnOpen) {
      menuRef.current.focus()
    }

    const clickListener = (event: MouseEvent) => {
      const { target } = event

      const isInside = (
        menuRef.current.contains(target)
        || menuRef.current === target
      ) || (
        containerRef.current && (
          containerRef.current.contains(target)
          || containerRef.current === target
        )
      )

      if (!isInside) {
        close()
        event.stopPropagation()
        event.preventDefault()
      }
    }

    window.addEventListener('click', clickListener, true)

    return () => {
      window.removeEventListener('click', clickListener, true)
    }
  }, [isOpened, close, focusOnOpen, containerRef])

  if (!isOpened) {
    return null
  }

  const rootClassName = cx(className, {
    [s.fullWidth]: fullWidth,
    [s.absolute]: absolute,
  })

  return (
    <div
      className={rootClassName}
      ref={menuRef}
      tabIndex={-1}
      {...rest}
    >
      {children}
    </div>
  )
}

export default DropdownMenu
