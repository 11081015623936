import { useMemo } from 'react'

import { getTimestamp } from 'helpers'
import TransferForm from 'pages/transfer/TransferEditor/TransferForm'
import DepositionForm from 'pages/deposition/DepositionEditor/DepositionForm'
import PaymentForm from 'pages/payment/PaymentEditor/PaymentForm'
import { OperationData } from '../types'

type EditorProps = {
  id?: string
  data: OperationData
  type: string
  receipt?: any
  accountId?: string
  onSave: () => void
  onClose: () => void
}

const Editor: React.FunctionComponent<EditorProps> = ({ id, accountId, data, type, receipt, onSave, onClose }) => {
  const initialData = useMemo(() => {
    const { datetime, title, amount, fromAmount } = data

    let result: any = {
      accountId,
      title,
      amount: amount,
      datetime: getTimestamp(datetime),
    }

    if (type === 'transfer') {
      result = {
        ...result,
        fromAccountId: accountId,
        fromAmount,
        toAmount: amount,
      }

      delete result.amount
      delete result.accountId
    }
    else if (type === 'payment') {
      if (receipt) {
        result.items = receipt.items
      }
      else if (data.items) {
        result.items = data.items
      }
      else {
        result.items = [
          {
            title,
            price: amount,
            count: 1,
            amount,
          },
        ]
      }
    }

    return result
  }, [data, accountId, type, receipt])

  const editorProps = {
    id,
    initialData,
    onSubmit: onSave,
    onClose,
    onDelete: onSave,
  }

  if (type === 'transfer') {
    return (
      <TransferForm {...editorProps} />
    )
  }

  if (type === 'deposition') {
    return (
      <DepositionForm {...editorProps} />
    )
  }

  if (type === 'payment') {
    return (
      <PaymentForm {...editorProps} />
    )
  }
}

export default Editor
