import { useCallback, useRef } from 'react'
import { useReducerState } from 'helpers/hooks'

import { Option } from './types'

const useMenuState = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const menuItemsRef = useRef<React.RefObject<HTMLDivElement>[]>(null)
  const openMenuAfterFocus = useRef<boolean>(false)

  // ref to set rendered options
  const filteredOptionsRef = useRef<Option[]>(null)

  const [state, setState] = useReducerState({
    inputValue: '',
    isOpened: false,
    isFocused: false,
    selectedOption: null
  })

  const { inputValue, isOpened, isFocused, selectedOption } = state

  //
  const openMenu = useCallback(() => {
    setState({ isOpened: true, selectedOption: null })
  }, [setState])

  //
  const closeMenu = useCallback(() => {
    setState({ isOpened: false, selectedOption: null, inputValue: '' })
  }, [setState])

  // input state
  const handleInputChange = useCallback((event) => {
    setState({ inputValue: event.target.value })
  }, [setState])

  const handleInputFocus = useCallback(() => {
    const update: any = {
      isFocused: true,
    }

    if (openMenuAfterFocus.current) {
      update.isOpened = true
      openMenuAfterFocus.current = false
    }

    setState(update)
  }, [setState])

  const handleInputBlur = useCallback(() => {
    if (menuRef.current && menuRef.current.contains(document.activeElement)) {
      inputRef.current.focus()
      return
    }

    setState({ inputValue: '', isOpened: false, isFocused: false })
  }, [setState])

  //
  const scrollToOptionIndex = useCallback((index: number) => {
    try {
      const container = menuRef.current

      const item = menuItemsRef.current[index].current

      const scrollTop = container.scrollTop
      const containerHeight = container.clientHeight

      const elementTop = item.offsetTop - scrollTop
      const elementBottom = elementTop + item.clientHeight

      const isAbove = elementTop < 0
      const isBellow = elementBottom > containerHeight

      if (isAbove) {
        container.scrollTo(0, item.offsetTop)
      }
      else if (isBellow) {
        container.scrollTo(0, scrollTop + (elementBottom - containerHeight))
      }
    }
    catch (error) {
      console.log('Can\'t scroll to option', error)
    }
  }, [])

  const setSelectedOption = useCallback((option?: Option) => {
    setState({ selectedOption: option })
  }, [setState])

  const selectNextOption = useCallback(() => {
    const options = filteredOptionsRef.current
    let index = options.indexOf(selectedOption) + 1

    if (index >= options.length) {
      index = 0
    }

    setSelectedOption(options[index])
    scrollToOptionIndex(index)
  }, [scrollToOptionIndex, selectedOption, setSelectedOption])

  const selectPerviousOption = useCallback(() => {
    const options = filteredOptionsRef.current
    let index = options.indexOf(selectedOption) - 1

    if (index < 0) {
      index = options.length - 1
    }

    setSelectedOption(options[index])
    scrollToOptionIndex(index)
  }, [scrollToOptionIndex, selectedOption, setSelectedOption])

  return {
    inputValue,
    onInputChange: handleInputChange,
    onInputFocus: handleInputFocus,
    onInputBlur: handleInputBlur,
    isOpened,
    isFocused,
    openMenu,
    closeMenu,
    inputRef,
    menuRef,
    menuItemsRef,
    openMenuAfterFocus,
    filteredOptionsRef,
    selectedOption,
    setSelectedOption,
    selectNextOption,
    selectPerviousOption,
  }
}

export default useMenuState
