import { useState, useCallback } from 'react'

const useField = (initialValue?: any, onChange?: (value: any) => void) => {
  const [value, setValue] = useState(initialValue)

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setValue(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [onChange])

  return [value, handleChange, setValue]
}

export default useField
