
import Text from 'components/Text'
import { Operation } from 'pages/operations/OperationsList/types'

type OperationInfoProps = {
  data: Operation
}

const OperationInfo: React.FunctionComponent<OperationInfoProps> = ({ data }) => {
  const { origin, account } = data
  const { market, fromAccount, toAccount } = origin

  if (!market && !fromAccount && !toAccount) {
    return null
  }

  return (
    <Text size="sm">
      {
        market && (
          <span>{market.name}</span>
        )
      }
      {
        fromAccount && fromAccount.id === account.id && (
          <span>Перевод на {toAccount.name}</span>
        )
      }
      {
        toAccount && toAccount.id === account.id && (
          <span>Перевод из {fromAccount.name}</span>
        )
      }
    </Text>
  )
}

export default OperationInfo
