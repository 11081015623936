
import List from 'components/List'

import ReceiptItem, { ReceiptItemProps } from './ReceiptItem/ReceiptItem'

type ReceiptsListProps = {
  className?: string
  items: Array<ReceiptItemProps['data']>
}

const ReceiptsList = (props: ReceiptsListProps) => {
  const { items } = props

  return (
    <List>
      {items && items.map((item) => (
        <ReceiptItem key={item.id} data={item} />
      ))}
    </List>
  )
}

export default ReceiptsList
