import React from 'react'

export type UserContextValue = {
  user?: {
    username: string
  }
  refetchUser?: () => void,
  signIn?: (username: string, password: string) => Promise<any>,
  signOut?: () => void,
}

export const UserContext = React.createContext<UserContextValue>({
  user: null,
  refetchUser: () => undefined,
  signIn: () => Promise.reject(),
  signOut: () => undefined,
})
