import React, { useState, useCallback, useEffect, useMemo } from 'react'
import cx from 'classnames'

import IconButton from 'components/IconButton'

import s from './Nav.scss'

type NavProps = {
  isHamburgerEnabled: boolean
  className?: string
  children?: React.ReactNode
}

const Nav: React.FunctionComponent<NavProps> = ({ isHamburgerEnabled, className, children }) => {
  const [isOpen, setOpen] = useState(false)

  const toggleOpen = useCallback(() => {
    setOpen(!isOpen)
  }, [isOpen])

  useEffect(() => {
    if (!isHamburgerEnabled && isOpen) {
      setOpen(false)
    }
  }, [isHamburgerEnabled, isOpen])

  const processedChildren = useMemo(() => (
    React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child
      }

      return React.cloneElement(child, {
        onClick: (event) => {
          setOpen(false)

          const { onClick } = child.props

          if (typeof onClick === 'function') {
            onClick(event)
          }
        }
      })
    })
  ), [children])

  return (
    <div className={cx(s.root, className)}>
      <nav className={cx(s.nav, {
        [s.fixed]: isHamburgerEnabled,
        [s.opened]: isHamburgerEnabled && isOpen,
      })}>
        {processedChildren}
      </nav>
      {
        isHamburgerEnabled && (
          <IconButton
            className={s.hamburgerButton}
            icon="hamburger"
            title="Развернуть меню"
            onClick={toggleOpen}
          />
        )
      }
    </div>
  )
}

export default Nav
