import { useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import { pathOr } from 'ramda'
import { links, getLinkWithSearch } from 'helpers'

import PaymentForm from './PaymentForm'

type PaymentEditorProps = RouteComponentProps<any>

const PaymentEditor = (props: PaymentEditorProps) => {
  const { match, history } = props
  const id = pathOr(null, ['params', 'id'], match)

  const handleSubmit = useCallback(() => {
    history.push(getLinkWithSearch(links.operations.root))
  }, [ history ])

  const handleClose = useCallback(() => {
    history.push(getLinkWithSearch(links.operations.root))
  }, [history])

  return (
    <PaymentForm
      id={id}
      onSubmit={handleSubmit}
      onClose={handleClose}
      onDelete={handleClose}
    />
  )
}

export default PaymentEditor
