import { memo } from 'react'
import { useHasFocus } from 'helpers'
import FormGroup from 'components/FormGroup'
import FormLabel from 'components/FormLabel'
import FormError, { FormErrorProps } from 'components/FormError'
import TextInput, { TextInputProps } from 'components/TextInput'
import Select, { SelectProps } from 'components/Select'
import Toggle, { ToggleProps } from 'components/Toggle'
import Autocomplete, { AutocompleteProps } from 'components/Autocomplete'
import DateTimeInput from 'components/DateTimeInput'

export type BaseFormFieldProps = {
  control?: 'text' | 'select' | 'checkbox' | 'autocomplete' | 'datetime' | React.ComponentType
  controlProps?: any
  label?: React.ReactNode,
  labelProps?: any
  errorProps?: FormErrorProps
  id?: string,
  name?: string
  value?: any
  type?: string
  checked?: boolean
  multiple?: boolean
  error?: any
  style?: React.StyleHTMLAttributes<HTMLStyleElement>
  onChange?: (e: any) => void | ((value: any) => void),
  onBlur?: (e: any) => void,
  onFocus?: (e: any) => void,
} & (
  Partial<TextInputProps>
  | Partial<SelectProps>
  | Partial<ToggleProps>
  | Partial<AutocompleteProps>
)

const BaseFormField: React.FunctionComponent<BaseFormFieldProps> = (props) => {
  const {
    className, control, controlProps, label, labelProps, errorProps, error,
    id, name, value, checked, onChange, onBlur, onFocus, style, ...rest
  } = props
  const { hasFocus, handleBlur, handleFocus } = useHasFocus({ onBlur, onFocus })

  const realId = id || `form-field_${name}`
  const hasError = !!error

  const mergedLabelProps = {
    ...labelProps,
    htmlFor: realId,
    hasError,
  }

  const mergedControlProps: any = {
    ...rest,
    ...controlProps,
    id: realId,
    name,
    value,
    hasFocus,
    hasError,
    onChange,
    onBlur: handleBlur,
    onFocus: handleFocus,
  }

  let Control = null

  if (typeof control === 'string') {
    switch (control) {
      case 'select': {
        Control = Select
        break
      }
      case 'checkbox': {
        Control = Toggle
        mergedControlProps.type = 'checkbox'
        mergedControlProps.checked = checked
        break
      }
      case 'autocomplete': {
        Control = Autocomplete
        break
      }
      case 'datetime': {
        Control = DateTimeInput
        break
      }
      case 'text': {
        Control = TextInput
        if (mergedControlProps.value === null) {
          mergedControlProps.value = ''
        }
        break
      }
    }
  } else {
    Control = control
  }

  if (!Control) {
    throw new Error('Invalid Control')
  }

  return (
    <FormGroup className={className} style={style}>
      {label && (
        <FormLabel {...mergedLabelProps}>
          {label}
        </FormLabel>
      )}
      <Control {...mergedControlProps} />
      {!!error && (
        <FormError {...errorProps}>
          {error}
        </FormError>
      )}
    </FormGroup>
  )
}

BaseFormField.defaultProps = {
  control: 'text',
}

export default memo(BaseFormField)
