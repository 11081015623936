import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'

import { Option, OptionRenderer, OptionClick } from '../../types'

import s from './MenuItem.scss'

type MenuItemProps = {
  children?: OptionRenderer
  option: Option
  selected: boolean
  focused: boolean
  onClick: OptionClick
  onHover: (option: Option) => void,
}

const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>((props, ref) => {
  const { children, option, selected, focused, onClick, onHover } = props

  const rootClassName = cx(s.item, {
    [s.selected]: selected,
    [s.focused]: focused,
  })

  const handleClick = useCallback(() => {
    onClick(option)
  }, [onClick, option])

  const handleHover = useCallback(() => {
    onHover(option)
  }, [onHover, option])

  const content = useMemo(() => {
    if (typeof children === 'function') {
      return children(option)
    }

    if (option.__isNew__) {
      return `Создать ${option.label}`
    }

    return option.label
  }, [ option, children ])

  return (
    <div
      className={rootClassName}
      role="button"
      tabIndex={-1}
      ref={ref}
      onClick={handleClick}
      onMouseOver={handleHover}
    >
      {content}
    </div>
  )
})

export default MenuItem
