import { useRef, useState, useCallback } from 'react'

type ChangeEventHandler<T> = (state: T) => void
type StateUpdater<T> = (update: Partial<T>) => void

const useFiltersState = <T = unknown>(propsState: T, onChange: ChangeEventHandler<T>): [T, StateUpdater<T>, () => void] => {
  const [state, setState] = useState<T>(propsState)

  const propsStateRef = useRef(propsState)

  if (propsState !== propsStateRef.current && propsState !== state) {
    propsStateRef.current = propsState
    setState(propsState)
  }

  const timeoutRef = useRef(null)

  const setFilterState = useCallback((update: Partial<T>) => {
    const newState = { ...state, ...update }

    setState(newState)

    clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
      onChange(newState)
    }, 500)
  }, [onChange, state])

  const handleSubmit = useCallback(() => {
    onChange(state)
  }, [state, onChange])

  return [state, setFilterState, handleSubmit]
}

export default useFiltersState
