import { useCallback, Fragment, useMemo, useEffect } from 'react'
import { gql, useQuery } from 'apollo'
import { Route, Switch } from 'react-router'
import { useQueryParams, stringifyQueryParams, useFetchMore, pathOr, links, getParsedQuery } from 'helpers'

import ContentLoader from 'components/ContentLoader'
import PageError from 'components/PageError'
import Pagination from 'components/Pagination'
import Container from 'components/Container'

import DepositionEditor from 'pages/deposition/DepositionEditor'
import TransferEditor from 'pages/transfer/TransferEditor'
import PaymentEditor from 'pages/payment/PaymentEditor'

import OperationsList, { OperationsListFragment } from '../OperationsList'
import OperationsFilters from '../OperationsFilters'

const QUERY = gql`
  query OperationsRootQuery($input: OperationsInput) {
    operations(input: $input) @connection(key: "OperationsRootFeed", filters: ["accountId", "query", "type", "dates"]) {
      items {
        id
        ...OperationsList
      }
      pageInfo {
        page
        pageCount
      }
    }
  }
  ${OperationsListFragment}
`

type OperationsQueryParams = {
  page?: number
  perPage?: number
  query?: string
  accountIds?: Array<string>
  dates?: any
}

type FilterValues = {
  query: string
  accountIds: Array<string>
}

const OperationsRoot = ({ location, history }) => {
  const params = useQueryParams<OperationsQueryParams>(({ page, perPage, query, accountIds }: any) => ({
    page: Number.parseInt(page as string, 10) || 1,
    perPage: Number.parseInt(perPage as string, 10) || 40,
    query,
    accountIds,
  }), location)

  const variables = useMemo(() => {
    const input = {
      ...params,
      ...getParsedQuery(params.query),
    }

    return { input }
  }, [params])

  const { data, loading, error, fetchMore } = useQuery(QUERY, {
    variables,
  })

  useFetchMore(fetchMore, variables)

  const handlePageChange = useCallback((page) => {
    const newParams = { ...params, page }
    const newLocation = { search: stringifyQueryParams(newParams) }

    history.push(newLocation)
  }, [params, history])

  const handleFiltersChange = useCallback((values: FilterValues) => {
    const newParams = { ...params, ...values }
    const newLocation = { search: stringifyQueryParams(newParams) }

    history.push(newLocation)
  }, [params, history])

  const items = pathOr([], ['operations', 'items'], data)
  const pageInfo = pathOr([], ['operations', 'pageInfo'], data)

  // Empty result with not the first page
  useEffect(() => {
    if (data && params.page > 1 && items.length === 0) {
      history.push({ search: stringifyQueryParams({ ...params, page: 1 }) })
    }
  }, [data, history, items, params])

  return (
    <div>
      <Container verticalPadding>
        <OperationsFilters query={params.query} accountIds={params.accountIds} onChange={handleFiltersChange} />
        {
          error ? (
            <PageError error={error} />
          ) : (
            loading ? (
              <ContentLoader />
            ) : (
              <Fragment>
                <OperationsList operations={items} />
                <Pagination {...pageInfo} fixed onChange={handlePageChange} />
              </Fragment>
            )
          )
        }
      </Container>
      <Switch>
        <Route exact={true} path={links.deposition.create} component={DepositionEditor} />
        <Route exact={true} path={links.transfer.create} component={TransferEditor} />
        <Route exact={true} path={links.payment.create} component={PaymentEditor} />
        <Route exact={true} path={links.deposition.edit} component={DepositionEditor} />
        <Route exact={true} path={links.transfer.edit} component={TransferEditor} />
        <Route exact={true} path={links.payment.edit} component={PaymentEditor} />
      </Switch>
    </div>
  )
}

export default OperationsRoot
