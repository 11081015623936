
import { OperationLinkGetter, Operations } from '../types'

import List from 'components/List'
import Item from './Item'

type MobileProps = {
  operations: Operations
  getOperationLink: OperationLinkGetter
}

const Mobile: React.FunctionComponent<MobileProps> = ({ operations, getOperationLink }) => {
  return (
    <List>
      {
        operations.map((operation) => (
          <Item
            key={operation.id}
            data={operation}
            getOperationLink={getOperationLink}
          />
        ))
      }
    </List>
  )
}

export default Mobile
