
import cx from 'classnames'

import s from './Text.scss'

export type TextProps = {
  [key: string]: any
  className?: string
  children?: React.ReactNode
  text?: string
  size?: 'h1' | 'h2' | 'sm' | 'md'
  weight?: 'light' | 'regular' |'medium' | 'semi' | 'bold',
  align?: 'left' | 'right' | 'center'
  underline?: boolean
  lineThrough?: boolean
  muted?: boolean
  uppercase?: boolean
  component?: React.ElementType
}

const Text: React.FunctionComponent<TextProps> = (props) => {
  const {
    children, text, className, component: Tag = 'div',
    size, weight, align, underline, lineThrough, muted, uppercase,
  } = props

  const rootClassName = cx(className, {
    [s[size]]: size && s[size],
    [s[weight]]: weight && s[weight],
    [s[align]]: s[align],
    [s.underline]: underline,
    [s.lineThrough]: lineThrough,
    [s.muted]: muted,
    [s.uppercase]: uppercase,
  })

  return (
    <Tag className={rootClassName}>
      {text || children}
    </Tag>
  )
}

export default Text
