import { memo, useCallback, useMemo, useRef, useState } from 'react'
import { equals } from 'ramda'
import Flex from 'components/Flex'
import CategoriesSelect from 'components/data/CategoriesSelect'
import ProductSelect from 'components/data/ProductSelect'
import IconButton from 'components/IconButton'
import BaseFormField from 'components/BaseFormField'

import s from './Item.scss'
import { toFixed } from 'helpers'

export type ItemState = {
  id?: string
  clientId?: string
  productId?: string
  title?: string
  price?: number
  count?: number
  amount?: number
  categoriesIds?: Array<string>
}

type ItemProps = {
  name: string
  index: number
  value: ItemState
  onChange: (index: number, value: ItemState) => void
  onRemove: (index: number) => void
}

const Item: React.FunctionComponent<ItemProps> = (props) => {
  const { name, index, value: propValue, onChange, onRemove } = props

  const propValueRef = useRef(propValue)
  const valueRef = useRef(propValue)

  const [ , setUpdate ] = useState(0)
  const forceUpdate = useCallback(() => {
    setUpdate((prevState) => prevState + 1)
  }, [])

  if (propValue !== propValueRef.current && !propValue !== valueRef.current) {
    valueRef.current = propValue
    propValueRef.current = propValue
  }

  const handleChange = useCallback(() => {
    onChange(index, valueRef.current)
  }, [index, onChange])

  const updateField = useCallback((name: string, value: any) => {
    valueRef.current = { ...valueRef.current, [name]: value }

    forceUpdate()
    handleChange()
  }, [forceUpdate, handleChange])

  const handleDelete = useCallback(() => {
    if (confirm('Вы уверены?')) {
      onRemove(index)
    }
  }, [ index, onRemove ])

  const handleFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: fieldName, value } = event.target

    updateField(fieldName.substr(name.length + 1), value)
  }, [ name.length, updateField ])

  const handleProductSelect = useCallback((productValue: any) => {
    if (productValue && productValue.categoriesIds) {
      updateField('categoriesIds', productValue.categoriesIds)
    }
  }, [updateField])

  const handleNumberBlur = useCallback((base: string) => (event: React.FocusEvent<HTMLInputElement>) => {
    const value = toFixed(parseFloat(event.target.value), base === 'count' ? 4 : 2)
    const { count, price, amount } = valueRef.current

    if (value) {
      switch (base) {
        case 'price': {
          if (count) {
            updateField('amount', toFixed(value * count))
          } else if (!count && amount) {
            updateField('count', toFixed(amount / value, 4))
          }
          break
        }
        case 'count': {
          if (price) {
            updateField('amount', toFixed(value * price))
          } else if (!price && amount) {
            updateField('price', toFixed(amount / value))
          }
          break
        }
        case 'amount': {
          if (price) {
            updateField('count', toFixed(value / price, 4))
          } else if (!price && count) {
            updateField('price', toFixed(value / count))
          }
          break
        }
      }
    }
  }, [ updateField ])

  const handlePriceBlur = useMemo(() => handleNumberBlur('price'), [ handleNumberBlur ])
  const handleCountBlur = useMemo(() => handleNumberBlur('count'), [ handleNumberBlur ])
  const handleAmountBlur = useMemo(() => handleNumberBlur('amount'), [ handleNumberBlur ])

  const value = valueRef.current
  const {
    productId = null,
    title = '',
    price = 0,
    count = 1,
    amount = 0,
    categoriesIds = null,
  } = value

  return (
    <Flex wrap>
      <BaseFormField
        className={s.product}
        name={`${name}.productId`}
        value={productId}
        control={ProductSelect}
        onChange={handleFieldChange}
        onSelect={handleProductSelect}
      />
      <BaseFormField
        className={s.title}
        name={`${name}.title`}
        type="text"
        placeholder="Описание"
        value={title}
        onChange={handleFieldChange}
      />
      <BaseFormField
        className={s.price}
        name={`${name}.price`}
        type="number"
        placeholder="Цена"
        value={price}
        onChange={handleFieldChange}
        onBlur={handlePriceBlur}
      />
      <BaseFormField
        className={s.count}
        name={`${name}.count`}
        type="number"
        placeholder="Кол-во"
        value={count}
        onChange={handleFieldChange}
        onBlur={handleCountBlur}
      />
      <BaseFormField
        className={s.amount}
        name={`${name}.amount`}
        type="number"
        placeholder="Сумма"
        value={amount}
        onChange={handleFieldChange}
        onBlur={handleAmountBlur}
      />
      <BaseFormField
        className={s.categories}
        name={`${name}.categoriesIds`}
        control={CategoriesSelect}
        value={categoriesIds}
        onChange={handleFieldChange}
      />
      <IconButton
        className={s.delete}
        icon="close"
        color="danger"
        tabIndex={-1}
        onClick={handleDelete}
      />
    </Flex>
  )
}

export default memo(Item, equals)
