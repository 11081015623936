import { useState, useCallback, useMemo } from 'react'
import { prop, values, map, sortBy, compose, groupBy } from 'ramda'
import { DateTime } from 'luxon'
import { useLazyQuery, gql } from 'apollo'
import { getTimestamp, pathOr } from 'helpers'

import { OperationData } from '../../types'
import tinkoffCSVParser from './tinkoffCSVParser'
import sberbankJSONParser from './sberbankJSONParser'

const SEARCH_QUERY = gql`
  query ImportTinkoffCSVTimelineQuery($input: OperationsInput!) {
    operations(input: $input) {
      items {
        id
        type
        title
        datetime
        amount
        origin {
          id
        }
      }
    }
  }
`

const initialState: {
  isParsing: boolean
  operations: Array<OperationData>
  startDate: DateTime
  endDate: DateTime
} = {
  isParsing: false,
  operations: null,
  startDate: null,
  endDate: null,
}

const useParser = ({ accountId }: { accountId?: string }) => {
  const [state, setState] = useState(initialState)
  const { isParsing, startDate, endDate } = state

  const parse = useCallback(async (data: string) => {
    setState({
      isParsing: true,
      operations: null,
      startDate: null,
      endDate: null,
    })

    try {
      const isJson = data.startsWith('{')
      const result = isJson ? await sberbankJSONParser(data) : await tinkoffCSVParser(data)

      setState({
        isParsing: false,
        ...result,
      })
    }
    catch (error) {
      console.error(error)

      setState((state) => ({
        ...state,
        isParsing: false,
      }))
    }
  }, [])

  // Timeline
  const timelineVariables = useMemo(() => {
    return {
      input: {
        accountIds: accountId ? [accountId] : undefined,
        dates: [[
          getTimestamp(startDate),
          getTimestamp(endDate),
        ]],
      },
    }
  }, [accountId, startDate, endDate])

  const [loadTimelineBase, { data: timelineData }] = useLazyQuery(SEARCH_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: timelineVariables,
  })

  const loadTimeline = useCallback(() => {
    if (startDate && endDate && accountId) {
      loadTimelineBase()
    }
  }, [accountId, endDate, loadTimelineBase, startDate])

  const operations = useMemo(() => {
    const operations = pathOr([], ['operations'], state)
    const timeline = pathOr([], ['operations', 'items'], timelineData).map(({ datetime, ...item }) => ({
      ...item,
      datetime: DateTime.fromMillis(datetime * 1000).toLocal(),
    }))

    let items = [
      ...operations,
      ...timeline,
    ]

    items = compose(
      map(
        (group: any[]) => group.reduce((acc, item) => {
          acc[item.id ? 1 : 0].push(item)
          return acc
        }, [[], []])
      ),
      values,
      groupBy((item: any) => {
        return item.datetime.startOf('hour')
      }),
      sortBy(prop('datetime'))
    )(items)

    return items
  }, [ state, timelineData ])

  return {
    operations,
    isParsing,
    parse,
    loadTimeline,
  }
}

export default useParser
