import { useCallback } from 'react'
import { pathOr } from 'ramda'
import { useQuery, useMutation, gql } from 'apollo'

import Select, { SelectProps } from 'components/Select'

type CategoriesSelectProps = SelectProps & {
  children?: React.ReactNode
  className?: string
}

const QUERY = gql`
  query CategoriesSelectQuery {
    categories {
      items {
        id
        value: id
        label: name
      }
    }
  }
`

const CREATE_MUTATION = gql`
  mutation CategorySelect_CreateMutation($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      node {
        id
        value: id
        label: name
      }
    }
  }
`

const CategoriesSelect: React.FunctionComponent<CategoriesSelectProps> = (props) => {
  const result = useQuery(QUERY)

  const options = pathOr([], ['data', 'categories', 'items'], result)

  const [ createMutation, { loading } ] = useMutation(CREATE_MUTATION, {
    refetchQueries: ['CategoriesSelectQuery']
  })

  const handleCreate = useCallback((name: string) => {
    return createMutation({ variables: { input: { name } } })
      .then((result) => {
        const option = pathOr(null, ['data', 'createCategory', 'node'], result)

        return option
      })
  }, [createMutation])

  return (
    <Select
      options={options}
      multiple
      creatable
      isLoading={loading}
      onCreateOption={handleCreate}
      {...props}
    />
  )
}

export default CategoriesSelect
