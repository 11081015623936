/* eslint-disable quotes */
import { useMemo } from 'react'
import Fuse from 'fuse.js'

import { Option } from '../types'

type UseFilteredOptionsProps = {
  options: Option[]
  value: Option | Option[] | null
  inputValue?: string
  creatable?: boolean
}

const useFilteredOptions = (props: UseFilteredOptionsProps) => {
  const { options, value, inputValue, creatable } = props

  const index = useMemo(() => {
    return new Fuse(options, {
      keys: ['label'],
      shouldSort: true,
      findAllMatches: true,
      threshold: 0.1,
      location: 0,
      distance: 100,
    })
  }, [options])

  const filteredOptions = useMemo(() => {
    if (inputValue) {
      const results = index.search(inputValue).map(({ item }) => item)

      if (creatable) {
        results.push({ label: inputValue, value: inputValue, __isNew__: true })
      }

      return results
    }

    return options
  }, [index, inputValue, creatable, options])

  return useMemo(() => {
    if (Array.isArray(value)) {
      const keys = value.map((option) => option.value)

      return filteredOptions.filter((option) => keys.indexOf(option.value) === -1)
    }

    return filteredOptions
  }, [filteredOptions, value])
}

export default useFilteredOptions
