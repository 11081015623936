import { memo, useCallback, useState } from 'react'
import { getOriginRoot, getLink } from 'helpers'

import FormattedDate from 'components/FormattedDate'
import FormattedCurrency from 'components/FormattedCurrency'
import ListItem from 'components/ListItem'
import Flex from 'components/Flex'
import FlexItem from 'components/FlexItem'
import Button from 'components/Button'

import { ExistingOperationData } from 'pages/import/types'
import Editor from 'pages/import/components/Editor'

import FormGroup from 'components/FormGroup'
import FormLabel from 'components/FormLabel'

import s from './ExistingItem.scss'

type ItemProps = {
  data: ExistingOperationData
}

const types = {
  'transfer': 'Перевод',
  'deposition': 'Пополнение',
  'payment': 'Платеж',
}

const Item: React.FunctionComponent<ItemProps> = (props) => {
  const { data } = props
  const [isEditorOpened, setEditorOpened] = useState(false)

  const handleEditClick = useCallback((event) => {
    setEditorOpened(true)

    if (event) {
      event.preventDefault()
    }
  }, [])

  const handleEditorClose = useCallback(() => {
    setEditorOpened(false)
  }, [])

  return (
    <ListItem className={s.item}>
      <Flex>
        <FlexItem basis="40%" grow={0} shrink={0}>
          {types[data.type]}<br />
          <FormattedDate value={data.datetime} /><br />
          {data.title}<br />
          {data.currencyCode}
          <FormattedCurrency value={data.amount} currency={data.currencyCode} /><br />
        </FlexItem>
        <FlexItem basis="60%" ml={16} grow={0} shrink={0}>
          <FormGroup>
            <FormLabel>Действия</FormLabel>
            <Button
              to={getLink(getOriginRoot(data.type).edit, { id: data.origin.id })}
              target="_blank"
              onClick={handleEditClick}
            >
              Редактировать {data.title}
            </Button>
          </FormGroup>
        </FlexItem>
      </Flex>
      {
        isEditorOpened && (
          <Editor
            id={data.origin.id}
            data={data}
            receipt={null}
            type={data.type}
            accountId={data.accountId}
            onClose={handleEditorClose}
            onSave={handleEditorClose}
          />
        )
      }
    </ListItem>
  )
}

export default memo(Item)
