import React from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import { gql } from 'apollo'
import { links, getLink } from 'helpers'
import ListItem from 'components/ListItem'
import ListDragHandle from 'components/ListDragHandle'
import Flex from 'components/Flex'
import Box from 'components/Box'
import Text from 'components/Text'
import FormattedCurrency from 'components/FormattedCurrency'

const DragHandle = SortableHandle(ListDragHandle)

export type AccountProps = {
  id: string
  name: string
  notes: string
  balance: number
  show: boolean
  currency: {
    isoCode: string
    price: number
  }
}

type AccountsListItemProps = {
  item: AccountProps
}

const AccountsListItem = (props: AccountsListItemProps) => {
  const { item } = props

  return (
    <ListItem to={getLink(links.accounts.edit, { id: item.id })}>
      <Flex alignItems="flex-start">
        <DragHandle />
        <div>
          <Text lineThrough={!item.show} text={item.name} />
          <Box mt={8}>
            <Text size="sm" muted text={item.notes} />
          </Box>
        </div>
        <Box ml="auto">
          <Text align="right">
            <FormattedCurrency value={item.balance} currency={item.currency.isoCode} />
            {
              item.currency.isoCode !== 'RUB' && (
                <Box mt={8}>
                  <Text size="sm" muted>
                    <FormattedCurrency value={item.balance * item.currency.price} />
                  </Text>
                </Box>
              )
            }
          </Text>
        </Box>
      </Flex>
    </ListItem>
  )
}

export const AccountsListItemFragment = gql`
  fragment AccountsListItem on Account {
    id
    name
    notes
    balance
    sort
    show
    currency {
      id
      isoCode
      price
    }
  }
`

export default React.memo(AccountsListItem)
