import { useState, useCallback, useRef } from 'react'
import { Option } from './types'

const useValueState = (props) => {
  const {
    value: propsValue,
    multiple,
    onCreateOption,
    onChange,
  } = props

  const propsValueRef = useRef(propsValue)
  const valueRef = useRef(null)
  const [value, setValue] = useState<Option | Option[]>(propsValueRef.current)

  // updates value from props
  if (propsValue !== propsValueRef.current && propsValue !== valueRef.current) {
    propsValueRef.current = propsValue
    valueRef.current = propsValue
    setValue(propsValue)
  }

  // checks if option is already selected
  const isSelected = useCallback((lookupValue: Option) => {
    if (Array.isArray(value)) {
      return value.some((v) => v === lookupValue)
    }

    return value === lookupValue
  }, [value])

  //
  const handleSetValue = useCallback((value: Option | Option[], skipOnChange = false) => {
    valueRef.current = value
    setValue(value)

    if (!skipOnChange) {
      if (typeof onChange === 'function') {
        onChange(value)
      }
    }
  }, [onChange])

  //
  const handleSelectOption = useCallback((option: Option) => {
    if (!isSelected(option)) {
      const isNewWithAsync = option.__isNew__ === true && typeof onCreateOption === 'function'

      if (multiple) {
        handleSetValue([].concat(value, option).filter((v) => !!v), isNewWithAsync)
      }
      else {
        handleSetValue(option, isNewWithAsync)
      }

      // When adding created option
      if (isNewWithAsync) {
        const createResult = onCreateOption(option.label)

        if (typeof createResult !== undefined) {
          // replace placeholder value with the created one
          Promise.resolve(createResult).then((createdOption?: Option) => {
            if (multiple) {
              handleSetValue((valueRef.current as Option[]).map((v) => v === option ? createdOption : v))
            }
            else if (valueRef.current === option) {
              handleSetValue(createdOption)
            }
          })
        }
      }
    }
  }, [handleSetValue, isSelected, multiple, onCreateOption, value])

  // remove option
  const handleRemoveOption = useCallback((option: Option) => {
    if (isSelected(option)) {
      if (multiple && Array.isArray(value)) {
        handleSetValue(value.filter((v) => v !== option))
      }
      else {
        handleSetValue(null)
      }
    }
  }, [handleSetValue, isSelected, multiple, value])

  const isFilled = Array.isArray(value) ? value.length > 0 : Boolean(value)

  return {
    value,
    setValue: handleSetValue,
    selectOption: handleSelectOption,
    removeOption: handleRemoveOption,
    isFilled,
  }
}

export default useValueState
