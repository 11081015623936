import { useCallback, useMemo } from 'react'
import cx from 'classnames'
import IconButton from 'components/IconButton'

import s from './TimePicker.scss'
import { DateTime } from 'luxon'

const parse = (str: string): [number, number] => {
  if (!str) {
    return [0, 0]
  }
  const parts = str.split(':')

  return [
    Number.parseInt(parts[0], 10),
    Number.parseInt(parts[1], 10)
  ]
}
const toString = (num: number): string => num.toString().padStart(2, '0')

export type TimePickerProps = {
  className?: string
  value: DateTime
  onChange: (value: DateTime) => void
}

const TimePicker: React.FunctionComponent<TimePickerProps> = (props) => {
  const { className, value, onChange } = props

  const parts = useMemo(() => {
    const str = value ? value.toFormat('HH:mm') : '00:00'

    return parse(str)
  }, [value])

  const createHandler = useCallback((part: number, number: number) => () => {
    parts[part] += number
    if (parts[0] < 0) parts[0] += 24
    else if (parts[0] >= 24) parts[0] -= 24
    if (parts[1] < 0) parts[1] += 60
    else if (parts[1] >= 60) parts[1] -= 60

    const date = (value || DateTime.local()).set({ hour: parts[0], minute: parts[1], second: 0 })

    if (date.isValid) {
      onChange(date)
    }
  }, [onChange, parts, value])

  const hourUpClick = useMemo(() => createHandler(0, 1), [createHandler])
  const hourDownClick = useMemo(() => createHandler(0, -1), [createHandler])

  const minuteUpClick = useMemo(() => createHandler(1, 1), [createHandler])
  const minuteDownClick = useMemo(() => createHandler(1, -1), [createHandler])

  return (
    <div className={cx(s.root, className)}>
      <div className={s.number}>
        <IconButton className={s.button} icon="arrow_up" onClick={hourUpClick} />
        <div className={s.value}>{toString(parts[0])}</div>
        <IconButton className={s.button} icon="arrow_down" onClick={hourDownClick} />
      </div>
      <div className={s.delimiter}>:</div>
      <div className={s.number}>
        <IconButton className={s.button} icon="arrow_up" onClick={minuteUpClick} />
        <div className={s.value}>{toString(parts[1])}</div>
        <IconButton className={s.button} icon="arrow_down" onClick={minuteDownClick} />
      </div>
    </div>
  )
}

export default TimePicker
