
import cx from 'classnames'

import s from './FormError.scss'

export type FormErrorProps = {
  className?: string
  children?: React.ReactNode
  summary?: boolean
}

const FormError: React.FunctionComponent<FormErrorProps> = ({ className, summary, children }) => (
  <div className={cx(className, s.root, { [s.isSummary]: summary })}>
    {children}
  </div>
)

export default FormError
