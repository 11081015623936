import { useUser } from 'providers/UserProvider'
import links from 'helpers/links'

import Container from 'components/Container'
import Nav from 'layout/Header/Nav'
import NavItem from 'layout/Header/NavItem'
import IconButton from 'components/IconButton'
import Logo from './Logo'

import s from './Header.scss'
import { useMedia } from 'helpers'

const Header: React.FunctionComponent = () => {
  const { signOut } = useUser()
  const { isMobile } = useMedia()

  const isHamburgerEnabled = isMobile
  const isDev = process.env.DEV

  return (
    <header className={s.root}>
      <Logo />
      <Container className={s.navContainer}>
        <Nav isHamburgerEnabled={isHamburgerEnabled} className={s.nav}>
          <NavItem to={links.root} title="Операции" exact />
          <NavItem to={links.accounts.root} title="Счета" />
          <NavItem to={links.markets.root} title="Магазины" />
          <NavItem to={links.products.root} title="Продукты" />
          <NavItem to={links.categories.root} title="Категории" />
          <NavItem to={links.import.receipts} title="Чеки" />
          <NavItem to={links.import.file} title="Импорт CSV" />
          {
            isDev && (
              <NavItem to={links.guidelines} title="Стайлгайд" />
            )
          }
          {
            isHamburgerEnabled && (
              <NavItem onClick={signOut} title="Выйти" />
            )
          }
        </Nav>
        {
          !isHamburgerEnabled && (
            <IconButton onClick={signOut} icon="exit" title="Выйти" />
          )
        }
      </Container>
    </header>
  )
}

export default Header
