import { memo, useCallback, useState } from 'react'
import cx from 'classnames'
import { getOriginRoot, getLink, useField } from 'helpers'

import VisibilitySensor from 'react-visibility-sensor'
import FormattedDate from 'components/FormattedDate'
import FormattedCurrency from 'components/FormattedCurrency'
import ListItem from 'components/ListItem'
import Flex from 'components/Flex'
import FlexItem from 'components/FlexItem'
import Button from 'components/Button'
import BaseFormField from 'components/BaseFormField'
import Box from 'components/Box'

import { OperationData } from 'pages/import/types'
import Editor from 'pages/import/components/Editor'

import FormGroup from 'components/FormGroup'
import FormLabel from 'components/FormLabel'
import { useSearchQuery } from './queries'

import s from './Item.scss'

type ItemProps = {
  data: OperationData
  accountId: string
  containerElement: React.RefObject<HTMLDivElement>
}

const types = {
  'transfer': 'Перевод',
  'deposition': 'Пополнение',
  'payment': 'Платеж',
}

const typesOptions = ['deposition', 'transfer', 'payment']
  .map((value) => ({ value, label: types[value] }))

const Item: React.FunctionComponent<ItemProps> = (props) => {
  const { data, accountId, containerElement } = props

  const [type, handleTypeChange, setType] = useField(data.type)
  const [isEditorOpened, setEditorOpened] = useState(0)

  const { search, found, receipt, isFetching, isFetched } = useSearchQuery({ data, accountId })

  const handleLoad = useCallback(() => {
    search()
  }, [search])

  const handleCreateClick = useCallback(() => {
    setEditorOpened(1)
  }, [])

  const handleEditClick = useCallback((event) => {
    setType(found.type)
    setEditorOpened(2)

    if (event) {
      event.preventDefault()
    }
  }, [found, setType])

  const handleEditorClose = useCallback(() => {
    setEditorOpened(0)
  }, [])

  const handleSave = useCallback(() => {
    handleEditorClose()
    search()
  }, [handleEditorClose, search])

  const handleVisibilityChange = useCallback((isVisible: boolean) => {
    if (isVisible) {
      search()
    }
  }, [search])

  const rootClassName = cx(s.item, {
    [s.found]: !!found,
  })

  return (
    <VisibilitySensor
      active={!isFetched && !isFetching}
      intervalCheck={false}
      scrollCheck={true}
      containment={containerElement.current}
      onChange={handleVisibilityChange}
    >
      <ListItem className={rootClassName}>
        <Flex>
          <FlexItem basis="40%" grow={0} shrink={0}>
            <BaseFormField
              label="Тип"
              value={type}
              onChange={handleTypeChange}
              control="select"
              options={typesOptions}
              clearable={false}
              searchable={false}
            />
          </FlexItem>
          <FlexItem basis="60%" ml={16} grow={0} shrink={0}>
            <FormGroup>
              <FormLabel>Действия</FormLabel>
              {
                !found ? (
                  <Button
                    loading={isFetching}
                    disabled={!!found}
                    onClick={handleLoad}
                  >
                    Искать
                  </Button>
                ) : (
                  <Button
                    to={getLink(getOriginRoot(found.type).edit, { id: found.origin.id })}
                    target="_blank"
                    onClick={handleEditClick}
                  >
                    Редактировать {found.title}
                  </Button>
                )
              }
              {
                isFetched && (
                  <Box ml={16} inline>
                    <Button onClick={handleCreateClick} color="primary">
                      Создать
                      {
                        Boolean(receipt) && ' из чека'
                      }
                    </Button>
                  </Box>
                )
              }
            </FormGroup>
          </FlexItem>
        </Flex>
        <FormattedDate value={data.datetime} /><br />
        {data.title}<br />
        {data.currencyCode}
        <FormattedCurrency value={data.amount} currency={data.currencyCode} /><br />
        {
          isEditorOpened > 0 && (
            <Editor
              id={isEditorOpened == 2 && found && found.origin.id}
              data={data}
              receipt={receipt}
              type={type}
              accountId={accountId}
              onClose={handleEditorClose}
              onSave={handleSave}
            />
          )
        }
      </ListItem>
    </VisibilitySensor>
  )
}

export default memo(Item)
