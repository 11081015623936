import { useCallback, useMemo } from 'react'

import { useQuery, gql } from 'apollo'
import { useQueryParams, useFetchMore, updateAndStringifyQueryParams } from 'helpers'

import Container from 'components/Container'
import ContentLoader from 'components/ContentLoader'
import PageError from 'components/PageError'
import Pagination from 'components/Pagination'
import PageContent from 'components/PageContent'

import SimpleFilter from 'components/SimpleFilter'
import ReceiptsList from './ReceiptsList'

export const ROOT_QUERY = gql`
  query ReceiptsRootQuery($input: NalogRuReceiptsInput!) {
    nalogRuReceipts(input: $input) @connection(key: "ReceiptsRootFeed") {
      items {
        id
        datetime
        title
        accountId
        amount
        items {
          title
          price
          count
          amount
          productId
          categoriesIds
        }
      }
      pageInfo {
        page
        pageCount
      }
    }
  }
`

const ReceiptsRoot = ({ location, history }) => {
  const params = useQueryParams(({ page, perPage, query }) => ({
    page: Number.parseInt(page as string) || 1,
    perPage: Number.parseInt(perPage as string) || 20,
    query: query as string,
  }), location)

  const variables = useMemo(() => ({ input: params }), [params])

  const { data, loading, error, fetchMore } = useQuery(ROOT_QUERY, {
    variables,
  })

  useFetchMore(fetchMore, variables)

  const handlePageChange = useCallback((page) => {
    history.push({ search: updateAndStringifyQueryParams(params, { page }) })
  }, [params, history])

  const handleFilterChange = useCallback((filters) => {
    history.push({ search: updateAndStringifyQueryParams(params, filters) })
  }, [history, params])

  if (error) {
    return <PageError error={error} />
  }

  if (loading || !data) {
    return <ContentLoader />
  }

  const { nalogRuReceipts: { pageInfo, items } } = data

  return (
    <PageContent>
      <Container verticalPadding>
        <SimpleFilter query={params.query} onChange={handleFilterChange} />
        {
          loading ? (
            <ContentLoader />
          ) : (
            <ReceiptsList items={items} />
          )
        }
        <Pagination {...pageInfo} fixed onChange={handlePageChange} />
      </Container>
    </PageContent>
  )
}

export default ReceiptsRoot
