import { useCallback, useMemo, memo } from 'react'
import cx from 'classnames'
import { useMedia } from 'helpers'

import PaginationItem, { PaginationItemProps } from './PaginationItem'

import s from './Pagination.scss'

type PaginationProps = {
  page?: number
  pageCount?: number
  maxPages?: number
  onChange?: (page: number) => void
  getItemLink?: (page: number) => string
  fixed?: boolean
}

const Pagination: React.FunctionComponent<PaginationProps> = (props) => {
  const { page: activePage, pageCount, maxPages, fixed, onChange } = props
  const { isMobile } = useMedia()

  const handleChange = useCallback((page: number) => {
    if (typeof onChange === 'function') {
      onChange(page)
    }
  }, [onChange])

  const renderItem = useCallback((item: PaginationItemProps) => (
    <PaginationItem
      key={`${item.page}.${item.label}`}
      onClick={handleChange}
      {...item}
    />
  ), [handleChange])

  const startPage = Math.max(2, activePage - Math.floor(maxPages / 2))
  const endPage = Math.min(pageCount - 1, startPage + maxPages - 1)

  const items = useMemo(() => {
    const items = []

    for (let i = startPage; i <= endPage; i += 1) {
      items.push({ activePage, page: i, label: i })
    }

    return items
  }, [activePage, endPage, startPage])

  if (!activePage || !pageCount || pageCount <= 1) {
    return null
  }

  const rootClassName = cx(s.root, {
    [s.fixed]: isMobile && fixed,
  })

  return (
    <div className={rootClassName}>
      <nav className={s.container}>
        {
          renderItem({
            activePage,
            page: Math.max(1, activePage - 1),
            label: '<',
            disabled: activePage === 1,
          })
        }
        {
          renderItem({ activePage, page: 1, label: 1 })
        }
        {
          (startPage > 2) && (
            renderItem({
              activePage,
              page: Math.max(1, activePage - 5),
              label: '•••',
              delimiter: true,
            })
          )
        }
        {
          items.map(renderItem)
        }
        {
          (endPage < pageCount - 1) && (
            renderItem({
              activePage,
              page: Math.min(pageCount, activePage + 5),
              label: '•••',
              delimiter: true,
            })
          )
        }
        {
          renderItem({ activePage, page: pageCount, label: pageCount })
        }
        {
          renderItem({
            activePage,
            page: Math.min(pageCount, activePage + 1),
            label: '>',
            disabled: activePage === pageCount,
          })
        }
      </nav>
    </div>
  )
}

Pagination.defaultProps = {
  page: 0,
  pageCount: 0,
  maxPages: 3,
}

export default memo(Pagination)
