import cx from 'classnames'

import s from './Panel.scss'

export type PanelProps = {
  component?: React.ElementType
  className?: string
  children?: React.ReactNode
  [key: string]: any
}

const Panel: React.FunctionComponent<PanelProps> = (props: PanelProps) => {
  const { component: Component, children, className, ...rest } = props

  return (
    <Component className={cx(className, s.root)} {...rest}>
      {children}
    </Component>
  )
}

Panel.defaultProps = {
  component: 'div'
}

export default Panel
