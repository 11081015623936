import { useEffect, useState, useCallback } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'

import List from 'components/List'
import AccountListItem, { AccountProps, AccountsListItemFragment } from '../AccountsListItem'

const SortableListInner: React.FunctionComponent<{ items: AccountProps[] }> = ({ items }) => (
  <List>
    {items && items.map((item, index) => (
      <SortableItem key={item.id} index={index} item={item} />
    ))}
  </List>
)

const SortableItem = SortableElement(AccountListItem)
const SortableList = SortableContainer(SortableListInner)

type AccountsSortableListProps = {
  items: AccountProps[]
  saveOrder: (order: string[]) => void
}

const AccountsList: React.FunctionComponent<AccountsSortableListProps> = ({ items, saveOrder }) => {
  const [ temporaryItems, setTemporaryItems ] = useState(null)

  useEffect(() => {
    setTemporaryItems(null)
  }, [ items ])

  const activeItems = temporaryItems || items

  const handleSortEnd = useCallback((info: { oldIndex: number, newIndex: number }) => {
    const orderedItems = arrayMove<AccountProps>(activeItems, info.oldIndex, info.newIndex)
    const orderedIds = orderedItems.map((item) => item.id)

    setTemporaryItems(orderedItems)
    saveOrder(orderedIds)
  }, [saveOrder, activeItems])

  return (
    <SortableList
      useDragHandle
      onSortEnd={handleSortEnd}
      items={activeItems}
    />
  )
}

export { AccountsListItemFragment }
export default AccountsList
