
import cx from 'classnames'

import s from './List.scss'

type ListProps = {
  className?: string
  children?: React.ReactNode
}

const List: React.FunctionComponent<ListProps> = ({ className, children, ...rest }) => (
  <div role="list" className={cx(className, s.root)} {...rest}>
    {children}
  </div>
)

export default List
