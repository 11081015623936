import { useMemo } from 'react'
import cx from 'classnames'

import Box from 'components/Box'
import Text from 'components/Text'
import IconButton from 'components/IconButton'

import s from './Label.scss'

type LabelProps = {
  children?: React.ReactNode
  className?: string
  preset?: 'magenta'
  color?: string
  role?: string
  tabIndex?: number
  onRemoveClick?: React.MouseEventHandler<HTMLButtonElement>
  onRemoveMouseDown?: React.MouseEventHandler<HTMLButtonElement>
}

const Label: React.FunctionComponent<LabelProps> = (props) => {
  const { children, className, preset, color, role, tabIndex, onRemoveClick, onRemoveMouseDown } = props

  const style = useMemo(() => color ? { backgroundColor: color } : null, [color])
  // TODO: get text color by bg color
  const whiteText = false

  const rootClassName = cx(className, s.root, {
    [s[`preset-${preset}`]]: preset,
    [s.wihteText]: whiteText,
  })

  return (
    <Box plr={8} className={rootClassName} style={style} role={role}>
      <Text size="sm" className={s.label}>{children}</Text>
      {
        typeof onRemoveClick === 'function' && (
          <IconButton
            className={s.removeButton}
            size={null}
            color="gray"
            icon="close"
            width={10}
            height={10}
            tabIndex={tabIndex}
            onClick={onRemoveClick}
            onMouseDown={onRemoveMouseDown}
          />
        )
      }
    </Box>
  )
}

export default Label
