import { useState, useCallback, useMemo, useRef } from 'react'
import { DropdownContextValue } from './DropdownContext'

const useDropdownProvider = (): DropdownContextValue => {
  const [isOpened, setOpened] = useState(false)
  const openerRef = useRef(null)
  const containerRef = useRef(null)

  const open = useCallback((event?: any) => {
    if (event && event.currentTarget) {
      openerRef.current = event.currentTarget
    }

    setOpened(true)
  }, [])

  const close = useCallback(() => {
    if (openerRef.current) {
      openerRef.current.focus()
      openerRef.current = null
    }

    setOpened(false)
  }, [])

  const toggle = useCallback(() => {
    if (isOpened) {
      close()
    }
    else {
      open()
    }
  }, [isOpened, close, open])

  return useMemo(() => ({
    isOpened,
    open,
    close,
    toggle,
    openerRef,
    containerRef,
  }), [isOpened, open, close, toggle])
}

export default useDropdownProvider
