
export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  'possibleTypes': {
    'Node': [
      'Account',
      'Category',
      'Currency',
      'Deposition',
      'Market',
      'NalogRuReceipt',
      'Operation',
      'Payment',
      'Product',
      'Token',
      'Transfer',
      'User'
    ],
    'OperationOrigin': [
      'Deposition',
      'Payment',
      'Transfer'
    ]
  }
}

export default result
    