import { memo, useRef, useCallback } from 'react'
import { useField } from 'helpers'

import Container from 'components/Container'
import PageContent from 'components/PageContent'
import BaseFormField from 'components/BaseFormField'
import AccountSelect from 'components/data/AccountSelect'
import FiltersForm from 'components/FiltersForm'

import FormGroup from 'components/FormGroup'
import FormLabel from 'components/FormLabel'
import Button from 'components/Button'
import FlexItem from 'components/FlexItem'
import Flex from 'components/Flex'
import Box from 'components/Box'

import Item from './Item'
import ExistingItem from './ExistingItem'

import useFileField from './util/useFileField'
import useParser from './util/useParser'

const accountLocalStorageKey = 'IMPORT_CSV_ACCOUNT_ID'

const ImportTinkoffCSV: React.FunctionComponent = () => {
  const onAccountChange = useCallback((accountId: string) => {
    localStorage.setItem(accountLocalStorageKey, accountId)
  }, [])

  const [accountId, handleAccountChange] = useField(localStorage.getItem(accountLocalStorageKey), onAccountChange)

  const { operations, isParsing, parse, loadTimeline } = useParser({ accountId })
  const { onChange: handleFileChange } = useFileField(parse)

  const containerRef = useRef(null)

  return (
    <PageContent loading={isParsing} ref={containerRef}>
      <Container verticalPadding>
        <FiltersForm>
          <BaseFormField
            type="file"
            label="CSV/JSON файл"
            onChange={handleFileChange}
          />
          <BaseFormField
            type="select"
            control={AccountSelect}
            value={accountId}
            onChange={handleAccountChange}
            label="Счет для заполнения"
          />
          <FlexItem none cloneChildren>
            <FormGroup>
              <FormLabel>Обновить</FormLabel>
              <Button onClick={loadTimeline}>Обновить таймлайн</Button>
            </FormGroup>
          </FlexItem>
        </FiltersForm>
        {
          operations && operations.map((items, index) => (
            <Box key={index} mb={16}>
              <Flex>
                <FlexItem basis="50%">
                  {
                    items[0].map((item, index) => (
                      <Item
                        key={index}
                        data={item}
                        accountId={accountId}
                        containerElement={containerRef}
                      />
                    ))
                  }
                </FlexItem>
                {
                  items[1].length > 0 && (
                    <FlexItem basis="50%">
                      {
                        items[1].map((item, index) => (
                          <ExistingItem
                            key={index}
                            data={item}
                          />
                        ))
                      }
                    </FlexItem>
                  )
                }
              </Flex>
            </Box>
          ))
        }
      </Container>
    </PageContent>
  )
}

export default memo(ImportTinkoffCSV)
