const getLinkWithSearch = (base: string, search?: string): string => {
  if (!search && typeof window !== 'undefined') {
    search = window.location.search
  }

  if (search) {
    return `${base}${search}`
  }

  return base
} 

export default getLinkWithSearch
