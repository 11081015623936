import { Fragment } from 'react'
import { Redirect, RouteProps } from 'react-router-dom'

import { useUser } from 'providers/UserProvider'

import Header from 'layout/Header'

import s from './Layout.scss'

type LayoutProps = RouteProps

const Layout: React.FunctionComponent<LayoutProps> = (props) => {
  const { user } = useUser()
  const { children, location } = props

  if (!user) {
    return <Redirect to={{ pathname: '/signin', state: { from: location } }} />
  }

  return (
    <Fragment>
      <main className={s.root}>
        <Header />
        <div className={s.content}>
          {children}
        </div>
      </main>
    </Fragment>
  )
}

export default Layout
