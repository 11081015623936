import { useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import { gql, useMutation, useQuery } from 'apollo'
import { links, getLinkWithSearch, pathOr } from 'helpers'

import ModalLoader from 'components/ModalLoader/ModalLoader'
import ModalError from 'components/ModalError'
import ProductForm, { ProductFormSubmit } from './ProductForm'

const NODE_FRAGMENT = gql`
  fragment ProductEditor on Product {
      id
      name
      notes
      categoriesIds
  }
`

const NODE_QUERY = gql`
  query ProductEditor_NodeQuery($id: ID!) {
    node(id: $id) {
      id
      ...on Product {
        ...ProductEditor
      }
    }
  }
  ${NODE_FRAGMENT}
`

const CREATE_MUTATION = gql`
  mutation ProductEditor_CreateMutation($input: CreateProductInput!) {
    createProduct(input: $input) {
      node {
        ...ProductEditor
      }
    }
  }
  ${NODE_FRAGMENT}
`

const UPDATE_MUTATION = gql`
  mutation ProductEditor_UpdateMutation($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      node {
        ...ProductEditor
      }
    }
  }
  ${NODE_FRAGMENT}
`

const DELETE_MUTATION = gql`
  mutation ProductEditor_DeleteMutation($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      deletedId
    }
  }
`

const mutationConfig = {
  refetchQueries: ['ProductsRootQuery'],
}

type ProductEditorProps = RouteComponentProps<any>

const ProductEditor = (props: ProductEditorProps) => {
  const { match: { params: { id: requestedId = '' } }, history } = props

  const { data, loading, error } = useQuery(NODE_QUERY, {
    variables: {
      id: requestedId,
    },
    skip: !requestedId,
  })

  const node = pathOr(null, ['node'], data) as any

  const [ createMutation ] = useMutation(CREATE_MUTATION, mutationConfig)
  const [ updateMutation ] = useMutation(UPDATE_MUTATION, mutationConfig)
  const [ deleteMutation ] = useMutation(DELETE_MUTATION, mutationConfig)

  const handleSubmit = useCallback<ProductFormSubmit>((values, actions) => {
    const mutation = node ? updateMutation : createMutation

    mutation({ variables: { input: values } })
      .then(
        () => {
          history.push(getLinkWithSearch(links.products.root))
        },
        (mutationError) => {
          const { validationErrors } = mutationError

          if (validationErrors) {
            actions.setErrors(validationErrors)
          }
        }
      )
      .then(() => {
        actions.setSubmitting(false)
      })
  }, [ createMutation, updateMutation, node, history ])

  const handleDelete = useCallback(() => {
    const { id } = node

    if (confirm('Вы уверены?')) {
      deleteMutation({ variables: { input: { id } } })
        .then(
          () => {
            history.push(getLinkWithSearch(links.products.root))
          },
          (mutationError) => {
            alert(mutationError)
          }
        )
    }
  }, [node, deleteMutation, history])

  const handleClose = useCallback(() => {
    history.push(getLinkWithSearch(links.products.root))
  }, [history])

  if (loading) {
    return <ModalLoader size="md" onClose={handleClose} />
  }

  if (error) {
    return <ModalError size="md" onClose={handleClose} error={error} />
  }

  return (
    <ProductForm
      node={node}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    />
  )
}

export default ProductEditor
