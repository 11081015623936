import { useCallback, useMemo } from 'react'
import { Field, Form, Formik, FormikHelpers } from 'components/formik'
import * as Yup from 'yup'
import uniqid from 'uniqid'

import { getCurrentTimestamp, getQueryParams } from 'helpers'

import { Modal } from 'components/Modal'
import ModalLoader from 'components/ModalLoader'
import ModalError from 'components/ModalError'
import AccountSelect from 'components/data/AccountSelect'
import MarketSelect from 'components/data/MarketSelect'
import Flex from 'components/Flex'
import FlexItem from 'components/FlexItem'
import usePaymentEditor from './usePaymentEditor'

import Items from './Items'

export type PaymentFormValues = {
  accountId?: string
  datetime?: number
  title?: string
  notes?: string
  marketId?: string
  discount?: number
  discountPercent?: number
  amount?: number
  isDraft?: boolean
  items?: Array<{
    id?: number
    clientId?: string
    productId: string
    title: string
    count: number
    price: number
    amount: number
    categoriesIds: Array<string>
  }>
}

//type PaymentFormSubmit = (values: PaymentFormValues, actions: FormikHelpers<PaymentFormValues>) => void

type PaymentFormProps = {
  id?: string
  initialData?: PaymentFormValues
  onSubmit: () => void
  onClose: () => void
  onDelete: () => void
}

const PaymentFormSchema = Yup.object().shape({
  accountId: Yup.string().required(),
  datetime: Yup.number().required(),
  title: Yup.string().min(2).required(),
  notes: Yup.string().nullable(),
  marketId: Yup.string().nullable(),
  discount: Yup.number().nullable(),
  discountPercent: Yup.number().nullable(),
  amount: Yup.number().required(),
  isDraft: Yup.boolean(),
  items: Yup.array(Yup.object().shape({
    productId: Yup.string(),
    count: Yup.number(),
    price: Yup.number(),
    amount: Yup.number(),
    categoriesIds: Yup.array(Yup.string()),
  })),
})

const PaymentForm: React.FunctionComponent<PaymentFormProps> = (props) => {
  const { id, initialData, onSubmit, onClose, onDelete } = props

  const {
    node,
    loading,
    error,
    saveNode,
    deleteNode,
  } = usePaymentEditor(id)

  const initialValues = useMemo<PaymentFormValues>(() => {
    const { accountIds = [] } = getQueryParams()
    const items = (initialData?.items || [])
      .map((item) => ({
        ...item,
        clientId: uniqid()
      }))

    return {
      accountId: accountIds && accountIds.length === 1 ? accountIds[0] : '',
      datetime: getCurrentTimestamp(),
      title: '',
      notes: '',
      marketId: null,
      amount: 0,
      discount: 0,
      discountPercent: 0,
      ...initialData,
      items,
    }
  }, [initialData])

  const confirmClose = useCallback(() => {
    if (confirm('Все несохраненные изменения будут удалены. Вы точно хотите уйти со страницы?')) {
      onClose()
    }
  }, [onClose])

  const handleSubmit = useCallback((values: PaymentFormValues, actions: FormikHelpers<PaymentFormValues>) => {
    saveNode(values)
      .finally(() => {
        actions.setSubmitting(false)
      })
      .then(
        () => {
          if (typeof onSubmit === 'function') {
            onSubmit()
          }
        },
        (mutationError) => {
          const { validationErrors, message } = mutationError

          if (validationErrors) {
            actions.setErrors(validationErrors)
          }
          else {
            actions.setFieldError('title', message)
          }
        }
      )
  }, [onSubmit, saveNode])

  const handleDelete = useCallback(() => {
    if (!id) {
      return
    }

    if (confirm('Вы уверены?')) {
      deleteNode(id)
        .then(
          () => {
            if (typeof onDelete === 'function') {
              onDelete()
            }
          },
          (mutationError) => {
            alert(mutationError)
          }
        )
    }
  }, [id, deleteNode, onDelete])

  if (loading) {
    return <ModalLoader size="md" onClose={onClose} />
  }

  if (error) {
    return <ModalError size="md" onClose={onClose} error={error} />
  }

  return (
    <Formik
      validationSchema={PaymentFormSchema}
      enableReinitialize
      initialValues={node || initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, dirty, touched }) => {
        const handleClose = dirty && Object.keys(touched).length > 0 ? confirmClose : onClose

        return (
          <Modal
            id="payment-modal"
            size="full"
            title={`${!node ? 'Создание' : 'Редактирование'} платежа`}
            onClose={handleClose}
            wrapper={Form}
            footerButtons={[
              { children: 'Удалить', onClick: handleDelete, color: 'danger', disabled: !id },
              { children: 'Назад', onClick: handleClose },
              { children: 'Сохранить', color: 'primary', loading: isSubmitting, type: 'submit' },
            ]}
          >
            <Field label="Счет" name="accountId" control={AccountSelect} />
            <Field label="Дата и время" name="datetime" control="datetime" />
            <Field label="Название" name="title" />
            <Field label="Магазин" name="marketId" control={MarketSelect} />
            <Field label="Описание" name="notes" multiline={true} />
            <Flex>
              <FlexItem basis="50%" cloneChildren>
                <Field label="Скидка в у.е." type="number" name="discount" />
              </FlexItem>
              <FlexItem basis="50%" cloneChildren ml={16}>
                <Field label="Скидка в процентах" type="number" name="discountPercent" />
              </FlexItem>
            </Flex>
            <Field label="Сумма" type="number" name="amount" />
            <Items />
          </Modal>
        )
      }}
    </Formik>
  )
}

export default PaymentForm
