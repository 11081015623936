
import cx from 'classnames'

import s from './Toggle.scss'

export type ToggleProps = React.InputHTMLAttributes<HTMLInputElement> & {
  hasFocus?: boolean
  hasError?: boolean
}

const Toggle: React.FunctionComponent<ToggleProps> = (props) => {
  const { className, id, hasFocus, hasError, ...rest } = props

  const rootClassName = cx(className, s.root, {
    '-error': hasError,
    '-focus': hasFocus,
  })

  const inputProps = {
    className: s.input,
    type: 'checkbox',
    id,
    ...rest,
  }

  return (
    <label htmlFor={id} className={rootClassName}>
      <input {...inputProps} />
      <span className={s.track}>
        <span className={s.handle} />
      </span>
    </label>
  )
}

export default Toggle
