
import { Field, Form, Formik, FormikHelpers } from 'components/formik'
import * as Yup from 'yup'
import { Modal } from 'components/Modal'

import CategoriesSelect from 'components/data/CategoriesSelect'

export type CategoryFormValues = {
  name: string
  notes: string
  relativeIds: Array<string>
}

export type CategoryFormSubmit = (values: CategoryFormValues, actions: FormikHelpers<CategoryFormValues>) => void

type CategoryFormProps = {
  node?: CategoryFormValues
  onSubmit: CategoryFormSubmit
  onClose: () => void
  onDelete: () => void
}

const newInitialValues: CategoryFormValues = {
  name: '',
  notes: '',
  relativeIds: [],
}

const CategoryFormSchema = Yup.object().shape({
  name: Yup.string().min(2).required(),
  notes: Yup.string().nullable(),
  relativeIds: Yup.array(Yup.string()),
})

const CategoryForm: React.FunctionComponent<CategoryFormProps> = (props) => {
  const { node, onSubmit, onClose, onDelete } = props

  return (
    <Formik
      validationSchema={CategoryFormSchema}
      enableReinitialize
      initialValues={node || newInitialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Modal
          size="md"
          title={`${!node ? 'Создание' : 'Редактирование'} категории`}
          onClose={onClose}
          wrapper={Form}
          footerButtons={[
            { children: 'Удалить', onClick: onDelete, color: 'danger' },
            { children: 'Назад', onClick: onClose },
            { children: 'Сохранить', color: 'primary', loading: isSubmitting, type: 'submit' },
          ]}
        >
          <Field label="Название" type="text" name="name" />
          <Field label="Описание" type="text" name="notes" multiline={true} />
          <Field label="Связанные категории" control={CategoriesSelect} name="relativeIds" creatable={false} />
        </Modal>
      )}
    </Formik>
  )
}

export default CategoryForm
