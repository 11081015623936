import React, { useCallback } from 'react'
import cx from 'classnames'

import s from './FiltersForm.scss'

type FiltersFormProps = {
  children?: React.ReactNode
  className?: string
  onSubmit?: React.FormEventHandler<HTMLFormElement>
}

const FiltersForm: React.FunctionComponent<FiltersFormProps> = ({ children, className, onSubmit }) => {

  const handleSubmit = useCallback((event) => {
    event.preventDefault()

    if (typeof onSubmit === 'function') {
      onSubmit(event)
    }
  }, [onSubmit])

  return (
    <form onSubmit={handleSubmit} noValidate className={cx(s.root, className)}>
      {
        React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return child
          }

          return React.cloneElement(child, {
            className: cx(s.item, child.props.className),
          })
        })
      }
    </form>
  )
}

export default FiltersForm
