import { useCallback } from 'react'
import cx from 'classnames'

import s from './PaginationItem.scss'

export type PaginationItemProps = {
  page: number
  label: string | number
  activePage?: number
  disabled?: boolean
  delimiter?: boolean
  onClick?: (page: number) => void
}

const PaginationItem: React.FunctionComponent<PaginationItemProps> = (props) => {
  const { page, label, onClick, activePage, disabled, delimiter } = props

  const handleClick = useCallback(() => {
    onClick(page)
  }, [ page, onClick ])

  const isActive = !disabled && activePage === page
  const rootClassName = cx(s.item, {
    [s.active]: isActive,
    [s.disabled]: disabled,
    [s.delimiter]: delimiter,
  })

  return (
    <button
      type="button"
      className={rootClassName}
      onClick={handleClick}
      disabled={disabled}
    >
      {label}
    </button>
  )
}

export default PaginationItem
