import { useMemo, useCallback } from 'react'

import { Option, OptionValue } from './types'
import BaseSelect, { BaseSelectProps } from './BaseSelect'

export type SelectProps = BaseSelectProps & {
  optionBased: boolean
  value: OptionValue | OptionValue[] | Option | Option[]
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  onSelect?: (value: Option | Option[]) => void
}

const Select: React.FunctionComponent<SelectProps> = ({ optionBased, onChange, onSelect, ...rest }) => {
  const { options, value, name, multiple } = rest

  const processedValue: (Option | Option[]) = useMemo(() => {
    if (!value || optionBased) {
      return value
    }

    if (multiple) {
      if (Array.isArray(value)) {
        return (value as OptionValue[])
          .map((v) => options.find((option) => option.value === v))
          .filter((v) => !!v)
      }

      return null
    }

    return options.find((option) => option.value === value)
  }, [multiple, optionBased, options, value])

  const handleChange = useCallback((newValue) => {
    if (typeof onChange === 'function') {
      let value = null

      if (optionBased) {
        value = newValue
      }
      else {
        if (multiple) {
          if (Array.isArray(newValue)) {
            value = newValue.map((option) => option.value)
          }
        } else if (newValue) {
          value = newValue.value
        }
      }

      const event = { target: { name, value } } as React.ChangeEvent<HTMLSelectElement>

      onChange(event)
    }

    if (typeof onSelect === 'function') {
      onSelect(newValue)
    }
  }, [multiple, name, onChange, onSelect, optionBased])

  return (
    <BaseSelect
      {...rest}
      value={processedValue}
      onChange={handleChange}
    />
  )
}

export default Select
