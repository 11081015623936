import { memo } from 'react'
import cx from 'classnames'

import Panel, { PanelProps } from 'components/Panel'
import ButtonBase from 'components/ButtonBase'
import Box from 'components/Box'

import s from './ListItem.scss'

type ListItemProps = {
  className?: string
  children?: React.ReactNode,
  to?: any,
}

const ListItem: React.FunctionComponent<ListItemProps> = (props) => {
  const { className, to, children } = props
  const containerProps: PanelProps = {
    className: cx(s.root, className),
    role: 'listitem',
  }

  if (to) {
    containerProps.to = to
    containerProps.component = ButtonBase
  }

  return (
    <Panel {...containerProps}>
      <Box pt={8} pb={8} pl={16} pr={16}>
        {children}
      </Box>
    </Panel>
  )
}

export default memo(ListItem)
