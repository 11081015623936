import { useEffect, useCallback, useRef } from 'react'
import { Portal } from 'react-portal'
import cx from 'classnames'

import s from './ModalBase.scss'

export type ModalBaseSize = 'sm' | 'md' | 'full'

type ModalBaseProps = {
  id?: string
  className?: string
  children?: React.ReactNode
  size?: ModalBaseSize
  onBackdropClick?: React.MouseEventHandler
}

const ModalBase: React.FunctionComponent<ModalBaseProps> = (props) => {
  const { children, id, className, size, onBackdropClick } = props

  useEffect(() => {
    document.body.classList.add('modal-open')

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const modalClassName = cx(className, s.modal, {
    [s[`size-${size}`]]: !!size,
  })

  const preventClick = useRef(false)
  const documentRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = useCallback((event: React.MouseEvent) => {
    const { target } = event

    preventClick.current = (target === documentRef.current || documentRef.current.contains(target as Node))
  }, [])

  const handleBackdropClick = useCallback((event: React.MouseEvent) => {
    const { target } = event

    if (preventClick.current || target === documentRef.current || documentRef.current.contains(target as Node)) {
      return
    }

    if (typeof onBackdropClick === 'function') {
      onBackdropClick(event)
    }
  }, [onBackdropClick])

  return (
    <Portal>
      <div role="dialog" id={id} className={s.root} onMouseDown={handleMouseDown} onClick={handleBackdropClick} >
        <div className={modalClassName} role="document" ref={documentRef}>
          {children}
        </div>
      </div>
    </Portal>
  )
}

export default ModalBase
