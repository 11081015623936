import { memo, useMemo } from 'react'
import { DateTime } from 'luxon'
import { parseTimestamp } from 'helpers/getters/getTimestamp'

const FORMATS = {
  date: 'dd.LL.yy',
  time: 'HH:mm',
  datetime: 'dd.LL.yy - HH:mm',
  timedate: 'HH:mm dd.LL.yy',
  longdate: 'd LLLL yy, cccc',
}

type FormattedDateProps = React.HTMLAttributes<HTMLSpanElement> & {
  value: number | string | DateTime
  format?: (keyof typeof FORMATS) | string
}

const FormattedDate: React.FunctionComponent<FormattedDateProps> = (props) => {
  const { value, format: requiredFormat, ...rest } = props

  const date = useMemo(() => parseTimestamp(value), [value])

  return (
    <span {...rest}>
      {date.toFormat(FORMATS[requiredFormat] || requiredFormat)}
    </span>
  )
}

FormattedDate.defaultProps = {
  format: 'datetime',
}

export default memo(FormattedDate)
