import { useCallback, useState, useMemo } from 'react'
import { useMutation, gql } from 'apollo'
import { parseTimestamp } from 'helpers/getters/getTimestamp'

import ListItem from 'components/ListItem'
import Text from 'components/Text'
import FormattedDate from 'components/FormattedDate'
import FormattedCurrency from 'components/FormattedCurrency'
import Box from 'components/Box'
import Flex from 'components/Flex'
import FlexItem from 'components/FlexItem'
import Button from 'components/Button'

import Editor from 'pages/import/components/Editor'
import { OperationData } from 'pages/import/types'

import s from './ReceiptItem.scss'

export type ReceiptItemProps = {
  data: {
    id: string
    datetime: number
    title: string
    amount: number
    items: Array<{
      title: string
      price: number
      count: number
      amount: number
    }>
  }
}

const UPDATE_MUTATION = gql`
  mutation Receipts_UpdateMutation($input: UpdateNalogRuReceiptInput!) {
    updateNalogRuReceipt(input: $input) {
      node {
        id
        datetime
        title
        accountId
        amount
        items {
          title
          price
          count
          amount
          productId
          categoriesIds
        }
      }
    }
  }
`

const mutationConfig = {
  refetchQueries: ['ReceiptsRootQuery'],
}

const ReceiptItem: React.FunctionComponent<ReceiptItemProps> = ({ data }) => {
  const { id, datetime, title, amount, items } = data
  const editorData = useMemo<OperationData>(() => ({
    datetime: parseTimestamp(datetime),
    type: 'payment',
    title,
    amount,
    items,
  }), [amount, datetime, items, title])

  const [isUpdating, setUpdating] = useState(false)
  const [updateMutation] = useMutation(UPDATE_MUTATION, mutationConfig)

  const handleClick = useCallback(() => {
    setUpdating(true)

    updateMutation({
      variables: {
        input: {
          id,
        }
      }
    })
      .finally(() => {
        setUpdating(false)
      })
  }, [id, updateMutation])

  const [isEditorOpened, setEditorOpened] = useState(false)

  const openEditor = useCallback(() => {
    setEditorOpened(true)
  }, [])
  const closeEditor = useCallback(() => {
    setEditorOpened(false)
  }, [])

  return (
    <ListItem>
      <Flex>
        <FlexItem>
          <Text muted>
            <FormattedDate value={datetime} />
          </Text>
          <Text text={title} />
          <Box mt={16}>
            <Text size="md">
              <FormattedCurrency value={amount} />
            </Text>
          </Box>
        </FlexItem>
        <FlexItem>
          <Button loading={isUpdating} onClick={handleClick}>Обновить информацию</Button> &nbsp;
          <Button disabled={isUpdating} onClick={openEditor}>Создать платеж</Button>
        </FlexItem>
      </Flex>

      <table className={s.table}>
        <tbody>
          {
            items.map(({ title, price, amount, count }, index) => (
              <tr key={index}>
                <td>{title}</td>
                <td>
                  {
                    count !== 1 && (
                      <span>{price} * {count}</span>
                    )
                  }
                </td>
                <td>
                  {amount}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      {
        isEditorOpened && (
          <Editor
            data={editorData}
            type="payment"
            onClose={closeEditor}
            onSave={closeEditor}
          />
        )
      }
    </ListItem>
  )
}

export default ReceiptItem
