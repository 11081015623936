
import { Field, Form, Formik, FormikHelpers } from 'components/formik'
import * as Yup from 'yup'
import { Modal } from 'components/Modal'

import CurrencySelect from 'components/data/CurrencySelect'

export type AccountFormValues = {
  name: string
  notes: string
  show: boolean
  currencyId: string
}

export type AccountFormSubmit = (values: AccountFormValues, actions: FormikHelpers<AccountFormValues>) => void

interface AccountsFormProps {
  node?: AccountFormValues
  onSubmit: AccountFormSubmit
  onClose: () => void
  onDelete: () => void
}

const newInitialValues: AccountFormValues = {
  name: '',
  notes: '',
  show: true,
  currencyId: '',
}

const AccountFormSchema = Yup.object().shape({
  name: Yup.string().min(2).required(),
  currencyId: Yup.string().required(),
})

const AccountForm: React.FunctionComponent<AccountsFormProps> = (props) => {
  const { node, onSubmit, onClose, onDelete } = props

  return (
    <Formik
      validationSchema={AccountFormSchema}
      enableReinitialize
      initialValues={node || newInitialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Modal
          size="md"
          title={`${!node ? 'Создание' : 'Редактирование'} счета`}
          onClose={onClose}
          wrapper={Form}
          footerButtons={[
            { children: 'Удалить', onClick: onDelete, color: 'danger' },
            { children: 'Назад', onClick: onClose },
            { children: 'Сохранить', color: 'primary', loading: isSubmitting, type: 'submit' },
          ]}
        >
          <Field label="Название" type="text" name="name" />
          <Field
            label="Валюта"
            type="select"
            control={CurrencySelect}
            name="currencyId"
          />
          <Field label="Описание" type="text" name="notes" multiline={true} />
          <Field label="Показывать" type="checkbox" name="show" control="checkbox" />
        </Modal>
      )}
    </Formik>
  )
}

export default AccountForm
