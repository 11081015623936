import { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import cx from 'classnames'
import { DateTime } from 'luxon'

import TextInput from 'components/TextInput'
import { useDropdownProvider, DropdownContextProvider, DropdownMenu } from 'components/Dropdown'
import IconButton from 'components/IconButton'

import TimePicker from './TimePicker'
import DatePicker from './DatePicker'

import s from './DateTimeInput.scss'

type DateTimeInputProps = {
  className?: string
  id?: string,
  name?: string,
  value?: number | string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>

  format?: string
  placeholder?: string
  disabled?: boolean
}

const DateTimeInput: React.FunctionComponent<DateTimeInputProps> = (props) => {
  const { className, id, name, value, onChange, onBlur, format, placeholder, disabled } = props

  // converters
  const valueToDate = useCallback((value: string | number) => {
    let date = null

    if (typeof value === 'number') {
      date = DateTime.fromMillis(value * 1000)
    }
    else if (value) {
      date = DateTime.fromISO(value)
    }

    return date
  }, [])

  const dateToInputFormat = useCallback((date: DateTime) => {
    return date ? date.toFormat(format) : ''
  }, [format])

  const dateToValue = useCallback((date: DateTime) => {
    return date ? Math.ceil(date.valueOf() / 1000) : null
  }, [])

  // current date value
  const currentDateRef = useRef(valueToDate(value))
  const [inputValue, setInputValue] = useState(dateToInputFormat(currentDateRef.current))

  const handleDateChange = useCallback((date: DateTime, updateInput = true) => {
    currentDateRef.current = date

    if (updateInput) {
      setInputValue(dateToInputFormat(date))
    }

    if (typeof onChange === 'function') {
      const event = {
        target: {
          name,
          value: dateToValue(date),
        }
      } as any as React.ChangeEvent<HTMLInputElement>

      onChange(event)
    }
  }, [dateToInputFormat, dateToValue, name, onChange])

  // value from props
  useEffect(() => {
    const date = currentDateRef.current

    if (dateToValue(date) !== value) {
      currentDateRef.current = valueToDate(value)
      setInputValue(dateToInputFormat(currentDateRef.current))
    }
  }, [dateToInputFormat, dateToValue, value, valueToDate])

  //
  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setInputValue(value)

    if (value === '') {
      handleDateChange(null)
    }
    else {
      const date = DateTime.fromFormat(value, format)

      if (date.isValid) {
        handleDateChange(date, false)
      }
    }
  }, [format, handleDateChange])

  //
  const handleInputBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (value) {
      const date = DateTime.fromFormat(value, format)

      handleDateChange(date.isValid ? date : null, false)
    }

    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }, [format, handleDateChange, onBlur])

  // dropdown
  const dropdownState = useDropdownProvider()
  const { toggle, containerRef } = dropdownState

  // mask converter
  const inputMask = useMemo(() => {
    return format.split('').map((char) => {
      if (char.match(/\w/)) {
        return /\d/
      }

      return char
    })
  }, [format])

  return (
    <DropdownContextProvider value={dropdownState}>
      <div className={cx(className, s.root)} ref={containerRef}>
        <TextInput
          placeholder={placeholder}
          mask={inputMask}
          guide
          disabled={disabled}
          before={(
            <IconButton icon="calendar" onClick={toggle} />
          )}
          id={id}
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
        <DropdownMenu className={s.menu}>
          <DatePicker
            value={currentDateRef.current}
            onChange={handleDateChange}
          />
          <TimePicker
            value={currentDateRef.current}
            onChange={handleDateChange}
          />
        </DropdownMenu>
      </div>
    </DropdownContextProvider>
  )
}

DateTimeInput.defaultProps = {
  format: 'dd.MM.yy - HH:mm',
  placeholder: 'ДД.ММ.ГГ - ЧЧ:ММ',
}

export default DateTimeInput
