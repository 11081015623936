/* eslint-disable max-len */

import cx from 'classnames'

import s from './CloseButton.scss'

type CloseButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>

const CloseButton: React.FunctionComponent<CloseButtonProps> = (props) => {
  const { className, ...rest } = props

  return (
    <button
      type="button"
      className={cx(className, s.root)}
      title="Закрыть"
      {...rest}
    >
      <svg viewBox="0 0 72 74" width="100%" height="100%" className={s.icon}>
        <path
          d="M41.92 37.14l29.3-34.92a.89.89 0 0 0-.68-1.46h-8.9c-.53 0-1.03.23-1.38.64L36.1 30.2 11.94 1.4C11.6 1 11.1.76 10.56.76h-8.9a.89.89 0 0 0-.68 1.46l29.3 34.92L.97 72.06a.89.89 0 0 0 .68 1.47h8.9c.53 0 1.03-.24 1.38-.64L36.1 44.1l24.16 28.8c.34.4.84.64 1.37.64h8.91a.89.89 0 0 0 .68-1.47l-29.3-34.92z"
          fillRule="evenodd"
          fill="currentColor"
        />
      </svg>
    </button>
  )
}

export default CloseButton
