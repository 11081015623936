import { Fragment } from 'react'
import { Operation } from 'pages/operations/OperationsList/types'
import FormattedCurrency from 'components/FormattedCurrency'

type OperationAmountProps = {
  data: Operation
}

const OperationAmount: React.FunctionComponent<OperationAmountProps> = ({ data }) => {
  const { amount, direction, account: { currency: { isoCode } } } = data

  return (
    <Fragment>
      {
        direction > 0 && (
          <strong>+&nbsp;</strong>
        )
      }
      <FormattedCurrency value={amount} currency={isoCode} />
    </Fragment>
  )
}

export default OperationAmount
