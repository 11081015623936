const getLink = (base: string, params: unknown = {}): string => {
  let result = base

  Object.keys(params).forEach((param) => {
    result = result.replace(new RegExp(`:${param}`, 'g'), params[param])
  })

  return result
}

export default getLink
