import { useState, useCallback, useMemo } from 'react'

export type UseHasFocusProps = {
  hasFocus?: boolean
  onFocus?: (event: any) => void,
  onBlur?: (event: any) => void,
}

export type UseHasFocusType = (props: UseHasFocusProps) => {
  hasFocus: boolean
  handleFocus: (event: any) => void,
  handleBlur: (event: any) => void,
}

const useHasFocus: UseHasFocusType = ({ hasFocus: externalHasFocus, onFocus, onBlur }) => {
  const [hasFocus, setFocus] = useState(false)

  const handleBlur = useCallback((event) => {
    setFocus(false)

    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }, [ onBlur ])

  const handleFocus = useCallback((event) => {
    setFocus(true)

    if (typeof onFocus === 'function') {
      onFocus(event)
    }
  }, [ onFocus ])

  return useMemo(() => {
    if (externalHasFocus) {
      return {
        hasFocus: externalHasFocus,
        handleFocus: onFocus,
        handleBlur: onBlur,
      }
    }

    return {
      hasFocus,
      handleFocus,
      handleBlur,
    }
  }, [
    externalHasFocus,
    onFocus,
    onBlur,
    hasFocus,
    handleFocus,
    handleBlur,
  ])
}

export default useHasFocus
