/* tslint:disable:no-invalid-template-strings */
import { setLocale } from 'yup'

setLocale({
  mixed: {
    default: 'Ошибка',
    required: 'Поле обязательное',
  },
  string: {
    min: 'Поле содержать от ${min} символов',
    max: 'Поле должно содержать не более ${max} символов',
  },
})
