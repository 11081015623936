
import cx from 'classnames'

import ButtonBase, { ButtonBaseProps } from 'components/ButtonBase'
import Icon, { IconValues } from 'components/Icon'

import s from './IconButton.scss'

type IconButtonProps = ButtonBaseProps & {
  title?: string
  size?: 'sm' | 'md' | 'lg'
  color?: 'default' | 'gray' | 'danger'
  icon?: IconValues
  width?: number
  height?: number
}

const IconButton: React.FunctionComponent<IconButtonProps> = (props) => {
  const { children, className, size, color, icon, width, height, ...rest } = props
  const rootClassName = cx(className, s.root, {
    [s[`size-${size}`]]: !!size,
    [s[color]]: !!color,
  })

  return (
    <ButtonBase className={rootClassName} {...rest}>
      <span className={s.container}>
        {
          icon ? (
            <Icon icon={icon} className={s.icon} width={width} height={height} />
          ) : (
            children
          )
        }
      </span>
    </ButtonBase>
  )
}

IconButton.defaultProps = {
  size: 'md',
  color: 'default',
}

export default IconButton
