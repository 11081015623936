import { forwardRef } from 'react'

import ContentLoader from 'components/ContentLoader'

import s from './PageContent.scss'

export type PageContentProps = {
  children?: React.ReactNode
  loading?: boolean
  overlayLoader?: boolean
}

const PageContent = forwardRef<HTMLDivElement, PageContentProps>((props, ref) => {
  const { children, loading, overlayLoader } = props

  return (
    <div className={s.root} ref={ref} id="page-content">
      {(!loading || overlayLoader) ? children : null}
      {loading ? <ContentLoader /> : null}
    </div>
  )
})

export default PageContent
