import { Fragment, memo, useCallback } from 'react'
import cx from 'classnames'

import { Columns, RowData, ColumnData, GetRowClassName, RowClickHandler } from '../types'
import s from './TableRow.scss'

type TableRowProps = {
  columns: Columns
  columnProps?: any
  data: RowData
  columnClassName?: string
  rowClassName?: string
  getRowClassName?: GetRowClassName
  onRowClick?: RowClickHandler
}

const defaultRender = (data: ColumnData) => {
  let value = '-'

  if (typeof data === 'string') {
    value = data
  }
  else if (typeof data === 'number') {
    value = data.toString()
  }
  else if (data) {
    value = typeof data
  }

  return (
    <Fragment>
      {value}
    </Fragment>
  )
}

const TableRow: React.FunctionComponent<TableRowProps> = (props) => {
  const { columns, columnProps, data, columnClassName, rowClassName, getRowClassName, onRowClick } = props

  let rootClassName = cx(rowClassName, {
    [s.pointer]: !!onRowClick,
  })

  if (typeof getRowClassName === 'function') {
    rootClassName = cx(rootClassName, getRowClassName(data))
  }

  const handleRowClick = useCallback(() => {
    if (typeof onRowClick === 'function') {
      onRowClick(data)
    }
  }, [data, onRowClick])

  return (
    <tr className={rootClassName} onClick={handleRowClick}>
      {
        columns.map(({ key, className, align = 'left', wrap, render = defaultRender }) => (
          <td key={key} className={cx(columnClassName, className, { [s.wrap]: wrap })} align={align}>
            <div className={s.value}>
              {render(data[key], data, columnProps)}
            </div>
          </td>
        ))
      }
    </tr>
  )
}

export default memo(TableRow)
