
import { Field as FormikField } from 'formik'
import FormField from 'components/FormField'
import { BaseFormFieldProps } from 'components/BaseFormField'

export type FieldProps = BaseFormFieldProps

const Field: React.FunctionComponent<FieldProps> = (props) => {
  return (
    <FormikField
      component={FormField}
      {...props}
    />
  )
}

export default Field
