
import { getLink, links, getLinkWithSearch } from 'helpers'
import List from 'components/List'
import ListItem from 'components/ListItem'
import Text from 'components/Text'
import Box from 'components/Box'

type CategoriesListProps = {
  className?: string
  items: [{
    id: string
    name: string
    notes?: string
    relatives?: [{ name: string }]
  }]
}

const CategoriesList = (props: CategoriesListProps) => {
  const { items } = props

  return (
    <List>
      {items && items.map(({ id, name, relatives }) => (
        <ListItem key={id} to={getLinkWithSearch(getLink(links.categories.edit, { id }))}>
          <Text text={name} />
          {
            relatives && relatives.length > 0 && (
              <Box mt={8}>
                <Text size="sm" muted text={relatives.map(({ name }) => name).join(', ')} />
              </Box>
            )
          }
        </ListItem>
      ))}
    </List>
  )
}

export default CategoriesList
