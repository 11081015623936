import { useState, useRef } from 'react'

const useReducerState = <T = unknown>(initialState: T): [T, (value: Partial<T>) => void] => {
  const [ state, _setState ] = useState(initialState)
  const setStateRef = useRef((value: Partial<T>) => _setState((prevState) => ({ ...prevState, ...value })))

  return [ state, setStateRef.current ]
}

export default useReducerState
