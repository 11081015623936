import { useMemo } from 'react'
import cx from 'classnames'

import { Columns, TableData, GetRowClassName, RowClickHandler } from '../types'
import TableRow from '../TableRow'
import TableHeader from '../TableHeader'

import s from './Table.scss'

type TableProps = {
  className?: string
  columns: Columns
  columnProps?: any
  data: TableData
  rowKey: string
  getRowClassName?: GetRowClassName
  onRowClick?: RowClickHandler
}

const Table: React.FunctionComponent<TableProps> = (props) => {
  const { className, columns, data, rowKey, columnProps, getRowClassName, onRowClick } = props

  const tbody = useMemo(() => (
    <tbody>
      {
        data.map((rowData, index) => (
          <TableRow
            key={rowKey ? rowData[rowKey] : index}
            columns={columns}
            columnProps={columnProps}
            data={rowData}
            columnClassName={s.td}
            rowClassName={s.tr}
            getRowClassName={getRowClassName}
            onRowClick={onRowClick}
          />
        ))
      }
    </tbody>
  ), [columnProps, columns, data, getRowClassName, onRowClick, rowKey])

  return (
    <table className={cx(s.table, className)}>
      <TableHeader columns={columns} columnClassName={s.th} />
      {tbody}
    </table>
  )
}

export default Table
