
import cx from 'classnames'

import s from './NumberInput.scss'

export type NumberInputProps = React.InputHTMLAttributes<HTMLInputElement>

const NumberInput: React.FunctionComponent<NumberInputProps> = (props) => {
  const { className, ...rest } = props

  return (
    <input
      className={cx(s.root, className)}
      type="number"
      {...rest}
    />
  )
}

export default NumberInput
