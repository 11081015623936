
import { getLink, links, getLinkWithSearch } from 'helpers'
import List from 'components/List'
import ListItem from 'components/ListItem'
import Text from 'components/Text'
import Box from 'components/Box'

type ProductsListProps = {
  className?: string
  items: {
    id: string,
    name: string,
    categories?: { id: string, name: string }[]
  }[]
}

const ProductsList = (props: ProductsListProps) => {
  const { items } = props

  return (
    <List>
      {items && items.map(({ id, name, categories }) => (
        <ListItem key={id} to={getLinkWithSearch(getLink(links.products.edit, { id }))}>
          <Text text={name} />
          {
            categories && categories.length > 0 && (
              <Box mt={8}>
                <Text size="sm" muted text={categories.map(({ name }) => name).join(', ')} />
              </Box>
            )
          }
        </ListItem>
      ))}
    </List>
  )
}

export default ProductsList
