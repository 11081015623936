import { useCallback } from 'react'
import iconv from 'iconv-lite'

// logic for file field
const useFileField = (cb: (data: string) => void) => {
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (!files || files.length < 1) {
      return
    }

    const file = files[0]
    const reader = new FileReader()

    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      const buffer = reader.result as ArrayBuffer

      try {
        let string = iconv.decode(Buffer.from(buffer), 'win1251')

        if (!string.match(/Дата/)) {
          string = iconv.decode(Buffer.from(buffer), 'utf8')
        }

        cb(string)
      }
      catch (err) {
        alert(err.toString())
      }
    }
    reader.onerror = () => {
      alert(reader.error)
    }
  }, [cb])

  return { onChange }
}

export default useFileField
