
import { Redirect, RouteComponentProps } from 'react-router'
import { getErrorMessage } from 'apollo'
import { useUser } from 'providers/UserProvider'
import Container from 'components/Container'

import AuthForm, { AuthFormSubmit } from './SignInForm'

const SignInRoot: React.FunctionComponent<RouteComponentProps> = (props) => {
  const { user, signIn } = useUser()

  if (user) {
    const { from } = props.location.state || { from: { pathname: '/' } }

    return <Redirect to={from} />
  }

  const onSubmit: AuthFormSubmit = ({ username, password }, actions) => {
    signIn(username, password)
      .then(() => {
        actions.setSubmitting(false)
      })
      .catch((error) => {
        actions.setStatus(getErrorMessage(error))
        actions.setSubmitting(false)
      })
  }

  return (
    <Container>
      <AuthForm onSubmit={onSubmit} />
    </Container>
  )
}

export default SignInRoot
