import { DateTime } from 'luxon'

const getTimestamp = (date?: DateTime | Date): number => {
  return date ? Math.floor(date.valueOf() / 1000) : null
}

const getCurrentTimestamp = (): number => {
  return getTimestamp(DateTime.local())
}

const parseTimestamp = (datetime: number | string | DateTime): DateTime => {
  if (typeof datetime === 'number') {
    return DateTime.fromMillis(datetime * 1000)
  }

  if (typeof datetime === 'string') {
    return DateTime.fromISO(datetime)
  }

  if (datetime instanceof DateTime) {
    return datetime
  }

  return null
}

export {
  getTimestamp,
  getCurrentTimestamp,
  parseTimestamp,
}
