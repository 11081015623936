import { forwardRef } from 'react'
import Textarea from 'react-textarea-autosize'
import cx from 'classnames'
import MaskedInput, { MaskedInputProps } from 'components/MaskedInput'
import NumberInput, { NumberInputProps } from 'components/NumberInput'

import s from './TextInput.scss'

export type TextInputProps =
  React.InputHTMLAttributes<HTMLInputElement>
  & Partial<MaskedInputProps>
  & Partial<NumberInputProps>
  & {
    className?: string
    inputClassName?: string
    multiline?: boolean
    rows?: number
    before?: React.ReactNode
    beforeClassName?: string
    after?: React.ReactNode
    afterClassName?: string
    rounded?: boolean
    hasFocus?: boolean
    hasError?: boolean
  }

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    className,
    inputClassName,
    multiline,
    before,
    beforeClassName,
    after,
    afterClassName,
    rounded,
    hasFocus,
    hasError,
    ...rest
  } = props

  const { type } = rest

  const rootClassName = cx(className, s.root, {
    [s.rounded]: rounded,
    [s.hasFocus]: hasFocus,
    [s.hasError]: hasError,
  })

  let InputComponent: React.ElementType = 'input'
  const inputProps = {
    className: cx(inputClassName, s.input),
    type: 'text',
    ...rest,
    ref,
  }

  if (multiline) {
    InputComponent = Textarea
    delete inputProps.type
  } else if (rest.mask) {
    InputComponent = MaskedInput
  } else if (type === 'number') {
    InputComponent = NumberInput
  }

  return (
    <div className={rootClassName}>
      {before ? <div className={beforeClassName}>{before}</div> : null}
      <InputComponent {...inputProps} />
      {after ? <div className={afterClassName}>{after}</div> : null}
    </div>
  )
})

export default TextInput
