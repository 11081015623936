
import { Field, Form, Formik, FormikHelpers } from 'components/formik'
import * as Yup from 'yup'
import { Modal } from 'components/Modal'

import CategoriesSelect from 'components/data/CategoriesSelect'

export type ProductFormValues = {
  name: string
  notes: string
  categoriesIds: string[]
}

export type ProductFormSubmit = (values: ProductFormValues, actions: FormikHelpers<ProductFormValues>) => void

type ProductFormProps = {
  node?: ProductFormValues
  onSubmit: ProductFormSubmit
  onClose: () => void
  onDelete: () => void
}

const newInitialValues: ProductFormValues = {
  name: '',
  notes: '',
  categoriesIds: [],
}

const ProductFormSchema = Yup.object().shape({
  name: Yup.string().min(2).required(),
  notes: Yup.string().nullable(),
})

const ProductForm: React.FunctionComponent<ProductFormProps> = (props) => {
  const { node, onSubmit, onClose, onDelete } = props

  return (
    <Formik
      validationSchema={ProductFormSchema}
      enableReinitialize
      initialValues={node || newInitialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Modal
          size="md"
          title={`${!node ? 'Создание' : 'Редактирование'} товара`}
          onClose={onClose}
          wrapper={Form}
          footerButtons={[
            { children: 'Удалить', onClick: onDelete, color: 'danger' },
            { children: 'Назад', onClick: onClose },
            { children: 'Сохранить', color: 'primary', loading: isSubmitting, type: 'submit' },
          ]}
        >
          <Field label="Название" type="text" name="name" />
          <Field label="Описание" type="text" name="notes" multiline={true} />
          <Field label="Связанные категории" control={CategoriesSelect} name="categoriesIds" />
        </Modal>
      )}
    </Formik>
  )
}

export default ProductForm
