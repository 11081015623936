import { useMemo } from 'react'
import cx from 'classnames'

import { constants } from 'helpers'
import { GetRowClassName, Columns, Table, useRowLinkClickHandle } from 'components/table'
import FormattedDate from 'components/FormattedDate/FormattedDate'
import Link from 'components/Link'
import Panel from 'components/Panel'
import Text from 'components/Text'

import FormattedCurrency from 'components/FormattedCurrency'
import OperationInfo from 'pages/operations/OperationsList/components/OperationInfo'
import OperationAmount from 'pages/operations/OperationsList/components/OperationAmount'
import OperationAccount from 'pages/operations/OperationsList/components/OperationAccount'

import { Operation, OperationLinkGetter, Operations } from '../types'
import s from './ExceptMobile.scss'

type ExceptMobileProps = {
  operations: Operations
  getOperationLink: OperationLinkGetter
}

const columns: Columns<Operation, { getOperationLink: OperationLinkGetter }> = [
  {
    key: 'datetime',
    title: 'Дата',
    className: s.datetime,
    render: (datetime) => (
      <FormattedDate value={datetime} format="date" />
    ),
  },
  {
    key: 'account',
    title: 'Счет',
    className: s.account,
    render: (account) => (
      <OperationAccount account={account} />
    ),
  },
  {
    key: 'title',
    title: 'Название',
    wrap: true,
    className: s.title,
    render: (title, item, { getOperationLink }) => (
      <Link to={getOperationLink(item)} stopClickPropagation>{title}</Link>
    ),
  },
  {
    key: 'info',
    title: 'Информация',
    wrap: true,
    className: s.info,
    render: (_, data) => {
      return (
        <OperationInfo data={data} />
      )
    },
  },
  {
    key: 'amount',
    title: 'Сумма',
    className: s.amount,
    align: 'right',
    render: (_, data) => (
      <OperationAmount data={data} />
    ),
  },
  {
    key: 'balanceAfter',
    title: 'Баланс',
    className: s.balance,
    align: 'right',
    render: (balanceAfter, { account: { currency: { isoCode } } }) => (
      <Text size="sm" muted>
        <FormattedCurrency value={balanceAfter} currency={isoCode} />
      </Text>
    ),
  },
]

const getRowClassName: GetRowClassName = ({ type }) => {
  return cx({
    [s.transfer]: type === constants.originTypes.transfer,
    [s.deposition]: type === constants.originTypes.deposition,
  })
}

const ExceptMobile: React.FunctionComponent<ExceptMobileProps> = (props) => {
  const { operations, getOperationLink } = props

  const columnProps = useMemo(() => ({ getOperationLink }), [ getOperationLink ])
  const handleRowClick = useRowLinkClickHandle(getOperationLink)

  return (
    <Panel>
      <Table
        rowKey="id"
        columns={columns}
        columnProps={columnProps}
        getRowClassName={getRowClassName}
        onRowClick={handleRowClick}
        data={operations}
      />
    </Panel>
  )
}

export default ExceptMobile
