import { gql, useMutation, useQuery, client } from 'apollo'
import { useCallback } from 'react'
import { parseFloat, toFixed } from 'helpers'

const PaymentFragment = gql`
  fragment PaymentFragment on Payment {
    id
    accountId
    datetime
    title
    notes
    marketId
    discount
    discountPercent
    amount
    isDraft
    items {
      id
      productId
      title
      count
      price
      amount
      categoriesIds
    }
  }
`

const NODE_QUERY = gql`
  query PaymentEditor_NodeQuery($id: ID!) {
    node(id: $id) {
      id
      ...on Payment {
        ...PaymentFragment
      }
    }
  }
  ${PaymentFragment}
`

const CREATE_MUTATION = gql`
  mutation PaymentEditor_CreateMutation($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      node {
        ...PaymentFragment
      }
    }
  }
  ${PaymentFragment}
`

const UPDATE_MUTATION = gql`
  mutation PaymentEditor_UpdateMutation($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      node {
        ...PaymentFragment
      }
    }
  }
  ${PaymentFragment}
`

const DELETE_MUTATION = gql`
  mutation PaymentEditor_DeleteMutation($input: DeletePaymentInput!) {
    deletePayment(input: $input) {
      deletedId
    }
  }
`

const PRODUCT_CATEGORIES_FRAGMENT = gql`
  fragment ProductCategories on Product {
    categoriesIds
  }
`

const PRODUCT_CATEGORIES_MUTATION = gql`
  mutation PaymentEditor_ProductCategoriesMutation($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      node {
        id
        categoriesIds
      }
    }
  }
`

const mutationConfig = {
  refetchQueries: ['OperationsRootQuery', 'AccountsRootFeed'],
}

const usePaymentEditor = (id: string) => {
  const { data, loading, error } = useQuery(NODE_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  })
  const node = data ? data.node : null

  const [createMutation] = useMutation(CREATE_MUTATION, mutationConfig)
  const [updateMutation] = useMutation(UPDATE_MUTATION, mutationConfig)
  const [deleteMutation] = useMutation(DELETE_MUTATION, mutationConfig)

  const saveNode = useCallback(async (values: any) => {
    const mutation = values.id ? updateMutation : createMutation

    const productsCategoriesUpdates = []

    const items = values.items
      .filter((item) => Boolean(item.productId) && Boolean(item.amount))
      .map(({ amount, price, count, ...item }) => {
        delete item.clientId

        productsCategoriesUpdates.push({
          productId: item.productId,
          categoriesIds: item.categoriesIds,
        })

        return {
          ...item,
          price: toFixed(parseFloat(price), 2),
          count: toFixed(parseFloat(count), 4),
          amount: toFixed(parseFloat(amount), 2),
        }
      })

    try {
      await Promise.all(productsCategoriesUpdates.map(async ({ productId, categoriesIds }) => {
        // read product from cache
        const { categoriesIds: cachedCategoriesIds } = client.readFragment({
          id: `Product:${productId}`,
          fragment: PRODUCT_CATEGORIES_FRAGMENT,
        })

        if (cachedCategoriesIds.length === 0 && categoriesIds.length > 0) {
          // update
          await client.mutate({
            mutation: PRODUCT_CATEGORIES_MUTATION,
            variables: {
              input: {
                id: productId,
                categoriesIds,
              },
            }
          })
        }
      }))
    }
    catch (error) {
      console.log(error)
    }

    const input = {
      ...values,
      items,
      resetFields: ['marketId', 'notes'],
    }

    return mutation({ variables: { input } })
  }, [createMutation, updateMutation])

  const deleteNode = useCallback((id: string) => {
    return deleteMutation({ variables: { input: { id } } })
  }, [deleteMutation])

  return {
    node,
    loading,
    error,
    saveNode,
    deleteNode,
  }
}

export default usePaymentEditor
