
import links from 'helpers/links'

import ButtonBase from 'components/ButtonBase'

import s from './Logo.scss'

const Logo: React.FunctionComponent = () => (
  <ButtonBase className={s.logoContainer} to={links.root}>
    <div className={s.logo}>Ф</div>
  </ButtonBase>
)

export default Logo
