import csv from 'csvtojson'
import { DateTime } from 'luxon'
import { OperationData, Parser } from '../../types'

const tinkoffCSVParser: Parser = async (data) => {
  const parsed = await csv({
    delimiter: ';',
    escape: '"',
  }).fromString(data)

  let startDate: DateTime = null
  let endDate: DateTime = null

  const operations = parsed.map<OperationData>((item) => {
    const datetime = DateTime.fromFormat(item['Дата операции'], 'dd.MM.yyyy HH:mm:ss', {
      locale: 'ru',
      zone: 'Europe/Moscow',
    }).setZone('local').set({ second: 0 })

    const fromAmount = Number.parseFloat(item['Сумма операции'].replace(',', '.'))
    const fromCurrency = item['Валюта операции']

    const toAmount = Number.parseFloat(item['Сумма платежа'].replace(',', '.'))
    const toCurrency = item['Валюта платежа']

    const title = `${item['Описание']} (${item['Категория']})`.trim()

    const isTransfer = title.match(/перевод/iu)
    const type = isTransfer ? 'transfer' : (
      fromAmount > 0 ? 'deposition' : 'payment'
    )

    if (!startDate || startDate > datetime) {
      startDate = datetime
    }

    if (!endDate || endDate < datetime) {
      endDate = datetime
    }

    return {
      type,
      datetime,
      title,
      amount: Math.abs(toAmount),
      currency: toCurrency,
      fromAmount: Math.abs(fromAmount),
      fromCurrency,
    }
  })

  return {
    startDate,
    endDate,
    operations: operations.reverse(),
  }
}

export default tinkoffCSVParser
