import { useCallback, useMemo } from 'react'
import { Switch, Route } from 'react-router-dom'

import { useQuery, gql } from 'apollo'
import { useQueryParams, useFetchMore, links, getLinkWithSearch, updateAndStringifyQueryParams } from 'helpers'

import Container from 'components/Container'
import ContentLoader from 'components/ContentLoader'
import PageError from 'components/PageError'
import Pagination from 'components/Pagination'
import ProductsList from './ProductsList'
import PageContent from 'components/PageContent'
import SimpleFilter from 'components/SimpleFilter'

import ProductEditor from '../ProductEditor/ProductEditor'

export const ROOT_QUERY = gql`
  query ProductsRootQuery($input: DefaultQueryInput!) {
    products(input: $input) @connection(key: "ProductsRootFeed") {
      items {
        id
        name
        categories {
          id
          name
        }
      }
      pageInfo {
        page
        pageCount
      }
    }
  }
`

const ProductsRoot = ({ location, history }) => {
  const params = useQueryParams(({ page, perPage, query }) => ({
    page: Number.parseInt(page as string) || 1,
    perPage: Number.parseInt(perPage as string) || 20,
    query: query as string,
  }), location)
  const variables = useMemo(() => ({ input: params }), [params])

  const { data, loading, error, fetchMore } = useQuery(ROOT_QUERY, {
    variables,
  })

  useFetchMore(fetchMore, variables)

  const handlePageChange = useCallback((page) => {
    history.push({ search: updateAndStringifyQueryParams(params, { page }) })
  }, [params, history])

  const handleFilterChange = useCallback((filters) => {
    history.push({ search: updateAndStringifyQueryParams(params, filters) })
  }, [history, params])

  if (error) {
    return <PageError error={error} />
  }

  if (loading) {
    return <ContentLoader />
  }

  const { products: { pageInfo, items } } = data

  return (
    <PageContent>
      <Container verticalPadding>
        <SimpleFilter
          query={params.query}
          addTo={getLinkWithSearch(links.products.create)}
          onChange={handleFilterChange}
        />
        {
          loading ? (
            <ContentLoader />
          ) : (
            <ProductsList items={items} />
          )
        }
        <Pagination {...pageInfo} fixed onChange={handlePageChange} />
      </Container>
      <Switch>
        <Route path={links.products.create} exact component={ProductEditor} />
        <Route path={links.products.edit} exact component={ProductEditor} />
      </Switch>
    </PageContent>

  )
}

export default ProductsRoot
