import { useMemo } from 'react'

import Box, { BoxProps } from 'components/Box'

export type FlexItemProps = BoxProps & {
  basis?: number | 'auto' | string
  grow?: number
  shrink?: number
  none?: boolean
}

const FlexItem: React.FunctionComponent<FlexItemProps> = (props) => {
  const { children, className, basis, grow = 1, shrink = 1, none, style: restStyle, ...rest } = props

  const style = useMemo(() => {
    if (none) {
      return { flexGrow: 0, flexShrink: 0, flexBasis: 'auto', ...restStyle }
    }

    let flexBasis = null

    if (typeof basis === 'number') {
      flexBasis = `${basis}px`
    } else {
      flexBasis = basis
    }

    return { flexGrow: grow, flexShrink: shrink, flexBasis, ...restStyle }
  }, [basis, grow, none, restStyle, shrink])

  return (
    <Box  {...rest} className={className} style={style}>
      {children}
    </Box>
  )
}

export default FlexItem
