import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { RowData, RowClickHandler } from './types'

const useRowLinkClickHandle = (linkGetter: (data: RowData) => string): RowClickHandler => {
  const history = useHistory()

  return useCallback((data: RowData) => {
    const link = linkGetter(data)

    if (!link) {
      console.error('Link is undefined')
    }

    history.push(link)
  }, [ history, linkGetter ])
}

export default useRowLinkClickHandle
