import { useCallback, useMemo } from 'react'
import { Switch, Route } from 'react-router-dom'

import { useQuery, gql } from 'apollo'
import { useQueryParams, useFetchMore, updateAndStringifyQueryParams, links, getLinkWithSearch } from 'helpers'

import Container from 'components/Container'
import ContentLoader from 'components/ContentLoader'
import PageError from 'components/PageError'
import Pagination from 'components/Pagination'
import CategoriesList from './CategoriesList'
import PageContent from 'components/PageContent'

import CategoryEditor from '../CategoryEditor/CategoryEditor'
import SimpleFilter from 'components/SimpleFilter'

export const ROOT_QUERY = gql`
  query CategoriesRootQuery($input: DefaultQueryInput!) {
    categories(input: $input) @connection(key: "CategoriesRootFeed") {
      items {
        id
        name
        notes
        relatives {
          id
          name
        }
      }
      pageInfo {
        page
        pageCount
      }
    }
  }
`

const CategoriesRoot = ({ location, history }) => {
  const params = useQueryParams(({ page, perPage, query }) => ({
    page: Number.parseInt(page as string) || 1,
    perPage: Number.parseInt(perPage as string) || 20,
    query: query as string,
  }), location)
  const variables = useMemo(() => ({ input: params }), [params])

  const { data, loading, error, fetchMore } = useQuery(ROOT_QUERY, {
    variables,
  })

  useFetchMore(fetchMore, variables)

  const handlePageChange = useCallback((page) => {
    history.push({ search: updateAndStringifyQueryParams(params, { page }) })
  }, [params, history])

  const handleFilterChange = useCallback((filters) => {
    history.push({ search: updateAndStringifyQueryParams(params, filters) })
  }, [history, params])

  if (error) {
    return <PageError error={error} />
  }

  if (loading) {
    return <ContentLoader />
  }

  const { categories: { pageInfo, items } } = data

  return (
    <PageContent>
      <Container verticalPadding>
        <SimpleFilter
          query={params.query}
          addTo={getLinkWithSearch(links.categories.create)}
          onChange={handleFilterChange}
        />
        {
          loading ? (
            <ContentLoader />
          ) : (
            <CategoriesList items={items} />
          )
        }
        <Pagination {...pageInfo} fixed onChange={handlePageChange} />
      </Container>
      <Switch>
        <Route path={links.categories.create} component={CategoryEditor} />
        <Route path={links.categories.edit} component={CategoryEditor} />
      </Switch>
    </PageContent>

  )
}

export default CategoriesRoot
