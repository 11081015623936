
import cx from 'classnames'

import s from './Flex.scss'

export type FlexProps = {
  className?: string
  children?: React.ReactNode
  inline?: boolean
  justifyContent?: 'flex-start' | 'flex-end' | 'space-between'
  alignItems?: 'flex-start' | 'center' | 'stretch'
  wrap?: boolean
  tag?: React.ElementType
}

const Flex: React.FunctionComponent<FlexProps> = (props) => {
  const { tag: Tag, children, className, inline, justifyContent, alignItems, wrap } = props

  const rootClassName = cx(className, s.root, {
    [s.block]: !inline,
    [s.inline]: inline,
    [s.wrap]: wrap,
    [s[`justifyContent-${justifyContent}`]]: !!justifyContent,
    [s[`alignItems-${justifyContent}`]]: !!alignItems,
  })

  return (
    <Tag className={rootClassName}>{children}</Tag>
  )
}

Flex.defaultProps = {
  tag: 'div',
  inline: false,
  justifyContent: 'flex-start',
  alignItems: 'center',
}

export default Flex
