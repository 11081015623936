import { useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import { links, getLinkWithSearch, pathOr } from 'helpers'

import TransferForm from './TransferForm'

type TransferEditorProps = RouteComponentProps<{ id: string }>

const TransferEditor = (props: TransferEditorProps) => {
  const { match, history } = props
  const id = pathOr(null, ['params', 'id'], match)

  const handleSubmit = useCallback(() => {
    history.push(getLinkWithSearch(links.operations.root))
  }, [ history ])

  const handleClose = useCallback(() => {
    history.push(getLinkWithSearch(links.operations.root))
  }, [history])

  return (
    <TransferForm
      id={id}
      onSubmit={handleSubmit}
      onClose={handleClose}
      onDelete={handleClose}
    />
  )
}

export default TransferEditor
