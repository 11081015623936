import { useCallback, useMemo } from 'react'
import { Route, Switch } from 'react-router'
import { gql, useMutation, useQuery } from 'apollo'
import links from 'helpers/links'

import PageContent from 'components/PageContent'
import PageError from 'components/PageError'
import SimpleFilter from 'components/SimpleFilter'
import ContentLoader from 'components/ContentLoader'

import AccountsSortableList, { AccountsListItemFragment } from './AccountsList'
import Container from 'components/Container'
import AccountEditor from 'pages/accounts/AccountEditor'
import { useQueryParams, updateAndStringifyQueryParams } from 'helpers'
import FlexItem from 'components/FlexItem'
import Button from 'components/Button'
import FormGroup from 'components/FormGroup'
import FormLabel from 'components/FormLabel'

export const QUERY = gql`
  query AccountsListQuery {
    accounts @connection(key: "AccountsRootFeed") {
      id
      ...AccountsListItem
    }
  }
  ${AccountsListItemFragment}
`

const ORDER_MUTATION = gql`
  mutation AccountsList_OrderMutation($input: OrderAccountsInput!) {
    orderAccounts(input: $input) {
      query {
        accounts {
          id
          sort
        }
      }
    }
  }
`

const CURRENCY_MUTATION = gql`
  mutation AccountsList_CurrencyMutation {
    updateCurrenciesRates {
      query {
        currencies {
          id
          isoCode
          name
          price
        }
      }
    }
  }
`

const AccountsPageList = ({ location, history }) => {
  const params = useQueryParams(({ query }) => ({
    query: query as string,
  }), location)

  const variables = useMemo(() => ({ input: params }), [params])

  const { data, loading, error } = useQuery(QUERY, {
    // to update each time
    fetchPolicy: 'cache-and-network',
    variables,
  })

  const handleFilterChange = useCallback((filters) => {
    history.push({ search: updateAndStringifyQueryParams(params, filters) })
  }, [history, params])

  const [ orderMutation ] = useMutation(ORDER_MUTATION)

  const saveOrder = useCallback((order: string[]) => {
    orderMutation({
      variables: {
        input: {
          order,
        },
      },
    })
  }, [ orderMutation ])

  const [ currencyMutation ] = useMutation(CURRENCY_MUTATION)

  const updateCurrencies = useCallback(() => {
    currencyMutation()
  }, [ currencyMutation ])

  if (!data && loading) {
    return <ContentLoader />
  }

  if (error) {
    return <PageError error={error} />
  }

  const { accounts } = data

  return (
    <PageContent>
      <Container verticalPadding>
        <SimpleFilter
          query={params.query}
          addTo={links.accounts.create}
          onChange={handleFilterChange}
        >
          <FlexItem none cloneChildren>
            <FormGroup>
              <FormLabel>Курсы</FormLabel>
              <Button rounded onClick={updateCurrencies}>
                Обновить
              </Button>
            </FormGroup>
          </FlexItem>
        </SimpleFilter>
        <AccountsSortableList items={accounts} saveOrder={saveOrder} />
        <Switch>
          <Route path={links.accounts.create} component={AccountEditor} />
          <Route path={links.accounts.edit} component={AccountEditor} />
        </Switch>
      </Container>
    </PageContent>
  )
}

export default AccountsPageList
