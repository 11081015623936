import { gql, useMutation, useQuery } from 'apollo'
import { useCallback } from 'react'

const DepositionFragment = gql`
  fragment DepositionFragment on Deposition {
    id
    accountId
    datetime
    title
    notes
    marketId
    amount
  }
`

const NODE_QUERY = gql`
  query DepositionEditor_NodeQuery($id: ID!) {
    node(id: $id) {
      id
      ...on Deposition {
        ...DepositionFragment
      }
    }
  }
  ${DepositionFragment}
`

const CREATE_MUTATION = gql`
  mutation DepositionEditor_CreateMutation($input: CreateDepositionInput!) {
    createDeposition(input: $input) {
      node {
        ...DepositionFragment
      }
    }
  }
  ${DepositionFragment}
`

const UPDATE_MUTATION = gql`
  mutation DepositionEditor_UpdateMutation($input: UpdateDepositionInput!) {
    updateDeposition(input: $input) {
      node {
        ...DepositionFragment
      }
    }
  }
  ${DepositionFragment}
`

const DELETE_MUTATION = gql`
  mutation DepositionEditor_DeleteMutation($input: DeleteDepositionInput!) {
    deleteDeposition(input: $input) {
      deletedId
    }
  }
`

const mutationConfig = {
  refetchQueries: ['OperationsRootQuery', 'AccountsRootFeed'],
}

const useDepositionEditor = (id: string) => {
  const { data, loading, error } = useQuery(NODE_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  })
  const node = data ? data.node : null

  const [createMutation] = useMutation(CREATE_MUTATION, mutationConfig)
  const [updateMutation] = useMutation(UPDATE_MUTATION, mutationConfig)
  const [deleteMutation] = useMutation(DELETE_MUTATION, mutationConfig)

  const saveNode = useCallback((values: any) => {
    const mutation = values.id ? updateMutation : createMutation
    const input = {
      ...values,
      resetFields: ['marketId', 'notes'],
    }

    return mutation({ variables: { input } })
  }, [createMutation, updateMutation])

  const deleteNode = useCallback((id: string) => {
    return deleteMutation({ variables: { input: { id } } })
  }, [deleteMutation])

  return {
    node,
    loading,
    error,
    saveNode,
    deleteNode,
  }
}

export default useDepositionEditor
