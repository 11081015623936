import React from 'react'
import cx from 'classnames'

import s from './Box.scss'

const allowedKeys = ['mt', 'mb', 'mr', 'ml', 'mlr', 'mtb', 'pt', 'pb', 'pl', 'pr', 'p', 'plr', 'ptb'] as const
const allowedValues = ['auto', 4, 8, 16, 24, 32, 40, 56] as const

export type Key = typeof allowedKeys[number]
export type OffsetValue = typeof allowedValues[number]
export type OffsetValues = {
  [ key in Key ]?: OffsetValue
}

export type BoxProps = React.HTMLAttributes<HTMLDivElement> & OffsetValues & {
  children?: React.ReactNode | React.ReactElement<{ className?: string }>
  cloneChildren?: boolean
  align?: 'left' | 'right' | 'center'
  inline?: boolean
}

const getClasses = (props: BoxProps): string[] => (
  allowedKeys
    .map((key) => {
      const spacer = props[key]

      if (!spacer) {
        return null
      }

      return s[`${key}-${spacer}`]
    })
    .filter((item) => !!item)
)

const Box: React.FunctionComponent<BoxProps> = (props) => {
  const { children, className, cloneChildren, align, inline, ...rest } = props

  const rootClassName = cx(className, getClasses(rest), {
    [s[`align-${align}`]]: !!align,
    [s.inline]: inline,
  })

  allowedKeys.forEach((key) => {
    delete rest[key]
  })

  if (cloneChildren && React.isValidElement(children)) {
    return React.cloneElement(children, {
      className: cx(rootClassName, children.props.className),
      ...rest,
    })
  }

  return (
    <div className={rootClassName} {...rest}>
      {children}
    </div>
  )
}

export default Box
