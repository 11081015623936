import { useCallback } from 'react'
import { Form as FormikForm, FormikFormProps } from 'formik'

export type FormProps = FormikFormProps & {
  preventSubmitOnEnter?: boolean
}

const Form: React.FunctionComponent<FormProps> = ({ preventSubmitOnEnter = true, ...props }) => {
  const handleKeyPress = useCallback((event: React.KeyboardEvent<HTMLFormElement>) => {
    if (preventSubmitOnEnter) {
      if (event.keyCode === 13) {
        event.preventDefault()
      }
    }
  }, [preventSubmitOnEnter])

  return (
    <FormikForm
      {...props}
      onKeyPress={handleKeyPress}
    />
  )
}

export default Form
