import { useLazyQuery, gql } from 'apollo'
import { OperationData } from '../../types'
import { useMemo } from 'react'
import pathOr from 'ramda/es/pathOr'
import { getTimestamp } from 'helpers'

const SEARCH_QUERY = gql`
  query ImportSearchQuery($operationsInput: OperationsInput!, $receiptInput: NalogRuReceiptsInput!) {
    operations(input: $operationsInput) {
      items {
        id
        type
        title
        datetime
        amount
        origin {
          id
        }
      }
    }
    nalogRuReceipts(input: $receiptInput) {
      items {
        id
        title
        datetime
        amount
        items {
          title
          amount
          count
          price
          productId
          categoriesIds
        }
      }
    }
  }
`

type useSearchQueryParams = {
  data: OperationData
  accountId?: string
}

export const useSearchQuery = (params: useSearchQueryParams) => {
  const { data, accountId} = params

  const variables = useMemo(() => {
    const { amount, datetime } = data

    const isTaxi = data.title.match(/транспорт/i)

    const startDateTime = getTimestamp(datetime.startOf('day'))
    const endDateTime = getTimestamp(
      isTaxi ? datetime.plus({ hours: 36 }) : datetime.endOf('day')
    )

    return {
      operationsInput: {
        perPage: 1,
        page: 1,
        amount,
        amountAsItem: true,
        accountIds: accountId ? [accountId] : undefined,
        dates: [[startDateTime, endDateTime]],
      },
      receiptInput: {
        perPage: 1,
        page: 1,
        amount,
        dates: [[startDateTime, endDateTime]],
      },
    }
  }, [data, accountId])

  const [search, { data: searchData, loading: isFetching }] = useLazyQuery(SEARCH_QUERY, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

  const found = pathOr(null, ['operations', 'items', 0], searchData)
  const receipt = pathOr(null, ['nalogRuReceipts', 'items', 0], searchData)

  return {
    search,
    found,
    receipt,
    isFetching,
    isFetched: Boolean(searchData)
  }
}
