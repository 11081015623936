
import s from './Debug.scss'

type DebugProps = {
  title?: React.ReactNode
  data?: any
}

const Debug: React.FunctionComponent<DebugProps> = (props) => {
  const { title = 'Debug', data } = props

  return (
    <div className={s.root}>
      {title && <div className={s.header}>{title}</div>}
      <pre className={s.pre}>{JSON.stringify(data, null, 2)}</pre>
    </div>
  )
}

export default Debug
