export const parseFloat = (value: string | number) => {
  if (!value) {
    return 0
  }

  if (typeof value === 'number') {
    return value
  }

  return Number.parseFloat(value.replace(/,/g, '.').trim()) || 0
}

export const toFixed = (value: number, signs = 2) => {
  const multiplier = Math.pow(10, signs)

  return Math.round(value * multiplier) / multiplier
}
