
import Loader from 'components/Loader'

import s from './ContentLoader.scss'

const ContentLoader = () => (
  <div className={s.root}>
    <Loader heightBased className={s.loader} />
  </div>
)

export default ContentLoader
