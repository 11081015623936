
import ButtonBase, { ButtonBaseProps } from 'components/ButtonBase'

import s from './NavItem.scss'

type NavItemProps = ButtonBaseProps & {
  title: string
}

const NavItem: React.FunctionComponent<NavItemProps> = ({ title, ...rest }) => (
  <div className={s.navItem}>
    <ButtonBase {...rest} className={s.link} activeClassName={s.activeLink}>
      {title}
    </ButtonBase>
  </div>
)

export default NavItem
