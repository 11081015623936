
import cx from 'classnames'
import ButtonBase, { ButtonBaseProps } from 'components/ButtonBase'
import Loader from 'components/Loader'

import s from './Button.scss'

export type ButtonProps = ButtonBaseProps & {
  loading?: boolean
  block?: boolean
  color?: 'default' | 'primary' | 'danger'
  size?: 'sm' | 'md' | 'lg'
  rounded?: boolean
}

const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const { children, className, block, loading, color, size, rounded, disabled, ...rest } = props

  const rootClassName = cx(className, s.root, {
    [s[color]]: !!color,
    [s[size]]: s[size],
    [s.block]: block,
    [s.disabled]: disabled || loading,
    [s.loading]: loading,
    [s.rounded]: rounded,
  })

  return (
    <ButtonBase
      className={rootClassName}
      disabled={disabled || loading}
      {...rest}
    >
      <span className={s.inner}>
        {loading ? <Loader className={s.loader} heightBased strokeWidth={4} /> : null}
        <span className={s.content}>
          {children}
        </span>
      </span>
    </ButtonBase>
  )
}

Button.defaultProps = {
  color: 'default',
  size: 'md',
}

export default Button
