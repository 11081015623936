import { ApolloError, isApolloError } from '@apollo/client/errors'

const messages = {
  4002: 'Неверные логин или пароль',
}

const getErrorMessage = (error: ApolloError | Error): string => {
  if (isApolloError(error)) {
    const { graphQLErrors } = error as ApolloError

    if (graphQLErrors && graphQLErrors.length) {
      const gqlError = graphQLErrors[0]

      if (gqlError.extensions) {
        const { extensions: { code, message } } = gqlError

        if (messages[code] !== undefined) {
          return messages[code]
        }

        return message
      }

      return gqlError.message
    }
  }

  return error.message
}

export default getErrorMessage
