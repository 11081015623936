
import Box from 'components/Box'

type ModalContentProps = {
  children?: React.ReactNode
}

const ModalContent: React.FunctionComponent<ModalContentProps> = (props) => {
  const { children } = props

  return (
    <Box p={16}>
      {children}
    </Box>
  )
}

export default ModalContent
