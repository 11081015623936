import { useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import { gql, useMutation, useQuery } from 'apollo'
import { links, getLinkWithSearch, pathOr } from 'helpers'

import ModalLoader from 'components/ModalLoader/ModalLoader'
import ModalError from 'components/ModalError'
import MarketForm, { MarketFormSubmit } from './MarketForm'

const NODE_QUERY = gql`
  query MarketEditor_NodeQuery($id: ID!) {
    node(id: $id) {
      id
      ...on Market {
        id
        name
        notes
      }
    }
  }
`

const CREATE_MUTATION = gql`
  mutation MarketEditor_CreateMutation($input: CreateMarketInput!) {
    createMarket(input: $input) {
      node {
        id
        name
        notes
      }
    }
  }
`

const UPDATE_MUTATION = gql`
  mutation MarketEditor_UpdateMutation($input: UpdateMarketInput!) {
    updateMarket(input: $input) {
      node {
        id
        name
        notes
      }
    }
  }
`

const DELETE_MUTATION = gql`
  mutation MarketEditor_DeleteMutation($input: DeleteMarketInput!) {
    deleteMarket(input: $input) {
      deletedId
    }
  }
`

const mutationConfig = {
  refetchQueries: ['MarketsRootQuery'],
}

type MarketEditorProps = RouteComponentProps<any>

const useNode = (id: string) => {
  const { data, loading, error } = useQuery(NODE_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  })

  return { node: pathOr(null, ['node'], data) as any, loading, error }
}

const MarketEditor = (props: MarketEditorProps) => {
  const { match: { params: { id: requestedId = '' } }, history } = props

  const { node, loading, error } = useNode(requestedId)

  const [ createMutation ] = useMutation(CREATE_MUTATION, mutationConfig)
  const [ updateMutation ] = useMutation(UPDATE_MUTATION, mutationConfig)
  const [ deleteMutation ] = useMutation(DELETE_MUTATION, mutationConfig)

  const handleSubmit = useCallback<MarketFormSubmit>((values, actions) => {
    const mutation = node ? updateMutation : createMutation

    mutation({ variables: { input: values } })
      .then(
        () => {
          history.push(getLinkWithSearch(links.markets.root))
        },
        (mutationError) => {
          const { validationErrors } = mutationError

          if (validationErrors) {
            actions.setErrors(validationErrors)
          }
        }
      )
      .then(() => {
        actions.setSubmitting(false)
      })
  }, [ createMutation, updateMutation, node, history ])

  const handleDelete = useCallback(() => {
    const { id } = node

    if (confirm('Вы уверены?')) {
      deleteMutation({ variables: { input: { id } } })
        .then(
          () => {
            history.push(getLinkWithSearch(links.markets.root))
          },
          (mutationError) => {
            alert(mutationError)
          }
        )
    }
  }, [node, deleteMutation, history])

  const handleClose = useCallback(() => {
    history.push(getLinkWithSearch(links.markets.root))
  }, [history])

  if (loading) {
    return <ModalLoader size="md" onClose={handleClose} />
  }

  if (error) {
    return <ModalError size="md" onClose={handleClose} error={error} />
  }

  return (
    <MarketForm
      node={node}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    />
  )
}

export default MarketEditor
