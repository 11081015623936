
import cx from 'classnames'

import ButtonBase, { ButtonBaseProps } from 'components/ButtonBase'
import s from './Link.scss'

type LinkProps = ButtonBaseProps

const Link: React.FunctionComponent<LinkProps> = (props) => {
  const { children, className, ...rest } = props

  return (
    <ButtonBase className={cx(className, s.root)} {...rest}>
      {children}
    </ButtonBase>
  )
}

export default Link
