
import Modal from 'components/Modal/Modal'
import { ModalBaseSize } from 'components/Modal/ModalBase'
import ContentLoader from 'components/ContentLoader'

type ModalLoaderProps = {
  title?: string
  size?: ModalBaseSize
  onClose?: () => void
}

const ModalLoader: React.FunctionComponent<ModalLoaderProps> = ({ title, size, onClose }) => (
  <Modal title={title || 'Загрузка...'} size={size} onClose={onClose}>
    <ContentLoader />
  </Modal>
)

export default ModalLoader
