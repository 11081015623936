import { useState, useEffect, useCallback } from 'react'
import { pathOr } from 'ramda'
import { throttle } from 'throttle-debounce'
import { MediaContextValue, MediaContext } from 'helpers/hooks/useMedia'

const getMediaState = () => {
  const clientWidth = Number(pathOr(0, ['document', 'documentElement', 'clientWidth'], window))
  const tabletStart = 568
  const desktopStart = 1200
  const isMobile = clientWidth < tabletStart
  const isTablet = clientWidth >= tabletStart && clientWidth < desktopStart
  const isDesktop = clientWidth >= desktopStart
  const isExceptMobile = clientWidth >= tabletStart
  const isLarge = clientWidth >= 1600

  return {
    isMobile,
    isExceptMobile,
    isTablet,
    isDesktop,
    isLarge,
  }
}

const MediaProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<MediaContextValue>(getMediaState())

  const resize = useCallback(() => {
    setState(getMediaState())
  }, [])

  useEffect(() => {
    const throttledResize = throttle(300, resize)

    window.addEventListener('resize', throttledResize, false)

    return () => {
      window.removeEventListener('resize', throttledResize, false)
    }
  }, [resize])

  return (
    <MediaContext.Provider value={state}>
      {children}
    </MediaContext.Provider>
  )
}

export default MediaProvider
