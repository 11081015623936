import { DateTime } from 'luxon'
import { getTimestamp } from './getTimestamp'
import { parseFloat } from './numbers'

const parseDate = (date: string) => {
  return DateTime.fromFormat(date, 'd.L.yy')
}

const getParsedQuery = (initialQuery: string) => {
  if (!initialQuery) {
    return null
  }

  const result: any = {}
  const dates: [number, number][] = []
  let query = initialQuery.toString()

  // 14.02.16-14.04.16
  let matches = query.match(/\b(\d{1,2})\.(\d{1,2})\.(\d{2,4})-(\d{1,2})\.(\d{1,2})\.(\d{2,4})\b/g)

  if (matches !== null) {
    matches.forEach((match) => {
      const parts = match.split('-')

      try {
        dates.push([
          getTimestamp(parseDate(parts[0]).startOf('day')),
          getTimestamp(parseDate(parts[1]).endOf('day')),
        ])

        query = query.replace(match, '')
      } catch (err) {
        console.log(err)
      }
    })
  }

  // 14.02.17
  matches = query.match(/\b(\d{1,2})\.(\d{1,2})\.(\d{2,4})\b/g)

  if (matches !== null) {
    matches.forEach((match) => {
      try {
        const date = parseDate(match)

        dates.push([
          getTimestamp(date.startOf('day')),
          getTimestamp(date.endOf('day')),
        ])

        query = query.replace(match, '')
      } catch (err) {
        console.log(err)
      }
    })
  }

  // .12.19 - month
  matches = query.match(/\.(\d{1,2})\.(\d{2,4})\b/g)

  if (matches !== null) {
    matches.forEach((match) => {
      try {
        const date = parseDate(`01${match}`)

        dates.push([
          getTimestamp(date.startOf('day')),
          getTimestamp(date.endOf('month').endOf('day')),
        ])

        query = query.replace(match, '')
      } catch (err) {
        console.log(err)
      }
    })
  }

  // 12.02 - day
  matches = query.match(/\b(\d{1,2})\.(\d{1,2})\b/g)

  if (matches !== null) {
    const year = (new Date()).getFullYear()

    matches.forEach((match) => {
      try {
        const date = parseDate(`${match}.${year}`)

        dates.push([
          getTimestamp(date.startOf('day')),
          getTimestamp(date.endOf('day')),
        ])

        query = query.replace(match, '')
      } catch (err) {
        console.log(err)
      }
    })
  }

  // find amount
  matches = query.match(/\b[\d.,]+\b/)

  if (matches !== null) {
    matches.forEach((match) => {
      try {
        result.amount = parseFloat(match)

        query = query.replace(match, '')
      } catch (err) {
        console.log(err)
      }
    })
  }

  result.dates = dates
  result.query = query.replace(/[^\w\d\sА-ЯЁ-]/ig, '').trim()

  return result
}

export default getParsedQuery
