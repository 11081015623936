import { useCallback, useMemo } from 'react'
import { Switch, Route } from 'react-router-dom'

import { useQuery, gql } from 'apollo'
import { useQueryParams, useFetchMore, links, getLinkWithSearch, updateAndStringifyQueryParams } from 'helpers'

import Container from 'components/Container'
import ContentLoader from 'components/ContentLoader'
import PageError from 'components/PageError'
import Pagination from 'components/Pagination'
import MarketsList from './MarketsList'
import PageContent from 'components/PageContent'
import SimpleFilter from 'components/SimpleFilter'

import MarketEditor from '../MarketEditor/MarketEditor'

export const ROOT_QUERY = gql`
  query MarketsRootQuery($input: DefaultQueryInput!) {
    markets(input: $input) @connection(key: "MarketsRootFeed") {
      items {
        id
        name
        notes
      }
      pageInfo {
        page
        pageCount
      }
    }
  }
`

const OperationsRoot = ({ location, history }) => {
  const params = useQueryParams(({ page, perPage, query }) => ({
    page: Number.parseInt(page as string) || 1,
    perPage: Number.parseInt(perPage as string) || 20,
    query: query as string,
  }), location)
  const variables = useMemo(() => ({ input: params }), [params])

  const { data, loading, error, fetchMore } = useQuery(ROOT_QUERY, {
    variables,
  })

  useFetchMore(fetchMore, variables)

  const handlePageChange = useCallback((page) => {
    history.push({ search: updateAndStringifyQueryParams(params, { page }) })
  }, [params, history])

  const handleFilterChange = useCallback((filters) => {
    history.push({ search: updateAndStringifyQueryParams(params, filters) })
  }, [history, params])

  if (error) {
    return <PageError error={error} />
  }

  if (loading) {
    return <ContentLoader />
  }

  const { markets: { pageInfo, items } } = data

  return (
    <PageContent>
      <Container verticalPadding>
        <SimpleFilter
          query={params.query}
          addTo={getLinkWithSearch(links.markets.create)}
          onChange={handleFilterChange}
        />
        {
          loading ? (
            <ContentLoader />
          ) : (
            <MarketsList items={items} />
          )
        }
        <Switch>
          <Route path={links.markets.create} component={MarketEditor} />
          <Route path={links.markets.edit} component={MarketEditor} />
        </Switch>
        <Pagination {...pageInfo} fixed onChange={handlePageChange} />
      </Container>
    </PageContent>

  )
}

export default OperationsRoot
