import React, { Fragment, forwardRef, useMemo, useCallback, useEffect } from 'react'
import cx from 'classnames'
import { Option, OptionRenderer, OptionClick } from '../types'

import MenuItem from './MenuItem'
import Box from 'components/Box'
import Text from 'components/Text'

import useFilteredOptions from './useFilteredOptions'
import s from './Menu.scss'

type MenuProps = {
  className?: string
  options: Option[]
  value: Option | Option[]
  inputValue?: string
  multiple?: boolean
  selectedOption?: Option
  setSelectedOption?: (option: Option) => void
  filteredOptionsRef?: React.MutableRefObject<Option[]>
  itemsRef: React.MutableRefObject<React.RefObject<HTMLDivElement>[]>
  renderOption?: OptionRenderer
  creatable?: boolean
  onOptionClick?: OptionClick
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>
}

const Menu = forwardRef<HTMLDivElement, MenuProps>((props, forwardedRef) => {
  const {
    className, options, value, inputValue, multiple, selectedOption, setSelectedOption, filteredOptionsRef, itemsRef,
    renderOption, creatable, onOptionClick, onMouseDown,
  } = props

  const filteredOptions = useFilteredOptions({ options, value, inputValue, creatable })

  filteredOptionsRef.current = filteredOptions

  // Reset selection on input change
  useEffect(() => {
    setSelectedOption(null)
  }, [inputValue, setSelectedOption])

  useEffect(() => {
    if (!selectedOption && !multiple && value) {
      setSelectedOption(value as Option)
      return
    }

    if (filteredOptions.length > 0) {
      if (!selectedOption) {
        setSelectedOption(filteredOptions[0])
      }
      else if (filteredOptions.indexOf(selectedOption) === -1) {
        setSelectedOption(filteredOptions[0])
      }
    }
  }, [selectedOption, filteredOptions, setSelectedOption, multiple, value])

  const handleOptionHover = useCallback((option: Option) => {
    setSelectedOption(option)
  }, [setSelectedOption])

  // option refs
  itemsRef.current = useMemo(() => {
    return (new Array(filteredOptions.length)).fill(undefined).map(() => React.createRef<HTMLDivElement>())
  }, [filteredOptions.length])

  return (
    <div
      ref={forwardedRef}
      className={cx(className, s.root)}
      tabIndex={-1}
      onMouseDown={onMouseDown}
    >
      {
        filteredOptions.length
          ? (
            <Fragment>
              {filteredOptions.map((option, index) => (
                <MenuItem
                  key={option.value}
                  ref={itemsRef.current[index]}
                  option={option}
                  selected={!multiple && option === value}
                  focused={selectedOption === option}
                  onClick={onOptionClick}
                  onHover={handleOptionHover}
                >
                  {renderOption}
                </MenuItem>
              ))}
            </Fragment>
          )
          : (
            <Box p={8}>
              <Text muted align="center">Ничего не найдено</Text>
            </Box>
          )
      }
    </div>
  )
})

Menu.displayName = 'Menu'

export default Menu
