import { useMemo, useCallback } from 'react'
import { Field, Form, Formik, FormikHelpers } from 'components/formik'
import * as Yup from 'yup'
import { getCurrentTimestamp, getQueryParams } from 'helpers'

import { Modal } from 'components/Modal'
import ModalLoader from 'components/ModalLoader'
import ModalError from 'components/ModalError'

import AccountSelect from 'components/data/AccountSelect'
import MarketSelect from 'components/data/MarketSelect'
import useDepositionEditor from './useDepositionEditor'

export type DepositionFormValues = {
  accountId?: string
  datetime?: number
  title?: string
  notes?: string
  marketId?: string
  amount?: number
}

type DepositionFormProps = {
  id?: string
  initialData?: DepositionFormValues
  onSubmit: () => void
  onClose: () => void
  onDelete: () => void
}

const DepositionFormSchema = Yup.object().shape({
  accountId: Yup.string().required(),
  datetime: Yup.number().required(),
  title: Yup.string().min(2).required(),
  notes: Yup.string().nullable(),
  marketId: Yup.string().nullable(),
  amount: Yup.number().required(),
})

const DepositionForm: React.FunctionComponent<DepositionFormProps> = (props) => {
  const { id, initialData, onSubmit, onClose, onDelete } = props

  const {
    node,
    loading,
    error,
    saveNode,
    deleteNode,
  } = useDepositionEditor(id)

  const initialValues = useMemo<DepositionFormValues>(() => {
    const { accountIds = [] } = getQueryParams()

    return {
      accountId: accountIds && accountIds.length === 1 ? accountIds[0] : '',
      datetime: getCurrentTimestamp(),
      title: '',
      notes: '',
      marketId: null,
      amount: null,
      ...initialData,
    }
  }, [initialData])

  const handleSubmit = useCallback((values: DepositionFormValues, actions: FormikHelpers<DepositionFormValues>) => {
    saveNode(values)
      .finally(() => {
        actions.setSubmitting(false)
      })
      .then(
        () => {
          if (typeof onSubmit === 'function') {
            onSubmit()
          }
        },
        (mutationError) => {
          const { validationErrors } = mutationError

          if (validationErrors) {
            actions.setErrors(validationErrors)
          }
        }
      )
  }, [onSubmit, saveNode])

  const handleDelete = useCallback(() => {
    if (!id) {
      return
    }

    if (confirm('Вы уверены?')) {
      deleteNode(id)
        .then(
          () => {
            if (typeof onDelete === 'function') {
              onDelete()
            }
          },
          (mutationError) => {
            alert(mutationError)
          }
        )
    }
  }, [id, deleteNode, onDelete])

  if (loading) {
    return <ModalLoader size="md" onClose={onClose} />
  }

  if (error) {
    return <ModalError size="md" onClose={onClose} error={error} />
  }

  return (
    <Formik
      validationSchema={DepositionFormSchema}
      enableReinitialize
      initialValues={node || initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Modal
          size="md"
          title={`${!node ? 'Создание' : 'Редактирование'} пополнения`}
          onClose={onClose}
          wrapper={Form}
          footerButtons={[
            { children: 'Удалить', onClick: handleDelete, color: 'danger' },
            { children: 'Назад', onClick: onClose },
            { children: 'Сохранить', color: 'primary', loading: isSubmitting, type: 'submit' },
          ]}
        >
          <Field label="Счет" name="accountId" control={AccountSelect} />
          <Field label="Дата и время" name="datetime" control="datetime" />
          <Field label="Название" name="title" />
          <Field label="Источник" name="marketId" control={MarketSelect} />
          <Field label="Описание" name="notes" multiline={true} />
          <Field label="Сумма" type="number" name="amount" />
        </Modal>
      )}
    </Formik>
  )
}

export default DepositionForm
