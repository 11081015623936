
import { Option } from '../types'

type SelectedSingleProps = {
  className?: string
  value: Option
}

const SelectedSingle: React.FunctionComponent<SelectedSingleProps> = (props) => {
  const { className, value } = props

  if (!value) {
    return null
  }

  return (
    <div className={className}>
      {value.label}
    </div>
  )
}

export default SelectedSingle
