import { useRef } from 'react'
import uniqid from 'uniqid'

const useUniqueId = (prefix = '', length = 4) => {
  const id = useRef(null)

  if (!id.current) {
    id.current = `${prefix}${uniqid.time().substr(0, length)}`
  }

  return id.current
}

export default useUniqueId
