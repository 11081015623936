import { createContext, useContext } from 'react'

export type MediaContextValue = {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  isExceptMobile: boolean
  isLarge: boolean
}

export const MediaContext = createContext<MediaContextValue>(null)

const useMedia = (): MediaContextValue => {
  return useContext(MediaContext)
}

export default useMedia
