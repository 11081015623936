
import Text, { TextProps } from 'components/Text'
import Box, { OffsetValue } from 'components/Box'

type TitleProps = TextProps & {
  size?: 'h1' | 'h2'
  mt?: OffsetValue
  mb?: OffsetValue
}

const Title: React.FunctionComponent<TitleProps> = (props) => {
  const { size, mt, mb: userMb, ...rest } = props

  let mb

  if (size === 'h1') {
    mb = 24
  }
  else if (size === 'h2') {
    mb = 16
  }
  if (userMb !== undefined) {
    mb = userMb
  }

  return (
    <Box cloneChildren mt={mt} mb={mb}>
      <Text size={size} component={size} {...rest} />
    </Box>
  )
}

Title.defaultProps = {
  size: 'h1',
}

export default Title
