
import { Operation } from 'pages/operations/OperationsList/types'
import Label from 'components/Label'

type OperationAccountProps = {
  account: Operation['account']
  asText?: boolean
}

const OperationAccount: React.FunctionComponent<OperationAccountProps> = ({ account, asText }) => {
  if (asText) {
    const color = '#5a5a5a'

    return (
      <span style={{ color }}>{account.name}</span>
    )
  }

  const color = 'lightgray'

  return (
    <Label color={color}>{account.name}</Label>
  )
}

export default OperationAccount
