import { useCallback } from 'react'
import cx from 'classnames'

import { Option, OptionValue, OptionCreater } from './types'

import Input from './Input'
import Menu from './Menu'
import { SelectedSingle, SelectedMultiple } from './Selected'

import useValueState from './useValueState'
import useMenuState from './useMenuState'

import s from './BaseSelect.scss'
import IconButton from 'components/IconButton'
import Icon from 'components/Icon'
import Loader from 'components/Loader'

export type BaseSelectProps = {
  className?: string
  rounded?: boolean
  hasFocus?: boolean
  hasError?: boolean
  name?: string
  options?: Option[]
  value?: Option | Option[]
  multiple?: boolean
  clearable?: boolean
  searchable?: boolean
  creatable?: boolean
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  onChange?: (values?: OptionValue | OptionValue[]) => void
  onCreateOption?: OptionCreater
}

const BaseSelect: React.FunctionComponent<BaseSelectProps> = (props) => {
  const {
    className,
    rounded,
    hasFocus,
    hasError,
    options,
    placeholder,
    multiple,
    searchable,
    clearable,
    creatable,
    disabled,
    isLoading,
  } = props

  const {
    value,
    setValue,
    selectOption,
    removeOption,
    isFilled,
  } = useValueState(props)

  const {
    inputValue,
    onInputChange,
    onInputFocus,
    onInputBlur,
    isOpened,
    isFocused,
    openMenu,
    closeMenu,
    inputRef,
    menuRef,
    menuItemsRef,
    openMenuAfterFocus,
    filteredOptionsRef,
    selectedOption,
    setSelectedOption,
    selectNextOption,
    selectPerviousOption,
  } = useMenuState()

  // add option
  const handleOptionClick = useCallback((option: Option) => {
    selectOption(option)
    closeMenu()
  }, [closeMenu, selectOption])

  const handleClearButtonMouseDown = useCallback((event: React.MouseEvent) => {
    event.preventDefault()
  }, [])

  const handleClearButtonClick = useCallback(() => {
    setValue(null)
  }, [setValue])

  // Input and keyboard functionality

  const handleControlMouseDown = useCallback((event: React.MouseEvent) => {
    if (disabled) {
      return
    }

    // If prevented only focus
    if (event.defaultPrevented) {
      if (!isFocused) {
        inputRef.current.focus()
      }

      return
    }

    const target = event.target as HTMLElement

    if (!isFocused) {
      openMenuAfterFocus.current = true
      inputRef.current.focus()
    }
    else if (!isOpened) {
      openMenu()
    }
    else {
      if (target.tagName !== 'INPUT') {
        closeMenu()
      }
    }

    if (target.tagName !== 'INPUT') {
      event.preventDefault()
    }
  }, [closeMenu, disabled, inputRef, isFocused, isOpened, openMenu, openMenuAfterFocus])

  const handleMenuMouseDown = useCallback((event) => {
    if (event.button !== 0) {
      return
    }

    event.stopPropagation()
    event.preventDefault()

    inputRef.current.focus()
  }, [inputRef])

  // Keyboard control
  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (disabled) {
      return
    }

    const { keyCode, key } = event

    switch (keyCode) {
      case 38: { // up
        if (isOpened) {
          selectPerviousOption()
        }
        else {
          openMenu()
        }

        event.preventDefault()
        break
      }
      case 40: { // down
        if (isOpened) {
          selectNextOption()
        }
        else {
          openMenu()
        }

        event.preventDefault()
        break
      }
      case 27: { // esc
        closeMenu()
        break
      }
      case 13: {// enter
        if (isOpened) {
          selectOption(selectedOption)
          closeMenu()
        }
        else {
          openMenu()
        }

        event.preventDefault()
        event.stopPropagation()
        break
      }
      case 8: { // backspace
        if (inputValue === '' && multiple && Array.isArray(value) && value.length) {
          removeOption(value[value.length - 1])
        }

        break
      }
      case 9: { //tab
        return
      }
      default: {
        if (event.getModifierState(key)) {
          return
        }

        if (!isOpened) {
          // otherwise input doesn't change value
          // because of 2 state updates
          setImmediate(() => {
            openMenu()
          })
        }
      }
    }
  }, [
    closeMenu, disabled, inputValue, isOpened, multiple, openMenu, removeOption,
    selectNextOption, selectOption, selectPerviousOption, selectedOption, value
  ])

  const input = (
    <Input
      ref={inputRef}
      tabIndex={0}
      readOnly={!searchable}
      className={s.searchInput}
      value={inputValue}
      onChange={onInputChange}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
    />
  )

  const clearButton = clearable && isFilled && (
    <IconButton
      icon="close"
      size={null}
      color={null}
      tabIndex={-1}
      width={12}
      height={12}
      className={s.clearButton}
      onMouseDown={handleClearButtonMouseDown}
      onClick={handleClearButtonClick}
    />
  )

  const rootClassName = cx(className, s.root, {
    [s.rounded]: rounded,
    [s.hasFocus]: hasFocus,
    [s.hasError]: hasError,
  })

  let selectedNode

  if (isFilled && multiple) {
    selectedNode = (
      <SelectedMultiple
        value={value as Option[]}
        onRemoveClick={removeOption}
      />
    )
  }
  else if (isFilled && !inputValue) {
    selectedNode = (
      <SelectedSingle
        className={s.selectedSingle}
        value={value as Option}
      />
    )
  }

  return (
    <div className={rootClassName}>
      <div className={s.control}
        onMouseDown={handleControlMouseDown}
        onKeyDown={handleKeyDown}
        tabIndex={-1}
      >
        <div className={s.controlLeftSide}>
          {
            !isFilled && !inputValue && (
              <div className={s.placeholder}>{placeholder}</div>
            )
          }
          {selectedNode}
          {input}
        </div>
        <div className={s.controlRightSide}>
          {
            isLoading ? (
              <Loader className={s.loader} />
            ) : (
              clearButton
            )
          }
          <Icon
            icon="chevron-select"
            className={s.chevron}
          />
        </div>
      </div>
      {
        isOpened && (
          <Menu
            ref={menuRef}
            itemsRef={menuItemsRef}
            className={s.menu}
            options={options}
            value={value}
            inputValue={inputValue}
            multiple={multiple}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            filteredOptionsRef={filteredOptionsRef}
            creatable={creatable}
            onOptionClick={handleOptionClick}
            onMouseDown={handleMenuMouseDown}
          />
        )
      }
    </div>
  )
}

BaseSelect.defaultProps = {
  searchable: true,
  clearable: true,
}

export default BaseSelect
