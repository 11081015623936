
import Box from 'components/Box'

import s from './ModalFooter.scss'

type ModalFooterProps = {
  children?: React.ReactNode
}

const ModalFooter: React.FunctionComponent<ModalFooterProps> = (props) => {
  const { children } = props

  return (
    <Box p={16} className={s.root}>
      {children}
    </Box>
  )
}

export default ModalFooter
