import { useMemo, useCallback } from 'react'
import cx from 'classnames'
import { DateTime, Info } from 'luxon'
import DayPicker, { LocaleUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'

import s from './DatePicker.scss'

type DatePickerProps = {
  className?: string
  value: DateTime | null
  onChange: (value: DateTime) => void
}

const CustomLocaleUtils: LocaleUtils = {
  ...DayPicker.LocaleUtils,
  weekday(i) {
    return i > 0 ? i - 1 : 6
  },
  formatMonthTitle(d) {
    const date = DateTime.fromJSDate(d)

    return date.toFormat('LLLL yyyy')
  },
  getMonths() {
    return Info.months('long')
  },
  getFirstDayOfWeek() {
    return 1
  },
  formatWeekdayLong(i) {
    if (!this.weekdayLong) {
      this.weekdayLong = Info.weekdays('long')
    }

    return this.weekdayLong[this.weekday(i)]
  },
  formatWeekdayShort(i) {
    if (!this.weekdayShort) {
      this.weekdayShort = Info.weekdays('short')
    }

    return this.weekdayShort[this.weekday(i)]
  },
}

const DatePicker: React.FunctionComponent<DatePickerProps> = (props) => {
  const { className, value, onChange } = props

  const selectedDay = useMemo(() => {
    return value && value.isValid ? value && value.toJSDate() : null
  }, [value])

  const handleDayClick = useCallback((day) => {
    const currentDate = value || DateTime.local()
    let date = DateTime.fromJSDate(day)

    date = date.set({ hour: currentDate.hour, minute: currentDate.minute, second: 0 })

    if (date.isValid) {
      onChange(date)
    }
  }, [value, onChange])

  return (
    <DayPicker
      className={cx(className, s.root)}
      localeUtils={CustomLocaleUtils}
      onDayClick={handleDayClick}
      selectedDays={selectedDay}
      month={selectedDay}
    />
  )
}

export default DatePicker
