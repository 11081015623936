import { createContext, Ref } from 'react'

export type DropdownContextValue = {
  isOpened: boolean
  open: (event?: any) => void
  close: () => void
  toggle: () => void
  openerRef: Ref<any>
  containerRef: React.MutableRefObject<any>
}

const DropdownContext = createContext<DropdownContextValue>(null)

export const DropdownContextProvider = DropdownContext.Provider

export default DropdownContext
