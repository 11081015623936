
import { getLink, links, getLinkWithSearch } from 'helpers'
import List from 'components/List'
import ListItem from 'components/ListItem'
import Text from 'components/Text'
import Box from 'components/Box'

type MarketsListProps = {
  className?: string
  items: [{ id: string, name: string, notes?: string }]
}

const MarketsList = (props: MarketsListProps) => {
  const { items } = props

  return (
    <List>
      {items && items.map(({ id, name, notes }) => (
        <ListItem key={id} to={getLinkWithSearch(getLink(links.markets.edit, { id }))}>
          <Text text={name} />
          {
            notes && (
              <Box mt={8}>
                <Text size="sm" muted text={notes} />
              </Box>
            )
          }
        </ListItem>
      ))}
    </List>
  )
}

export default MarketsList
