
import Box, { OffsetValue } from 'components/Box'
import Text from 'components/Text'

type FormLabelProps = {
  className?: string
  children?: React.ReactNode
  htmlFor?: string
  mt?: OffsetValue
  mr?: OffsetValue
  mb?: OffsetValue
  ml?: OffsetValue
}

const FormLabel: React.FunctionComponent<FormLabelProps> = ({ className, children, htmlFor, mt, mr, mb, ml }) => (
  <Box {...{ mt, mr, mb, ml }}>
    <Text className={className} tag="label" htmlFor={htmlFor} size="sm">
      {children}
    </Text>
  </Box>
)

FormLabel.defaultProps = {
  mb: 8,
}

export default FormLabel
