import { useCallback } from 'react'
import { Link, NavLink } from 'react-router-dom'

export type ButtonBaseProps = React.AnchorHTMLAttributes<HTMLAnchorElement | HTMLButtonElement> & {
  children?: React.ReactNode
  disabled?: boolean
  autofocus?: boolean
  className?: string
  activeClassName?: string
  href?: string
  to?: any
  exact?: boolean
  component?: React.ElementType
  type?: string
  role?: string
  stopClickPropagation?: boolean
}

const ButtonBase: React.FunctionComponent<ButtonBaseProps> = (props) => {
  const {
    component,
    activeClassName,
    to,
    exact,
    href,
    type,
    role,
    disabled,
    stopClickPropagation,
    onClick,
    ...rest
  } = props

  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    if (typeof onClick === 'function') {
      onClick(event)
    }

    if (stopClickPropagation) {
      event.stopPropagation()
      return
    }
  }, [ onClick, stopClickPropagation ])

  let BaseElement = component ? component : 'button'
  const childProps: { [key: string]: any } = {
    draggable: false,
    onClick: handleClick,
    ...rest,
  }

  if (href) {
    BaseElement = 'a'
    childProps.href = href
  }
  else if (to) {
    if (activeClassName) {
      BaseElement = NavLink
      childProps.activeClassName = activeClassName
    }
    else {
      BaseElement = Link
    }

    childProps.to = to
    childProps.exact = exact
  }

  if (BaseElement === 'button') {
    childProps.type = type
    childProps.disabled = disabled
  }
  else if (BaseElement !== 'a') {
    childProps.role = role || 'button'
  }

  return <BaseElement {...childProps} />
}

ButtonBase.defaultProps = {
  disabled: false,
  type: 'button',
}

export default ButtonBase
