import { Fragment, memo } from 'react'
import cx from 'classnames'
import { Columns } from '../types'

import s from './TableHeader.scss'

type TableHeaderProps = {
  columns: Columns
  columnClassName?: string
}

const TableHeader: React.FunctionComponent<TableHeaderProps> = (props) => {
  const { columns, columnClassName } = props

  return (
    <Fragment>
      <thead>
        <tr>
          {
            columns.map(({ key, title, className, align = 'left', wrap }) => (
              <th
                key={key}
                className={cx(columnClassName, className, { [s.wrap]: wrap })}
                align={align}
              >
                <span className={s.title}>{title}</span>
              </th>
            ))
          }
        </tr>
      </thead>
    </Fragment>
  )
}

export default memo(TableHeader)
