import { useEffect, useRef } from 'react'

const useFetchMore = (fetchMore: (options: any) => void, variables: any) => {
  const renderCounter = useRef(0)

  useEffect(() => {
    // we shouldn't call it for the first render
    if (renderCounter.current > 0 && typeof fetchMore === 'function') {
      fetchMore({
        variables,
      })
    }

    renderCounter.current++
  }, [ fetchMore, variables ])
}

export default useFetchMore
