import { gql, useMutation, useQuery } from 'apollo'
import { useCallback } from 'react'

const TransferFragment = gql`
  fragment TransferFragment on Transfer {
    id
    fromAccountId
    toAccountId
    datetime
    title
    notes
    fromAmount
    toAmount
  }
`

const NODE_QUERY = gql`
  query TransferEditor_NodeQuery($id: ID!) {
    node(id: $id) {
      id
      ...on Transfer {
        ...TransferFragment
      }
    }
  }
  ${TransferFragment}
`

const CREATE_MUTATION = gql`
  mutation TransferEditor_CreateMutation($input: CreateTransferInput!) {
    createTransfer(input: $input) {
      node {
        ...TransferFragment
      }
    }
  }
  ${TransferFragment}
`

const UPDATE_MUTATION = gql`
  mutation TransferEditor_UpdateMutation($input: UpdateTransferInput!) {
    updateTransfer(input: $input) {
      node {
        ...TransferFragment
      }
    }
  }
  ${TransferFragment}
`

const DELETE_MUTATION = gql`
  mutation TransferEditor_DeleteMutation($input: DeleteTransferInput!) {
    deleteTransfer(input: $input) {
      deletedId
    }
  }
`

const mutationConfig = {
  refetchQueries: ['OperationsRootQuery', 'AccountsRootFeed'],
}

const useTransferEditor = (id: string) => {
  const { data, loading, error } = useQuery(NODE_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  })
  const node = data ? data.node : null

  const [createMutation] = useMutation(CREATE_MUTATION, mutationConfig)
  const [updateMutation] = useMutation(UPDATE_MUTATION, mutationConfig)
  const [deleteMutation] = useMutation(DELETE_MUTATION, mutationConfig)

  const saveNode = useCallback((values: any) => {
    const mutation = values.id ? updateMutation : createMutation
    const input = {
      ...values,
      resetFields: ['notes'],
    }

    return mutation({ variables: { input } })
  }, [createMutation, updateMutation])

  const deleteNode = useCallback((id: string) => {
    return deleteMutation({ variables: { input: { id } } })
  }, [deleteMutation])

  return {
    node,
    loading,
    error,
    saveNode,
    deleteNode,
  }
}

export default useTransferEditor
