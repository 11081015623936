import { Fragment } from 'react'
import Button, { ButtonProps } from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import ModalBase, { ModalBaseSize } from 'components/Modal/ModalBase'
import ModalHeader from 'components/Modal/ModalHeader'
import ModalContent from 'components/Modal/ModalContent'
import ModalFooter from 'components/Modal/ModalFooter'

type ModalProps = {
  id?: string
  children?: React.ReactNode
  title?: React.ReactNode
  size?: ModalBaseSize
  footerButtons?: ButtonProps[]
  onClose?: () => void
  closeOnBackdropClick?: boolean
  wrapper?: React.ElementType
  wrapperProps?: any
}

const Modal: React.FunctionComponent<ModalProps> = (props) => {
  const { children, title, size, footerButtons, id, onClose, closeOnBackdropClick, wrapper: Wrapper, wrapperProps } = props

  let content = (
    <Fragment>
      <ModalHeader title={title} onClose={onClose} />
      <ModalContent>
        {children}
      </ModalContent>
      {
        (footerButtons && footerButtons.length) ? (
          <ModalFooter>
            <ButtonGroup>
              {
                footerButtons.map((buttonProps, index) => (
                  <Button key={index} {...buttonProps} />
                ))
              }
            </ButtonGroup>
          </ModalFooter>
        ) : (
          null
        )
      }
    </Fragment>
  )

  if (Wrapper) {
    content = (
      <Wrapper {...wrapperProps}>
        {content}
      </Wrapper>
    )
  }

  return (
    <ModalBase id={id} size={size} onBackdropClick={closeOnBackdropClick ? onClose : null}>
      {content}
    </ModalBase>
  )
}

Modal.defaultProps = {
  closeOnBackdropClick: true,
}

export default Modal
