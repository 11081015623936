import { useCallback } from 'react'
import { pathOr } from 'ramda'
import { useQuery, gql, useMutation } from 'apollo'

import Select, { SelectProps } from 'components/Select'

type MarketSelectProps = SelectProps & {
  children?: React.ReactNode
  className?: string
}

const QUERY = gql`
  query MarketSelectQuery{
    markets {
      items {
        id
        value: id
        label: name
      }
    }
  }
`

const CREATE_MUTATION = gql`
  mutation MarketSelect_CreateMutation($input: CreateMarketInput!) {
    createMarket(input: $input) {
      node {
        id
        value: id
        label: name
      }
    }
  }
`

const MarketSelect: React.FunctionComponent<MarketSelectProps> = (props) => {
  const result = useQuery(QUERY)

  const options = pathOr([], ['data', 'markets', 'items'], result)

  const [ createMutation, { loading } ] = useMutation(CREATE_MUTATION, {
    refetchQueries: ['MarketSelectQuery']
  })

  const handleCreate = useCallback((name: string) => {
    return createMutation({ variables: { input: { name } } })
      .then((result) => {
        const option = pathOr(null, ['data', 'createMarket', 'node'], result)

        return option
      })
  }, [createMutation])

  return (
    <Select
      options={options}
      searchable={true}
      clearable={true}
      creatable={true}
      isLoading={loading}
      onCreateOption={handleCreate}
      {...props}
    />
  )
}

export default MarketSelect
