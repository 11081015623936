const KEY = 'USER_TOKEN'

export const getAuthToken = (): string => localStorage.getItem(KEY)

export const setAuthToken = (token: string) => {
  if (token) {
    localStorage.setItem(KEY, token)
  }
  else {
    localStorage.removeItem(KEY)
  }
}
