
import cx from 'classnames'

import s from './Container.scss'

type ContainerProps = {
  className?: string
  children?: React.ReactNode
  center?: boolean
  horizonalPadding?: boolean
  verticalPadding?: boolean
}

const Container: React.FunctionComponent<ContainerProps> = ({ className, children, center, horizonalPadding, verticalPadding }) => (
  <div className={cx(className, s.root, {
    [s.center]: center,
    [s.horizontalPadding]: horizonalPadding,
    [s.verticalPadding]: verticalPadding,
  })}>
    {children}
  </div>
)

Container.defaultProps = {
  center: false,
  horizonalPadding: true,
  verticalPadding: false,
}

export default Container
