import { useCallback, useState, Fragment, forwardRef, useRef, useEffect } from 'react'

const SelectInput = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  const { value, ...rest } = props

  const bufferRef = useRef(null)
  const [width, setWidth] = useState(0)

  const resize = useCallback(() => {
    if (bufferRef.current) {
      const width = bufferRef.current.clientWidth

      setWidth(width)
    }
  }, [ setWidth])

  useEffect(() => {
    resize()
  }, [ resize, value ])

  return (
    <Fragment>
      <input
        ref={ref}
        value={value}
        style={{ width: Math.max(2, width + 2) }}
        {...rest}
      />
      <div
        ref={bufferRef}
        style={{ position: 'absolute', top: -1000, left: -1000, visibility: 'hidden', whiteSpace: 'pre' }}
      >
        {value}
      </div>
    </Fragment>
  )
})

export default SelectInput
