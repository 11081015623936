/* tslint:disable:jsx-no-lambda */
import { useState, useCallback } from 'react'
import { useMedia } from 'helpers'
import Debug from 'components/Debug'
import Pagination from 'components/Pagination'
import Icon, { ICONS, IconValues } from 'components/Icon'
import TextInput from 'components/TextInput'
import Loader from 'components/Loader'
import PageError from 'components/PageError'
import Container from 'components/Container'
import Button from 'components/Button'
import Text, { TextProps } from 'components/Text'
import Title from 'components/Title'
import Box from 'components/Box'
import ButtonGroup from 'components/ButtonGroup'
import { Modal, ModalBase, ModalContent, ModalFooter, ModalHeader } from 'components/Modal'
import Flex from 'components/Flex'
import Toggle from 'components/Toggle'
import BaseFormField from 'components/BaseFormField'
import DropdownExample from './DropdownExample'
import Label from 'components/Label'
import TimePicker from 'components/DateTimeInput/TimePicker'

type TextPropsWithName = TextProps & {
  name: string
}

const textVariations: TextPropsWithName[] = [
  { name: 'Normal' },
  { name: 'Medium', weight: 'medium' },
  { name: 'Semi', weight: 'semi' },
  { name: 'Bold', weight: 'bold' },
  { name: 'Underline', underline: true },
  { name: 'Line through', lineThrough: true },
  { name: 'Muted', muted: true },
  { name: 'Uppercase', uppercase: true },
  { name: 'Header 1', size: 'h1' },
  { name: 'Header 2', size: 'h2' },
]

const options = [{ label: 'Value 1' }, { label: 'Value 2' }, { label: 'Value 3' }, { label: 'Value 4' }, { label: 'Value 5' }]
  .map(({ label }) => ({ label, value: label }))

const Guidelines: React.FunctionComponent = () => {
  const [page, setPage] = useState(1)
  const [isModal1Opened, setModal1Opened] = useState(false)
  const [isModal2Opened, setModal2Opened] = useState(false)

  const [value, setValue] = useState('')
  const handleChange = useCallback((event) => {
    setValue(event.target.value)
  }, [])

  const [selectValue, setSelectValue] = useState([options[0], options[1]])
  const handleSelectChange = useCallback((event) => {
    console.log(event.target.value)
    setSelectValue(event.target.value)
  }, [])

  const [timeValue, setTimeValue] = useState(null)
  const [dateValue, setDateValue] = useState(null)
  const handleDateChange = useCallback((date) => {
    console.log(date)
    setDateValue(date)
  }, [])

  const media = useMedia()

  return (
    <Container>
      <Title>Guidelines</Title>
      <Title size="h2">Buttons</Title>
      <Button to="/">Кнопка ссылка</Button>
      <Button>Кнопка</Button>
      <Button disabled>Кнопка выключенная</Button>
      <Button color="primary" loading>Загружает что-то</Button>
      <div style={{ maxWidth: 300 }}>
        <Button block>Default</Button>
        <Button block color="primary">Primary</Button>
        <Button block color="danger">Danger</Button>
        <Button block color="danger" loading>Danger</Button>
      </div>
      <Box mt={32} />

      <Title size="h2">Inputs</Title>
      <TextInput placeholder="Some placeholder" value={value} onChange={handleChange} />
      <TextInput mask="+9 999 99 99" showMask value={value} onChange={handleChange} />
      <BaseFormField
        label="Поле с маской"
        mask="99.99.99 99:99"
        showMask
        value={value}
        onChange={handleChange}
      />
      <BaseFormField
        label="Селект"
        control="select"
        placeholder="Выберите продукт..."
        searchable={false}
        options={options}
      />
      <BaseFormField
        label="Селект"
        control="select"
        options={options}
        onChange={(event) => { console.log('Select:', event) }}
        multiple
      />
      <BaseFormField
        label="Multiple select"
        control="select"
        options={options}
        value={selectValue}
        onChange={handleSelectChange}
        optionBased
        multiple
      />
      <BaseFormField
        label="Multiple with creation"
        control="select"
        options={options}
        value={selectValue}
        onChange={handleSelectChange}
        optionBased
        creatable
        multiple
      />
      <BaseFormField
        label="Автокомплит"
        name="autocomplete"
        control="autocomplete"
        suggestions={options}
        onSuggestionSelect={(suggestion) => { console.log('Suggestion selected:', suggestion) }}
      />
      <BaseFormField
        label="Дата и время"
        name="datetime"
        control="datetime"
        value={dateValue}
        onChange={handleDateChange}
      />
      <TimePicker value={timeValue} onChange={setTimeValue} />
      <Toggle id="toggle-1" />
      <Toggle id="toggle-2" defaultChecked />
      <Toggle id="toggle-3" disabled />
      <Toggle id="toggle-4" disabled defaultChecked />

      <Box mt={32} />
      <DropdownExample />

      <Box mt={32} />

      <Title size="h2">Text</Title>
      {
        textVariations.map(({ name, ...props }) => (
          <Text key={name} text={name} {...props} />
        ))
      }
      <Title size="h2" text="Header Title" />
      <Title size="h2" text="Header H2" />
      <Box mt={32} />

      <Button onClick={() => { setModal1Opened(true) }}>Open modal 1</Button>
      <Button onClick={() => { setModal2Opened(true) }}>Open modal 2</Button>
      {
        isModal1Opened && (
          <ModalBase>
            <ModalHeader title="Modal title" onClose={() => { setModal1Opened(false) }} />
            <ModalContent>
              Some modal content
            </ModalContent>
            <ModalFooter>
              <ButtonGroup>
                <Button>Отмена</Button>
                <Button color="primary">Сохранить</Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalBase>
        )
      }
      {
        isModal2Opened && (
          <Modal
            title="Редактирование платежа"
            onClose={() => { setModal2Opened(false) }}
            footerButtons={[
              { children: 'Отмена' },
              { children: 'Сохранить', color: 'primary' },
            ]}
          >
            Модалка построенная на Modal
          </Modal>
        )
      }

      <Title size="h2">Etc.</Title>
      <Label>Some label</Label>
      <Label onRemoveClick={() => { console.log('on remove') }}>Some label 2</Label>
      <Loader />
      <PageError error="Какая-то ошибка" />
      <Title size="h2">Pagination</Title>
      <Pagination
        pageCount={100}
        page={page}
        onChange={setPage}
      />

      <Title size="h2">Media</Title>
      <Debug data={media} />

      <Title size="h2">Иконки</Title>
      <Flex wrap>
        {
          Object.keys(ICONS).map((key: IconValues) => (
            <Box p={16} key={key} style={{ flex: 'none', width: '200px', textAlign: 'center' }}>
              <Box mb={16}>
                <Icon icon={key} />
              </Box>
              <Text size="sm" muted>{key}</Text>
            </Box>
          ))
        }
      </Flex>
    </Container>
  )
}

export default Guidelines
