import cx from 'classnames'
import { getErrorMessage } from 'apollo'

import s from './PageError.scss'

type PageErrorProps = {
  className?: string
  title?: React.ReactNode,
  error?: React.ReactNode | Error,
  children?: React.ReactNode
}

const PageError: React.FunctionComponent<PageErrorProps> = (props) => {
  const { className, title, error, children } = props
  let errorMessage = error

  if (error instanceof Error) {
    errorMessage = getErrorMessage(error)
  }

  return (
    <div className={cx(className, s.root)}>
      <h4>{title || 'Ошибка'}</h4>
      <div>{errorMessage || children}</div>
    </div>
  )
}

export default PageError
