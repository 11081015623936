import { useMemo, useRef } from 'react'
import queryString, { ParsedQuery, ParseOptions } from 'query-string'

export type QueryParamsGetter<T = unknown> = (params: ParsedQuery<string>) => T

const options = {
  parseBooleans: true,
  parseNumbers: false,
  arrayFormat: 'bracket',
} as ParseOptions

export const useQueryParams = <T = unknown>(getter: QueryParamsGetter<T>, location = window.location): T => {
  const { search } = location
  const getterRef = useRef(getter)

  return useMemo(() => {
    const queryParams = queryString.parse(search, options)

    return getterRef.current(queryParams)
  }, [search])
}

export const getQueryParams = (location = window.location) => {
  const { search } = location

  return queryString.parse(search, options)
}

export const parseQueryParams = (search: string) => {
  return queryString.parse(search, options)
}

export const stringifyQueryParams = (params: any): string => {
  return queryString.stringify(params, options)
}

export const updateAndStringifyQueryParams = (prevParams: any, update: any): string => {
  return queryString.stringify({ ...prevParams, ...update }, options)
}
