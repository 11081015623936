import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { getAuthToken } from 'helpers/token'
import timeoutReject from 'helpers/timeoutReject'
import fragmentTypes from './fragmentTypes'

const ENDPOINT = `${process.env.API || ''}/graphql`

const timeoutError = new Error('Network timeout')

const timeoutFetch = (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  const token = getAuthToken()
  const { headers, ...rest } = init || {}

  const fetchPromise = fetch(input, {
    ...rest,
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    }
  })
  const timeoutPromise = timeoutReject(10000, timeoutError)

  return Promise.race([fetchPromise, timeoutPromise])
}

const httpLink = createHttpLink({
  uri: ENDPOINT,
  fetch: timeoutFetch,
})

const cache = new InMemoryCache({
  possibleTypes: fragmentTypes.possibleTypes,
})

const client = new ApolloClient({
  uri: ENDPOINT,
  link: httpLink,
  cache,
  connectToDevTools: true,
})

export default client
