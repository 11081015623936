import { useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import { gql, useMutation, useQuery } from 'apollo'
import { links, getLinkWithSearch, pathOr } from 'helpers'

import ModalLoader from 'components/ModalLoader/ModalLoader'
import ModalError from 'components/ModalError'
import CategoryForm, { CategoryFormSubmit } from './CategoryForm'

const FRAGMENT = gql`
  fragment CategoryFragment on Category {
    id
    name
    notes
    relativeIds
    relatives {
      id
      name
    }
  }
`

const NODE_QUERY = gql`
  query CategoryEditor_NodeQuery($id: ID!) {
    node(id: $id) {
      id
      ...on Category {
        ...CategoryFragment
      }
    }
  }
  ${FRAGMENT}
`

const CREATE_MUTATION = gql`
  mutation CategoryEditor_CreateMutation($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      node {
        ...CategoryFragment
      }
    }
  }
  ${FRAGMENT}
`

const UPDATE_MUTATION = gql`
  mutation CategoryEditor_UpdateMutation($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      node {
        ...CategoryFragment
      }
    }
  }
  ${FRAGMENT}
`

const DELETE_MUTATION = gql`
  mutation CategoryEditor_DeleteMutation($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      deletedId
    }
  }
`

const mutationConfig = {
  refetchQueries: ['CategoriesRootQuery', 'CategoriesSelectQuery'],
}

type CategoryEditorProps = RouteComponentProps<any>

const CategoryEditor = (props: CategoryEditorProps) => {
  const { match: { params: { id: requestedId = '' } }, history } = props

  const { data, loading, error } = useQuery(NODE_QUERY, {
    variables: {
      id: requestedId,
    },
    skip: !requestedId,
  })

  const node = pathOr(null, ['node'], data)

  const [ createMutation ] = useMutation(CREATE_MUTATION, mutationConfig)
  const [ updateMutation ] = useMutation(UPDATE_MUTATION, mutationConfig)
  const [ deleteMutation ] = useMutation(DELETE_MUTATION, mutationConfig)

  const redirect = useCallback(() => {
    history.push(getLinkWithSearch(links.categories.root))
  }, [ history ])

  const handleSubmit = useCallback<CategoryFormSubmit>((values, actions) => {
    const mutation = node ? updateMutation : createMutation

    mutation({ variables: { input: values } })
      .then(
        () => {
          redirect()
        },
        (mutationError) => {
          const { validationErrors } = mutationError

          if (validationErrors) {
            actions.setErrors(validationErrors)
          }
        }
      )
      .then(() => {
        actions.setSubmitting(false)
      })
  }, [ createMutation, updateMutation, node, redirect ])

  const handleDelete = useCallback(() => {
    const { id } = node

    if (confirm('Вы уверены?')) {
      deleteMutation({ variables: { input: { id } } })
        .then(
          () => {
            redirect()
          },
          (mutationError) => {
            alert(mutationError)
          }
        )
    }
  }, [deleteMutation, node, redirect])

  const handleClose = redirect

  if (loading) {
    return <ModalLoader size="md" onClose={handleClose} />
  }

  if (error) {
    return <ModalError size="md" onClose={handleClose} error={error} />
  }

  return (
    <CategoryForm
      node={node}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    />
  )
}

export default CategoryEditor
