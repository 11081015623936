
import Modal from 'components/Modal/Modal'
import { ModalBaseSize } from 'components/Modal/ModalBase'
import PageError from 'components/PageError'

type ModalErrorProps = {
  title?: string
  error: React.ReactNode | Error
  size?: ModalBaseSize
  onClose?: () => void
}

const ModalError: React.FunctionComponent<ModalErrorProps> = ({ title, error, size, onClose }) => (
  <Modal title={title || 'Загрузка...'} size={size} onClose={onClose}>
    <PageError title={title} error={error} />
  </Modal>
)

export default ModalError
