import React from 'react'
import cx from 'classnames'

import Box, { OffsetValue } from 'components/Box'

import s from './ButtonGroup.scss'

type ButtonGroupProps = {
  className?: string
  children?: React.ReactNode
  mt?: OffsetValue
  mb?: OffsetValue
}

const ButtonGroup: React.FunctionComponent<ButtonGroupProps> = (props) => {
  const { children, className, mt, mb } = props

  return (
    <Box className={cx(className, s.root)} mt={mt} mb={mb}>
      {
        React.Children.map(children, (child: React.ReactElement<{ className?: string }>) => {
          if (!React.isValidElement(child)) {
            return child
          }

          return React.cloneElement(child, {
            className: cx(s.button, child.props.className),
          })
        })
      }
    </Box>
  )
}

export default ButtonGroup
