import { useMemo } from 'react'
import { pathOr } from 'ramda'
import { useQuery, gql } from 'apollo'

import Select, { SelectProps } from 'components/Select'

type AccountSelectProps = SelectProps & {
  children?: React.ReactNode
  className?: string
}

const query = gql`
  query AccountSelectQuery {
    accounts {
      id
      show
      value: id
      label: name
    }
  }
`

const AccountSelect: React.FunctionComponent<AccountSelectProps> = (props) => {
  const result = useQuery(query)

  const accounts = useMemo(() => {
    const items = pathOr([], ['data', 'accounts'], result)

    return items.filter(({ show }) => show)
  }, [ result ])

  return (
    <Select
      options={accounts}
      {...props}
    />
  )
}

export default AccountSelect
