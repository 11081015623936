
/* eslint-disable global-require */
export const ICONS = {
  'arrow_down': require('../../icons/arrow_down.svg'),
  'arrow_up': require('../../icons/arrow_up.svg'),
  'back': require('../../icons/back.svg'),
  'calendar': require('../../icons/calendar.svg'),
  'close': require('../../icons/close.svg'),
  'chevron-select': require('../../icons/chevron_select.svg'),
  'error': require('../../icons/error.svg'),
  'exit': require('../../icons/exit.svg'),
  'hamburger': require('../../icons/hamburger.svg'),
  'more-vert': require('../../icons/more_vert.svg'),
  'operation-deposition': require('../../icons/operation_deposition.svg'),
  'operation-transfer': require('../../icons/operation_transfer.svg'),
  'plus': require('../../icons/plus.svg'),
  'refresh': require('../../icons/refresh.svg'),
  'search': require('../../icons/search.svg'),
}
/* eslint-enable global-require */

export type IconValues = keyof typeof ICONS

type IconProps = {
  icon: IconValues
  className?: string
  width?: number | string
  height?: number | string
}

const Icon: React.FunctionComponent<IconProps> = ({ icon, className, width, height, ...rest }) => {
  if (!ICONS[icon]) {
    // eslint-disable-next-line no-console
    console.warn(`Icon \`${icon}\` no found.`)
    return null
  }

  const info = ICONS[icon]
  const viewBox = info.default.viewBox
  const [,, vbWidth, vbHeight] = viewBox.split(' ')

  const props = {
    className,
    viewBox,
    width: width || vbWidth,
    height: height || vbHeight,
    ...rest,
  }

  return (
    <svg {...props}>
      <use xlinkHref={`#${info.default.id}`} />
    </svg>
  )
}

export default Icon
