import { useCallback, useMemo } from 'react'
import { useFiltersState } from 'helpers'

import BaseFormField from 'components/BaseFormField'
import FiltersForm from 'components/FiltersForm'
import Button from 'components/Button'
import FlexItem from 'components/FlexItem'
import FormLabel from 'components/FormLabel'
import FormGroup from 'components/FormGroup'

type Filter = {
  query: string
}

type SimpleFilterProps = Filter & {
  query: string
  addTo?: string
  onChange?: (values: Filter) => void
}

const SimpleFilter: React.FunctionComponent<SimpleFilterProps> = (props) => {
  const { children, query, addTo, onChange } = props

  const filtersProps = useMemo(() => ({
    query: query || '',
  }), [query])

  const [state, setState, onSubmit] = useFiltersState(filtersProps, onChange)

  const handleChange = useCallback((event: any) => {
    const { target: { name, value } } = event

    setState({ [name]: value })
  }, [setState])

  return (
    <FiltersForm onSubmit={onSubmit}>
      <BaseFormField
        label="Искать"
        type="text"
        name="query"
        value={state.query}
        onChange={handleChange}
        autoFocus
        rounded
      />
      {children}
      {
        Boolean(addTo) && (
          <FlexItem none cloneChildren>
            <FormGroup>
              <FormLabel>Добавить</FormLabel>
              <Button to={addTo} rounded>
                Добавить
              </Button>
            </FormGroup>
          </FlexItem>
        )
      }
    </FiltersForm>
  )
}

export default SimpleFilter
